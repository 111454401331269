import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";

export enum HEADER_TYPE {
  DEFAULT = "",
  TYPE_1 = "type-1", // only mobile visible
  TYPE_2 = "type-2", // only desktop visible
  TYPE_3 = "type-3" // always visible
}

interface IHeaderData {
  centerSection?: JSX.Element | null;
  rightSection?: JSX.Element | null;
  leftSection?: JSX.Element | null;
  type: HEADER_TYPE;
}

export interface IHeaderContextInterface {
  centerSection: JSX.Element | null;
  rightSection: JSX.Element | null;
  leftSection: JSX.Element | null;
  showMenu: boolean;
  showNotifications: boolean;
  type: HEADER_TYPE;
  setCenterSection: (centreSection: JSX.Element | null) => void;
  setRightSection: (leftButton: JSX.Element | null) => void;
  setLeftSection: (leftButton: JSX.Element | null) => void;
  setShowMenu: (showMenu: boolean | ((prevValue: boolean) => boolean)) => void;
  setShowNotifications: (
    showNotifications: boolean | ((prevValue: boolean) => boolean)
  ) => void;
  setType: (type: HEADER_TYPE) => void;
  setData: (data: IHeaderData) => void;
  reset: () => void;
}

interface IHeaderProviderProps {
  children: ReactNode;
}

const HeaderStateContext = createContext<IHeaderContextInterface | undefined>(
  undefined
);

export function HeaderProvider({
  children
}: IHeaderProviderProps): JSX.Element {
  const [type, setType] = useState<HEADER_TYPE>(HEADER_TYPE.DEFAULT);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [rightSection, setRightSection] = useState<JSX.Element | null>(null);
  const [leftSection, setLeftSection] = useState<JSX.Element | null>(null);
  const [centerSection, setCenterSection] = useState<JSX.Element | null>(null);

  const _reset = () => {
    setCenterSection(null);
    setRightSection(null);
    setLeftSection(null);
    setShowMenu(false);
    setType(HEADER_TYPE.DEFAULT);
  };

  const _setData = (data: IHeaderData) => {
    if (data.centerSection !== undefined) {
      setCenterSection(data.centerSection);
    }

    if (data.rightSection !== undefined) {
      setRightSection(data.rightSection);
    }

    if (data.leftSection !== undefined) {
      setLeftSection(data.leftSection);
    }

    setType(data.type);
  };

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showMenu]);

  return (
    <HeaderStateContext.Provider
      value={{
        centerSection,
        rightSection,
        leftSection,
        showMenu,
        showNotifications,
        type,
        setCenterSection,
        setRightSection,
        setLeftSection,
        setShowMenu,
        setShowNotifications,
        setType,
        setData: _setData,
        reset: _reset
      }}
    >
      {children}
    </HeaderStateContext.Provider>
  );
}

export function useHeader(): IHeaderContextInterface {
  const context = useContext(HeaderStateContext);
  if (context === undefined) {
    throw new Error("useHeader must be used within a HeaderProvider");
  }
  return context;
}

import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type LocationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LocationsQuery = {
  __typename?: "Query";
  locations: Array<{
    __typename?: "Location";
    id: string;
    cover_photo_url?: string | null;
    visible_on_homepage: boolean;
    name: string;
    address_display?: string | null;
    address_latitude?: number | null;
    address_longitude?: number | null;
    slug?: string | null;
    company: { __typename?: "Company"; id: string; slug?: string | null };
  }>;
};

export type HomepageLocationItemFragment = {
  __typename?: "Location";
  id: string;
  cover_photo_url?: string | null;
  visible_on_homepage: boolean;
  name: string;
  address_display?: string | null;
  address_latitude?: number | null;
  address_longitude?: number | null;
  slug?: string | null;
  company: { __typename?: "Company"; id: string; slug?: string | null };
};

export const HomepageLocationItemFragmentDoc = gql`
  fragment HomepageLocationItem on Location {
    id
    cover_photo_url
    visible_on_homepage
    name
    address_display
    address_latitude
    address_longitude
    slug
    company {
      id
      slug
    }
  }
`;
export const LocationsDocument = gql`
  query Locations {
    locations {
      ...HomepageLocationItem
    }
  }
  ${HomepageLocationItemFragmentDoc}
`;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<LocationsQuery, LocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    options
  );
}
export function useLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LocationsQuery,
    LocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    options
  );
}
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<
  typeof useLocationsLazyQuery
>;
export type LocationsQueryResult = Apollo.QueryResult<
  LocationsQuery,
  LocationsQueryVariables
>;

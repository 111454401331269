import "./DesktopModal.scss";
import React, { useEffect, useRef, useState } from "react";
import { bemElement, bemModifier } from "../../../utils/bem-class-names";
import { motion, useAnimation } from "framer-motion";
import { IconButton } from "../../../elements/icon-button/IconButton";
import { COLORS } from "../../../models/colors";
import { joinClassNames } from "../../../utils/join-class-names";

export interface IDesktopModalData {
  title?: string;
  show: boolean;
  onHide: () => void;
  disableHeader?: boolean;
  children: React.ReactNode;
  BottomControls?: React.FC;
  className?: string;
}

const baseClassName = "desktop-modal";
const bem = bemElement(baseClassName);

const DesktopModal = ({
  title,
  show,
  onHide,
  disableHeader,
  children,
  BottomControls,
  className = ""
}: IDesktopModalData) => {
  const animation = useAnimation();
  const [active, setActive] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show) {
      setActive(true);
      document.body.style.overflow = "hidden";
      animation.start({ opacity: 1, display: "flex" }).catch();
    } else {
      animation
        .start({ opacity: 0 })
        .then(() => {
          setActive(false);
          animation.start({ display: "none" });
          document.body.style.overflow = "auto";
        })
        .catch();
    }
  }, [animation, show]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Escape") {
        onHide();
      }
    };

    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [onHide]);

  const onOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      onHide();
    }
  };

  return (
    <motion.div
      className={joinClassNames(
        bemModifier(baseClassName, { active: active }),
        className
      )}
      animate={animation}
      initial={{ opacity: 0, display: "none" }}
      onClick={onOutsideClick}
    >
      <div
        className={joinClassNames(bem("container"), "hide-scroll-bar")}
        ref={ref}
      >
        {!disableHeader && (
          <div className={bem("header")}>
            <IconButton
              color={COLORS.PRIMARY}
              iconName="close"
              onClick={onHide}
            />
            <div className={bem("title")}>{title}</div>
          </div>
        )}
        <div
          className={`desktop-modal-content-wrapper${
            disableHeader ? "" : " with-header"
          }`}
        >
          <div className={bem("content")}>{children}</div>
          {BottomControls && (
            <div className="bottom-controls">
              <BottomControls />
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default DesktopModal;

import "./Avatar.scss";
import { useState } from "react";
import { ImageElement } from "elements/image/Image";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { bemElement, bemModifier } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";

export type TAvatarSize = "xxs" | "xs" | "sm" | "md" | "lg" | "xl";
export type TAvatarShape = "square" | "circle";

interface IAvatarProps {
  alt?: string;
  className?: string;
  monogram?: string;
  monogramLength?: number;
  shape?: TAvatarShape;
  size?: TAvatarSize;
  src?: string;
  monogramFontSize?: string;
}

const baseClassName = "avatar";
const bem = bemElement(baseClassName);

const getDefaultIcon = (size: TAvatarSize) => {
  switch (size) {
    case "xxs":
    case "xs":
      return <SvgIcon name="nail" className="fill-outline-variant scale-75" />;
    case "sm":
      return <SvgIcon name="nail" className="fill-outline-variant" />;
    case "md":
    case "lg":
    case "xl":
      return <SvgIcon name="nail" className="fill-outline-variant scale-150" />;
  }
};

const getMonogram = (
  monogramLength: number,
  monogram?: string,
  monogramFontSize?: string
): JSX.Element | null =>
  monogram ? (
    <span className={monogramFontSize ? monogramFontSize : ""}>
      {monogram.slice(0, monogramLength).toLocaleUpperCase()}
    </span>
  ) : null;
const getContent = (
  errorHandler: () => void,
  size: TAvatarSize,
  monogramLength: number,
  monogram?: string,
  src?: string,
  alt?: string,
  monogramFontSize?: string
): JSX.Element | null => {
  if (!monogram && !src) {
    return getDefaultIcon(size);
  }

  if (src) {
    return (
      <ImageElement
        src={src}
        alt={alt || "avatar"}
        onError={errorHandler}
        className={bem("image")}
        aspectRatio="1:1"
      />
    );
  }

  return getMonogram(monogramLength, monogram, monogramFontSize);
};

export const Avatar = ({
  alt,
  className = "",
  monogram,
  monogramLength = 1,
  shape = "circle",
  size = "md",
  src,
  monogramFontSize
}: IAvatarProps): JSX.Element => {
  const [isError, setIsError] = useState<boolean>(false);

  return (
    <div
      className={joinClassNames(
        bemModifier(baseClassName, shape, size),
        className
      )}
    >
      {isError
        ? monogram
          ? getMonogram(monogramLength, monogram, monogramFontSize)
          : getDefaultIcon(size)
        : getContent(
            () => setIsError(true),
            size,
            monogramLength,
            monogram,
            src,
            alt,
            monogramFontSize
          )}
    </div>
  );
};

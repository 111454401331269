import axios from "axios";
import decode from "jwt-decode";

export type TCompany = {
  id: string;
  name: string;
  slug: string;
};

export type AuthenticateResponse = {
  id: string | null;
  email: string | null;
  first_name: string | null;
  last_name: string | null;
  phone_number: string | null;
  account_users: { account_id: string; role: string }[];
  iat: number | null;
  exp: number | null;
  access_token: string | null;
  company?: TCompany | null;
};

export type AuthenticateOTPResponse = {
  data: {
    email: string;
  };
};

const API_CORE_URL = process.env.REACT_APP_API_CORE_URL;

export async function authenticateEmail(
  email: string,
  verification_code: string
): Promise<AuthenticateResponse | null> {
  try {
    const response = await axios.post(`${API_CORE_URL}/v1/auth/email`, {
      email,
      verification_code: Number(verification_code)
    });

    if (response.headers["authorization"]) {
      const user: AuthenticateResponse = decode(
        response.headers["authorization"]
      );

      return { ...user, access_token: response.headers["authorization"] };
    }

    return null;
  } catch (err) {
    return err.message;
  }
}

export async function authenticateOauth({
  code,
  id_token,
  provider
}: {
  code?: string;
  id_token?: string;
  provider: string;
}): Promise<AuthenticateResponse | null> {
  try {
    const response = await axios.post(`${API_CORE_URL}/v1/auth/oauth`, {
      code,
      id_token,
      provider,
      client: process.env.REACT_APP_APPLE_CLIENT_ID
    });

    if (response.headers["authorization"]) {
      const user: AuthenticateResponse = decode(
        response.headers["authorization"]
      );

      return { ...user, access_token: response.headers["authorization"] };
    }

    return null;
  } catch (err) {
    return err.message;
  }
}

export async function authenticateOtp(
  email: string
): Promise<AuthenticateOTPResponse> {
  try {
    const user: AuthenticateOTPResponse = await axios.post(
      `${API_CORE_URL}/v1/auth/otp`,
      {
        email,
        provider: "email",
        client: process.env.REACT_APP_APPLE_CLIENT_ID
      }
    );

    return user;
  } catch (err) {
    return err.message;
  }
}

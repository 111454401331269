export const smoothScrollX = (
  element: HTMLDivElement,
  speed: number,
  distance: number,
  step: number,
  callback?: () => void
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
      if (callback) {
        callback();
      }
    }
  }, speed);
};

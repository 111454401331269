import "./ArtistSelect.scss";
import { useState } from "react";
import { bemElement } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";
import { Avatar } from "elements/avatar/Avatar";
import { TArtist } from "types";
import { getArtistRoute } from "routes";
import { InputChip } from "elements/input-chip/InputChip";
import { COLORS } from "models/colors";
import { IBookAppointmentServiceFull } from "providers/cart-provider.utils";
import { getTime } from "../../utils/date-time";
import { useCart } from "../../providers/cart-provider";
import { TBookAppointmentService } from "types";
import { IPerson } from "interfaces";
import { useAxios } from "providers/axios";
import TransformIcon from "@mui/icons-material/Transform";
import Tooltip from "@mui/material/Tooltip";
interface IArtistSelectProps {
  value: TArtist | null;
  service: IBookAppointmentServiceFull;
  isConcurrent?: boolean;
  onChange: (value: IValidPermutationsByArtist) => void;
  className?: string;
  constraints: TBookAppointmentService[];
  removeLockedArtist: (service: IBookAppointmentServiceFull) => void;
}
export interface IPermutation {
  artist_id: string;
  personIndex: number;
  service: string;
  add_ons?: string[];
}
export interface IValidPermutationsByArtist {
  artist_id: string;
  permutations: IPermutation[];
}

interface IPossibleArtistFetchBody {
  startTime: string;
  locations: string[];
  persons: IPerson[];
  constraints?: string;
  reschedule_appointment_id?: string;
}

const baseClassName = "artist-select";
const bem = bemElement(baseClassName);
const getArtistOption = (
  artist: TArtist | null | undefined,
  index: number,
  buttonName: string,
  onBtnClick: () => void,
  closeButton = false,
  showInfo = false,
  service: IBookAppointmentServiceFull,
  isConcurrent?: boolean,
  timezone?: string | null
): JSX.Element => {
  return (
    <div
      className={bem("artist-option relative")}
      key={artist ? artist.id : index}
    >
      <div className={bem("left-col")}>
        <Avatar
          size="sm"
          monogram={artist?.user?.first_name || ""}
          alt={artist?.user?.first_name || ""}
          src={artist?.cover_photo_url || artist?.user?.photo_url || ""}
        />
      </div>
      <div className={bem("center-col")}>
        <span className={bem("name")}>
          {artist ? artist.user?.first_name || "" : "Not selected"}
        </span>
        {showInfo && (
          <span className={bem("info")}>
            {artist && service.artist?.start_time
              ? `${getTime(service.artist.start_time, timezone)} • `
              : ""}
            {service.service.name.charAt(0).toUpperCase() +
              service.service.name.slice(1).toLowerCase()}
          </span>
        )}
        {artist && (
          <a className={bem("link")} href={getArtistRoute(artist.id)}>
            View profile
          </a>
        )}
      </div>
      <div className={bem("right-col")}>
        {closeButton ? (
          <InputChip
            color={COLORS.PRIMARY}
            onClick={onBtnClick}
            iconLeftName="close"
          />
        ) : (
          <InputChip
            color={COLORS.PRIMARY}
            iconLeftName={buttonName === "Unlock" ? "lock" : ""}
            value={buttonName === "Unlock"}
            onClick={onBtnClick}
            text={buttonName}
          />
        )}
        {isConcurrent && (
          <div className="absolute -bottom-[12px] left-1/2 translate-x-[-50%]">
            <Tooltip title="Concurrent">
              <TransformIcon className="text-primary" />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export const ArtistSelect = ({
  value,
  service,
  isConcurrent,
  onChange,
  className = "",
  constraints,
  removeLockedArtist
}: IArtistSelectProps): JSX.Element => {
  const { cart } = useCart();
  const { api } = useAxios();
  const [showOptions, setShowOptions] = useState(false);
  const [permutationsByArtists, setPermutationsByArtists] = useState<
    IValidPermutationsByArtist[]
  >([]);
  const [uniqueArtists, setUniqueArtists] = useState<TArtist[]>([]);

  const onArtistChange = (permutations: IValidPermutationsByArtist) => {
    onChange(permutations);
    setShowOptions(false);
  };

  function getPossibleArtistsFetchBody(): IPossibleArtistFetchBody {
    const startTime = cart.start_time;

    const persons: IPerson[] = [];

    cart.services.forEach((service: IBookAppointmentServiceFull) => {
      if (persons[service.personIndex]) {
        persons[service.personIndex].services.push(service.service.id);
        persons[service.personIndex].service_with_addons?.push({
          service_id: service.service.id,
          addons_ids: service.addOns || [],
          personIndex: service.personIndex
        });
      } else {
        persons[service.personIndex] = {
          services: [service.service.id],
          service_with_addons: [
            {
              service_id: service.service.id,
              addons_ids: service.addOns || [],
              personIndex: service.personIndex
            }
          ]
        };
      }
    });

    return {
      locations: cart.location?.id ? [cart.location?.id] : [],
      startTime,
      persons,
      constraints: !!constraints?.length
        ? JSON.stringify(constraints)
        : undefined
    };
  }

  const fetchAvailableArtists = async (
    selectedService: IBookAppointmentServiceFull
  ) => {
    const requestBody = {
      ...getPossibleArtistsFetchBody(),
      selectedService: JSON.stringify({
        service_id: selectedService.service.id,
        personIndex: selectedService.personIndex
      })
    };
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const response: any = await api.post(
      `/v1/default-permutations/assignments`,
      requestBody
    );
    setPermutationsByArtists(response?.data?.[0]?.appointments);
    setUniqueArtists(response?.data?.[0]?.artists);
  };

  return (
    <div className={joinClassNames(baseClassName, className)}>
      {getArtistOption(
        value,
        0,
        constraints.find(
          (item) =>
            item.artist_id === service?.artist?.artist_id &&
            item.personIndex === service?.artist?.personIndex &&
            item.service_id === service?.artist?.service
        )
          ? "Unlock"
          : "Change",
        () => {
          if (
            constraints.find(
              (item) =>
                item.artist_id === service?.artist?.artist_id &&
                item.personIndex === service?.artist?.personIndex &&
                item.service_id === service?.artist?.service
            )
          ) {
            removeLockedArtist(service);
          } else {
            if (!showOptions) {
              fetchAvailableArtists(service);
            }
            setShowOptions((prev) => !prev);
          }
        },
        showOptions,
        true,
        service,
        !showOptions && isConcurrent,
        cart.location?.timezone
      )}
      {showOptions && (
        <div className={bem("options")}>
          {permutationsByArtists?.map((option, index) =>
            getArtistOption(
              uniqueArtists?.find((artist) => artist?.id === option?.artist_id),
              index,
              "Choose",
              () => onArtistChange(option),
              false,
              false,
              service,
              index === permutationsByArtists.length - 1 && isConcurrent,
              cart.location?.timezone
            )
          )}
        </div>
      )}
    </div>
  );
};

import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ArtistQueryVariables = Types.Exact<{
  where: Types.ArtistWhereUniqueInput;
  user_id?: Types.InputMaybe<Types.UuidFilter>;
}>;

export type ArtistQuery = {
  __typename?: "Query";
  artist?: {
    __typename?: "Artist";
    id: string;
    bio?: string | null;
    rating_aggregate?: number | null;
    cover_photo_url?: string | null;
    user?: {
      __typename?: "User";
      first_name?: string | null;
      photo_url?: string | null;
      joined_company?: {
        __typename?: "Company";
        slug?: string | null;
        logo_url?: string | null;
      } | null;
    } | null;
    artist_instagram?: {
      __typename?: "ArtistInstagram";
      instagram_user_id?: string | null;
      access_token: string;
    } | null;
    favourited_by: Array<{ __typename?: "FavoriteArtist"; id: string }>;
  } | null;
};

export type AddArtistToFavoriteMutationVariables = Types.Exact<{
  data: Types.FavoriteArtistCreateInput;
}>;

export type AddArtistToFavoriteMutation = {
  __typename?: "Mutation";
  createOneFavoriteArtist: { __typename?: "FavoriteArtist"; artist_id: string };
};

export type RemoveArtistFromFavoriteMutationVariables = Types.Exact<{
  where: Types.FavoriteArtistWhereUniqueInput;
}>;

export type RemoveArtistFromFavoriteMutation = {
  __typename?: "Mutation";
  deleteOneFavoriteArtist?: {
    __typename?: "FavoriteArtist";
    artist_id: string;
  } | null;
};

export const ArtistDocument = gql`
  query Artist($where: ArtistWhereUniqueInput!, $user_id: UuidFilter) {
    artist(where: $where) {
      id
      bio
      rating_aggregate
      cover_photo_url
      user {
        first_name
        photo_url
        joined_company {
          slug
          logo_url
        }
      }
      artist_instagram {
        instagram_user_id
        access_token
      }
      favourited_by(where: { user_id: $user_id }) {
        id
      }
    }
  }
`;

/**
 * __useArtistQuery__
 *
 * To run a query within a React component, call `useArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtistQuery({
 *   variables: {
 *      where: // value for 'where'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useArtistQuery(
  baseOptions: Apollo.QueryHookOptions<ArtistQuery, ArtistQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ArtistQuery, ArtistQueryVariables>(
    ArtistDocument,
    options
  );
}
export function useArtistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ArtistQuery, ArtistQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ArtistQuery, ArtistQueryVariables>(
    ArtistDocument,
    options
  );
}
export type ArtistQueryHookResult = ReturnType<typeof useArtistQuery>;
export type ArtistLazyQueryHookResult = ReturnType<typeof useArtistLazyQuery>;
export type ArtistQueryResult = Apollo.QueryResult<
  ArtistQuery,
  ArtistQueryVariables
>;
export const AddArtistToFavoriteDocument = gql`
  mutation AddArtistToFavorite($data: FavoriteArtistCreateInput!) {
    createOneFavoriteArtist(data: $data) {
      artist_id
    }
  }
`;
export type AddArtistToFavoriteMutationFn = Apollo.MutationFunction<
  AddArtistToFavoriteMutation,
  AddArtistToFavoriteMutationVariables
>;

/**
 * __useAddArtistToFavoriteMutation__
 *
 * To run a mutation, you first call `useAddArtistToFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddArtistToFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArtistToFavoriteMutation, { data, loading, error }] = useAddArtistToFavoriteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddArtistToFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddArtistToFavoriteMutation,
    AddArtistToFavoriteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddArtistToFavoriteMutation,
    AddArtistToFavoriteMutationVariables
  >(AddArtistToFavoriteDocument, options);
}
export type AddArtistToFavoriteMutationHookResult = ReturnType<
  typeof useAddArtistToFavoriteMutation
>;
export type AddArtistToFavoriteMutationResult =
  Apollo.MutationResult<AddArtistToFavoriteMutation>;
export type AddArtistToFavoriteMutationOptions = Apollo.BaseMutationOptions<
  AddArtistToFavoriteMutation,
  AddArtistToFavoriteMutationVariables
>;
export const RemoveArtistFromFavoriteDocument = gql`
  mutation RemoveArtistFromFavorite($where: FavoriteArtistWhereUniqueInput!) {
    deleteOneFavoriteArtist(where: $where) {
      artist_id
    }
  }
`;
export type RemoveArtistFromFavoriteMutationFn = Apollo.MutationFunction<
  RemoveArtistFromFavoriteMutation,
  RemoveArtistFromFavoriteMutationVariables
>;

/**
 * __useRemoveArtistFromFavoriteMutation__
 *
 * To run a mutation, you first call `useRemoveArtistFromFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveArtistFromFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeArtistFromFavoriteMutation, { data, loading, error }] = useRemoveArtistFromFavoriteMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveArtistFromFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveArtistFromFavoriteMutation,
    RemoveArtistFromFavoriteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveArtistFromFavoriteMutation,
    RemoveArtistFromFavoriteMutationVariables
  >(RemoveArtistFromFavoriteDocument, options);
}
export type RemoveArtistFromFavoriteMutationHookResult = ReturnType<
  typeof useRemoveArtistFromFavoriteMutation
>;
export type RemoveArtistFromFavoriteMutationResult =
  Apollo.MutationResult<RemoveArtistFromFavoriteMutation>;
export type RemoveArtistFromFavoriteMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveArtistFromFavoriteMutation,
    RemoveArtistFromFavoriteMutationVariables
  >;

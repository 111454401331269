import { useRef, useState } from "react";
import "./PhoneInput.scss";
import { PhoneInput as InternationalPhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

interface PhoneInputProps {
  cyId: string | undefined;
  errorMsg: string | undefined;
  onChange?: (value: unknown, formName?: string | undefined) => void;
  onBlur?: (_formValueName?: string) => void;
  initialValue: string | undefined;
  disabled?: boolean;
}

const PhoneInput = ({
  cyId,
  errorMsg,
  onChange,
  onBlur,
  initialValue = "",
  disabled = false
}: PhoneInputProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialValue);
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <div className="relative w-full">
      <div
        onClick={() => {
          if (inputRef?.current) {
            inputRef?.current.focus();
            inputRef?.current.setSelectionRange(-1, -1);
          }
        }}
        className="absolute top-0 cursor-text left-[58px] 
        w-[calc(100%-58px)] h-[16px] bg-transparent z-50"
        aria-hidden
      />
      <InternationalPhoneInput
        className="phone-input"
        defaultCountry="us"
        placeholder={"Phone number"}
        style={{
          border: false
            ? "1px solid rgb(239 91 27)"
            : isFocused
            ? "1px solid rgb(184, 114, 42)"
            : "1px solid rgb(204, 199, 192)"
        }}
        value={value}
        onChange={(phone: string) => {
          onChange && onChange(phone);
          setValue(phone);
        }}
        disabled={disabled}
        inputRef={inputRef}
        inputStyle={{
          color: false ? "#EF5B1B" : "black"
        }}
        inputProps={{
          onFocus: () => setIsFocused(true),
          onBlur: () => {
            setIsFocused(false);
            onBlur && onBlur();
          },
          // @ts-ignore
          "data-cy": cyId
        }}
      />
      <span
        className={`${
          errorMsg ? "opacity-1" : "opacity-0"
        } text-xs text-error font-['Inter'] ml-4 mt-[4px] mb-[12px] min-h-[16px] !leading-4`}
        data-cy={`${cyId}-error`}
        style={{
          color: "#EF5B1B",
          fontSize: "0.75rem",
          marginTop: "0.25rem"
        }}
      >
        {errorMsg ? errorMsg : ""}
      </span>
    </div>
  );
};

export default PhoneInput;

export enum APP_ROUTES {
  HOME = "/",
  LOGIN = "/login",
  SIGN_UP = "/sign-up",
  VERIFY_USER = "/sign-up/verify",
  ME = "/me",
  ACCOUNT_DETAILS = "/me/account-details",
  RESET_PASSWORD = "/reset-password",
  RESERVATION = "/reservation",
  DASHBOARD = "/dashboard",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS_OF_SERVICE = "/terms-of-service",
  NOT_FOUND = "/not-found",
  ARTISTS = "/artists"
}

export const getVerifyUserRoute = (email: string) =>
  `${APP_ROUTES.VERIFY_USER}?email=${email.toLowerCase()}`;

export const getArtistRoute = (artistId: string) =>
  `${APP_ROUTES.ARTISTS}/${artistId}`;

import "./ArtistPreview.scss";
import { Avatar } from "elements/avatar/Avatar";
import { TArtist } from "types";
import { bemElement } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";
import { getRelativeDateTime } from "../../utils/date-time";

export interface ICompanyLogo {
  src: string;
  alt: string;
}

export interface IArtistPreviewProps {
  value: TArtist;
  availableTime?: string | null | undefined;
  className?: string;
  companyLogo?: ICompanyLogo;
}

const baseClassName = "artist-preview";
const bem = bemElement(baseClassName);

export const ArtistPreview = ({
  value,
  availableTime,
  className = "",
  companyLogo
}: IArtistPreviewProps): JSX.Element => {
  return (
    <div
      className={joinClassNames(baseClassName, className)}
      data-cy={"artist-preview"}
    >
      <div className={bem("logo")}>
        {companyLogo && (
          <div className={bem("company-logo")}>
            <Avatar
              src={companyLogo.src}
              alt={companyLogo.alt}
              shape="circle"
              size="xxs"
            />
          </div>
        )}
        <Avatar
          src={value.cover_photo_url || value.user?.photo_url || ""}
          alt={value.user?.first_name || ""}
          shape="circle"
          size="xl"
        />
      </div>
      <h5>{value.user?.first_name}</h5>
      <div className={bem("time")}>
        <span>Available time</span>
        <span className={bem("time-value")}>
          {availableTime ? getRelativeDateTime(availableTime) : "\u00A0"}
        </span>
      </div>
    </div>
  );
};

import "./OrderItem.scss";
import { Avatar } from "elements/avatar/Avatar";
import { TArtist, TUser } from "types";
import { bemElement, bemModifier } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";
import { IBookAppointmentServiceFull } from "../../providers/cart-provider.utils";
import { getTime } from "../../utils/date-time";
import { useCart } from "providers/cart-provider";

export interface IOrderItemProps {
  artists: TArtist[] | undefined;
  index: number;
  services: IBookAppointmentServiceFull[];
  user: TUser | null;
  className?: string;
  timezone?: string | null;
}

const baseClassName = "order-item";
const bem = bemElement(baseClassName);

export const OrderItem = ({
  artists,
  index,
  services,
  user,
  className = "",
  timezone
}: IOrderItemProps): JSX.Element => {
  const { addOnsWithCompleteData } = useCart();
  return (
    <div className={joinClassNames(baseClassName, className)}>
      {services.map((service: IBookAppointmentServiceFull, i: number) => {
        const artist = artists?.find(
          (artist) => service.artist?.artist_id === artist.id
        );

        return (
          <div key={i} className={bem("content")}>
            <div className={bem("left-col")}>
              {i === 0 && (
                <Avatar
                  src={user?.photo_url || ""}
                  monogram={user?.first_name || ""}
                  alt={user?.first_name || ""}
                  size="xs"
                />
              )}
              {i === 0 || services[i - 1]?.artist?.artist_id !== artist?.id ? (
                <Avatar
                  className={bemModifier(bem("artist-avatar"), {
                    first: i === 0
                  })}
                  src={artist?.cover_photo_url || artist?.user?.photo_url || ""}
                  monogram={artist?.user?.first_name || ""}
                  alt={artist?.user?.first_name || ""}
                  size="xs"
                />
              ) : (
                <div className={bem("dot")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle
                      cx="4"
                      cy="4"
                      r="3"
                      fill="#FAF9F7"
                      stroke="#5D5A57"
                      strokeWidth="2"
                    />
                  </svg>
                </div>
              )}
              <div className={bem("dashed-line")} />
              {i === services.length - 1 && (
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle cx="4" cy="4" r="3" fill="#5D5A57" />
                  </svg>
                </div>
              )}
            </div>
            <div className={bem("right-col")}>
              <div className={bem("right-col-content")}>
                {i === 0 && (
                  <div className={bem("name")}>
                    {index === 0
                      ? `Person ${index + 1} (You)`
                      : `Person ${index + 1}`}
                  </div>
                )}
                <div className={bem("time")}>
                  {getTime((service?.artist as any)?.start_time, timezone)}
                  {(i === 0 ||
                    services[i - 1]?.artist?.artist_id !== artist?.id) &&
                    ` • ${artist?.user?.first_name}`}
                </div>
              </div>
              <div
                className={bemModifier(bem("services"), {
                  last: i === services.length - 1
                })}
              >
                <div className={bem("service")}>
                  <span className="font-semibold">{service.service.name}</span>
                  <span className="font-semibold">
                    ${service.service.price}
                  </span>
                </div>
                <div>
                  {service?.addOns?.map((addOnId) => {
                    const foundAddOn = addOnsWithCompleteData?.find(
                      (item) => item.id === addOnId
                    );
                    return (
                      <div className={bem("service")} key={addOnId}>
                        <span>{foundAddOn?.name}</span>
                        <span>
                          {foundAddOn?.price && foundAddOn?.price > 0
                            ? `+$${foundAddOn?.price}`
                            : "Free"}
                        </span>
                      </div>
                    );
                  })}
                  {service?.service?.core_services?.map((coreService, i) => (
                    <div className={bem("service")}>
                      <span>Ritual({coreService?.core_service?.name})</span>
                      <span>
                        {coreService?.core_service?.price &&
                        coreService?.core_service?.price > 0
                          ? `+$${coreService?.add_on?.price}`
                          : "Free"}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

import "./Company.scss";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { Avatar } from "../../elements/avatar/Avatar";
import { COLORS } from "../../models/colors";
import { VARIANTS } from "../../models/variants";
import ExpandableText from "../../components/expandable-text/ExpandableText";
import { LocationPreview } from "components/location-preview/LocationPreview";
import TwoColsLayout from "../../components/layout/two-cols-layout/TwoColsLayout";
import { Button } from "../../elements/button/Button";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { HEADER_TYPE, useHeader } from "providers/header-provider";
import { IconButton } from "elements/icon-button/IconButton";
import { ImageElement } from "elements/image/Image";
import { bemElement } from "utils/bem-class-names";
import { handleGoBack } from "utils/handleGoBack";
import { TLocation } from "types";
import {
  CompanyLocationItemFragment,
  useCompanyQuery
} from "./Company.generated";
import { IconTitle } from "components/header/sections/icon-title/icon-title";
import useResponsive from "hooks/useResponsive";
import { useHeaderTransparent } from "components/header/Header";
import { getRefundAndCancellationHtml } from "../../utils/get-refund-and-cancellation-html";
import FormattedTextModal from "../../components/modals/formatted-text-modal/FormattedTextModal";
import { useAxios } from "../../providers/axios";
import { useUser } from "../../providers/user";
import { APP_ROUTES } from "routes";
import { schedulingBufferMinutes } from "../../utils/constants";
import { Amenities } from "components/amenities/Amenities";

const baseClassName = "company-page";
const bem = bemElement(baseClassName);

export default function Company() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isDesktop } = useResponsive();
  const isHeaderTransparent = useHeaderTransparent();
  const { setData } = useHeader();
  const { user } = useUser();
  const { api } = useAxios();
  const [showTextModal, setShowTextModal] = useState<boolean>(false);
  const [textModalData, setTextModalData] = useState<{
    title: string;
    text: string;
  }>();
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [artists, setArtists] = useState<any>();
  const { data, error } = useCompanyQuery({
    variables: {
      slug: params.slug
    },
    skip: !params.slug
  });
  const company = data?.company;

  useEffect(() => {
    setData({
      type: HEADER_TYPE.TYPE_2,
      leftSection: isDesktop ? null : (
        <IconButton
          color={COLORS.SECONDARY_CONTAINER}
          variant={isHeaderTransparent ? VARIANTS.FILLED : VARIANTS.DEFAULT}
          iconName="arrow_left"
          onClick={() => handleGoBack(navigate)}
        />
      ),
      rightSection: !isDesktop ? <></> : null,
      centerSection: isDesktop ? null : isHeaderTransparent ? (
        <></>
      ) : (
        <IconTitle title={company?.name || ""} src={company?.logo_url || ""} />
      )
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, isHeaderTransparent, isDesktop]);

  useEffect(() => {
    if (company?.users) {
      const artists = company?.users
        ?.filter((user) => user.artist !== null)
        .map((artist) => ({
          ...artist.artist,
          possibleAppointment: undefined
        }));
      setArtists(artists);

      (async () => {
        const result = await Promise.all(
          artists?.map(async (artist: any) => {
            const { data } = await api.post("/v1/possible-appointments", {
              locations: company?.locations?.map((location) => location.id),
              startTime:
                new Date().getTime() +
                schedulingBufferMinutes(!!user?.impersonate_name) * 60 * 1000,
              artist: artist.id,
              limit: 1
            });
            const possibleAppointments = data.find(
              (item: any) => item.appointments.length > 0
            );
            if (possibleAppointments) {
              return {
                ...artist,
                possibleAppointment: possibleAppointments.appointments[0]
              };
            } else {
              return { ...artist, possibleAppointment: null };
            }
          })
        );
        setArtists(result);
      })();
    }
  }, [company?.users, company?.locations, api, user?.impersonate_name]);

  useEffect(() => {
    if (error) {
      navigate(APP_ROUTES.NOT_FOUND);
    }
  }, [error, navigate]);

  if (!company) return null;

  return (
    <div className={baseClassName}>
      <Helmet>
        <title>Pointment | {company.name}</title>
        <meta property="og:title" content={`Pointment | ${company.name}`} />
      </Helmet>
      <TwoColsLayout>
        <div>
          <div className={bem("breadcrumbs-wrapper")}>
            <Breadcrumbs
              breadcrumbs={[{ title: "Company", url: location.pathname }]}
            />
          </div>
          <div className={bem("info")}>
            <div className={bem("info-media")}>
              <ImageElement
                className={bem("info-media-cover")}
                src={company.cover_photo_url || ""}
                alt={company.name}
                aspectRatio="3:1"
              />
              <Avatar
                src={company.logo_url || ""}
                size={"lg"}
                shape={"square"}
                monogram={company.name}
                className="avatar"
              />
            </div>
            <h2 className={bem("info-name")}>{company.name}</h2>
            <p className={bem("info-caption")}>{company.caption}</p>
            <div className={bem("info-links")}>
              {/* TODO: use <a> for links */}
              {company.website_url && (
                <div className={bem("info-link")}>
                  <a
                    href={company.website_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton
                      iconName="other_website"
                      color={COLORS.PRIMARY}
                      variant={VARIANTS.DEFAULT}
                    />
                  </a>
                </div>
              )}
              {company.instagram_url && (
                <div className={bem("info-link")}>
                  <a
                    href={company.instagram_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton
                      iconName="other_instagram"
                      color={COLORS.PRIMARY}
                      variant={VARIANTS.DEFAULT}
                    />
                  </a>
                </div>
              )}
              {company.twitter_url && (
                <div className={bem("info-link")}>
                  <a
                    href={company.twitter_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton
                      iconName="other_twitter"
                      color={COLORS.PRIMARY}
                      variant={VARIANTS.DEFAULT}
                    />
                  </a>
                </div>
              )}
              {company.facebook_url && (
                <div className={bem("info-link")}>
                  <a
                    href={company.facebook_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton
                      iconName="other_facebook"
                      color={COLORS.PRIMARY}
                      variant={VARIANTS.DEFAULT}
                    />
                  </a>
                </div>
              )}
            </div>
          </div>
          {company.about && (
            <div className={bem("expandable-text-block")}>
              <ExpandableText
                text={company.about}
                method="lines"
                limit={4}
                className={bem("expandable-text-block-about")}
              />
              <hr className="line" />
            </div>
          )}
          <div className={bem("locations-mobile")}>
            <Breadcrumbs
              rootCrumbName={`Locations (${
                company.locations ? company.locations.length : 0
              })`}
              breadcrumbs={[]}
            />
            <div>
              {company.locations?.map(
                (location: CompanyLocationItemFragment) => (
                  <Link
                    className={bem("locations-location")}
                    to={`/company/${company.id}/location/${location.slug}`}
                    key={location.slug}
                  >
                    <LocationPreview
                      value={location as TLocation}
                      aspectRatio="16:9"
                    />
                  </Link>
                )
              )}
            </div>
            <hr className="line" />
          </div>
          {/* POI-1180 - Temporarily hiding this code with a comment */}
          {/* {artists && artists?.length > 0 && (
            <div className={bem("category")}>
              <Slider
                title="Artists"
                navigationConfig={{ position: "top-right" }}
              >
                {artists.map((artist: any) => {
                  // TODO get location from artist info
                  if (user?.impersonate_name) {
                    return (
                      <ArtistPreview
                        value={artist}
                        availableTime={artist?.possibleAppointment?.start_time}
                        key={artist.id}
                      />
                    );
                  }
                  return (
                    <Link to={`/artists/${artist.id}`}>
                      <ArtistPreview
                        value={artist}
                        availableTime={artist?.possibleAppointment?.start_time}
                        key={artist.id}
                      />
                    </Link>
                  );
                })}
              </Slider>
            </div>
          )} */}
          {company?.amenities_enums?.length > 0 && (
            <Amenities
              amenitiesData={company?.amenities_enums}
              className="px-4"
            />
          )}
          <div className={bem("category")}>
            <hr className="line" />
            <h4>Additional</h4>
            <div className={bem("additional-list")}>
              <Button
                className="!bg-surface"
                color={COLORS.PRIMARY}
                text="Refund & Cancellation Policy"
                iconRightName="arrow_right"
                size="sm"
                variant={VARIANTS.DEFAULT}
                onClick={() => {
                  setShowTextModal(true);
                  setTextModalData({
                    title: "Refund & Cancellation",
                    text: getRefundAndCancellationHtml(
                      company.late_cancellation_hours,
                      company.late_cancellation_charge,
                      company.no_show_charge,
                      company.payment_type
                    )
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className={bem("locations")}>
          <Breadcrumbs
            rootCrumbName={`Locations (${
              company.locations ? company.locations.length : 0
            })`}
            breadcrumbs={[]}
          />
          <div>
            {company.locations &&
              company.locations.map(
                (location: CompanyLocationItemFragment, i: number) => (
                  <Link
                    className={bem("locations-location")}
                    to={`/company/${params.slug}/location/${location.slug}`}
                    key={i}
                  >
                    <LocationPreview
                      value={location as TLocation}
                      aspectRatio="16:9"
                    />
                  </Link>
                )
              )}
          </div>
        </div>
      </TwoColsLayout>
      <FormattedTextModal
        show={showTextModal}
        onHide={() => setShowTextModal(false)}
        title={textModalData?.title}
        text={textModalData?.text || ""}
        className={bem("refund-cancellation")}
      />
    </div>
  );
}

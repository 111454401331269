import "./CheckboxFormElement.scss";
import { Checkbox } from "elements/checkbox/Checkbox";
import { COLORS } from "models/colors";
import { bemElement } from "utils/bem-class-names";
import { IFormElement } from "../FormBuilder";

const baseClassName = "checkbox-form-element";
const bem = bemElement(baseClassName);

interface ICheckboxFormElementProps {
  element: IFormElement;
}

export const CheckboxFormElement = ({
  element
}: ICheckboxFormElementProps): JSX.Element => {
  return (
    <div className={baseClassName}>
      <div className={bem("body")}>
        <Checkbox
          className="-ml-11px"
          id={element.name}
          color={
            element.props.errorMsg
              ? COLORS.ERROR
              : element.props.color || COLORS.PRIMARY
          }
          checked={element.props.checked || false}
          onChange={element.props.onChange || (() => {})}
          {...element.props}
        />
        <label className={bem("label")} htmlFor={element.name}>
          {element.props.label}
        </label>
      </div>
      <div className={bem("error-container")}>
        {element.props.errorMsg && <span>{element.props.errorMsg}</span>}
      </div>
    </div>
  );
};

import "./center-default.scss";
import { SegmentedControl } from "elements/segmented-control/SegmentedControl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "providers/user";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { joinClassNames } from "utils/join-class-names";
import { APP_ROUTES } from "routes";
import useResponsive from "hooks/useResponsive";

const baseClassName = "center-default";

interface ICenterDefaultProps {
  className?: string;
}

export const CenterDefault = ({
  className = ""
}: ICenterDefaultProps): JSX.Element => {
  const { user } = useUser();
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMeRoute =
    pathname.startsWith(APP_ROUTES.ME) ||
    pathname.includes(APP_ROUTES.ME + "/");

  const navigateTo = (link: string): void => {
    navigate(link);
  };

  return (
    <div className={joinClassNames(baseClassName, className)}>
      {user ? (
        <SegmentedControl
          leftButtonActive={!isMeRoute}
          rightIconMonogram={user?.first_name || ""}
          rightIconSrc={user?.photo_url || ""}
          rightIconTitle="Me"
          onLeftButtonClick={() => navigateTo(APP_ROUTES.HOME)}
          onRightButtonClick={() => navigateTo(APP_ROUTES.ME)}
        />
      ) : (
        <div>
          <Link to={APP_ROUTES.HOME}>
            <SvgIcon
              name={isMobile ? "logo_colorized_mobile" : "logo_colorized"}
            />
          </Link>
        </div>
      )}
    </div>
  );
};

import "./CreditCard.scss";
import { Desktop, Mobile } from "components/responsive/Responsive";
import { IconButton } from "elements/icon-button/IconButton";
import { Radio } from "elements/radio/Radio";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { StripePaymentMethodFragment } from "hooks/use-payment/PaymentMethodsSection.generated";
import useResponsive from "hooks/useResponsive";
import { COLORS } from "models/colors";
import { VARIANTS } from "models/variants";
import { useState } from "react";
import { bemElement, bemModifier } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";

interface ICardDataProps {
  card: StripePaymentMethodFragment;
  cyId?: string;
  idDefault: boolean;
  deleting?: boolean;
  onClick: () => void;
  onDelete?: (id: string) => void;
  checked: boolean;
}

const baseClassName = "credit-card";
const bem = bemElement(baseClassName);

export const CreditCard = ({
  card,
  idDefault,
  deleting = false,
  cyId = "",
  onClick,
  onDelete,
  checked
}: ICardDataProps) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const { isMobile } = useResponsive();

  return (
    <div
      className={joinClassNames(
        bemModifier(baseClassName, {
          hovered: hovered,
          deleting: deleting
        }),
        "hide-scroll-bar"
      )}
    >
      <div
        className={bem("container")}
        key={card.id}
        aria-hidden
        onClick={onClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div className={bem("container-left-side")}>
          <SvgIcon name={`other_${card.brand.toLowerCase()}`} />
          <div>
            <div className={bem("container-title")}>
              {card.brand} *{card.last4}{" "}
              {idDefault && (
                <span
                  className={bem("container-default")}
                  data-cy={`${cyId}-default`}
                >
                  (Default)
                </span>
              )}
            </div>
            <div className={bem("container-expiry")}>
              {card.exp_month}/{card.exp_year}
            </div>
          </div>
        </div>
        <Radio
          color={COLORS.PRIMARY}
          value={card.id}
          cyId={cyId}
          checked={checked}
          onChange={onClick}
        />
      </div>
      {onDelete && (
        <div
          aria-hidden
          className={bem("delete")}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={() => isMobile && onDelete(card.id)}
          data-cy={`${cyId}-delete`}
        >
          <Mobile>
            <SvgIcon name="delete" />
          </Mobile>
          <Desktop>
            {hovered && (
              <IconButton
                className={bem("delete-button")}
                color={COLORS.ERROR_CONTAINER}
                variant={VARIANTS.FILLED}
                data-cy={`${cyId}-delete`}
                iconName="delete"
                onClick={() => onDelete(card.id)}
              />
            )}
          </Desktop>
        </div>
      )}
    </div>
  );
};

import "./DeleteAccountSection.scss";
import React from "react";
import { bemElement } from "../../../utils/bem-class-names";
import { SvgIcon } from "../../../elements/svg-icon/svg-icon";
import { Button } from "../../../elements/button/Button";
import { COLORS } from "../../../models/colors";
import { VARIANTS } from "../../../models/variants";

const baseClassName = "delete-account-section";
const bem = bemElement(baseClassName);

const DeleteAccountSection = () => {
  return (
    <div className={baseClassName}>
      <div className={bem("trash")}>
        <SvgIcon name="delete" className={bem("trash-icon")} />
      </div>
      <div className={bem("text")}>
        Are you sure you want to delete your Pointment account permanently?
      </div>
      <Button
        className={bem("button")}
        color={COLORS.ERROR}
        variant={VARIANTS.FILLED}
        text="Delete account"
      />
    </div>
  );
};

export default DeleteAccountSection;

import "./Button.scss";
import { ForwardedRef, forwardRef, MouseEventHandler } from "react";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { COLORS } from "models/colors";
import { VARIANTS } from "models/variants";
import { joinClassNames } from "utils/join-class-names";

export type TButtonSize = "lg" | "sm";
export type TButtonType = "button" | "submit" | "reset";

export interface IButtonProps {
  color: COLORS;
  text: string;
  className?: string;
  cyId?: string;
  disabled?: boolean;
  size?: TButtonSize;
  stretch?: boolean;
  submitting?: boolean;
  variant?: VARIANTS;
  iconLeftName?: string;
  iconRightName?: string;
  type?: TButtonType;
  onClick?: MouseEventHandler;
  form?: string;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (props: IButtonProps, ref: ForwardedRef<HTMLButtonElement>): JSX.Element => {
    const {
      color,
      text,
      type = "button",
      className = "",
      disabled,
      size = "lg",
      stretch,
      submitting,
      variant = VARIANTS.DEFAULT,
      iconLeftName,
      iconRightName,
      onClick,
      cyId,
      form
    } = props;

    const onClickHandler = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void => {
      if (submitting) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        onClick && onClick(event);
      }
    };

    return (
      <button
        className={joinClassNames(
          "button",
          size,
          stretch ? "stretch" : "",
          color,
          variant,
          className
        )}
        ref={ref}
        data-cy={cyId}
        onClick={onClickHandler}
        type={type}
        disabled={disabled}
        form={form}
      >
        <div className="state-layer">
          <span className="content">
            {iconLeftName && (
              <SvgIcon className="icon-left" name={iconLeftName} />
            )}
            {submitting && <SvgIcon className="submitting" name="loading" />}
            {text}
            {iconRightName && (
              <SvgIcon className="icon-right" name={iconRightName} />
            )}
          </span>
        </div>
      </button>
    );
  }
);

import * as Types from "../../../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ReminderSettingsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.ReminderSettingWhereInput>;
  orderBy?: Types.InputMaybe<
    | Array<Types.ReminderSettingOrderByWithRelationInput>
    | Types.ReminderSettingOrderByWithRelationInput
  >;
}>;

export type ReminderSettingsQuery = {
  __typename?: "Query";
  reminderSettings: Array<{
    __typename?: "ReminderSetting";
    id: string;
    type: Types.ReminderType;
    enabled: boolean;
    value: string;
  }>;
};

export type ReminderSettingFragment = {
  __typename?: "ReminderSetting";
  id: string;
  type: Types.ReminderType;
  enabled: boolean;
  value: string;
};

export type UpdateReminderSettingMutationVariables = Types.Exact<{
  data: Types.ReminderSettingUpdateInput;
  where: Types.ReminderSettingWhereUniqueInput;
}>;

export type UpdateReminderSettingMutation = {
  __typename?: "Mutation";
  updateOneReminderSetting?: {
    __typename?: "ReminderSetting";
    id: string;
  } | null;
};

export const ReminderSettingFragmentDoc = gql`
  fragment ReminderSetting on ReminderSetting {
    id
    type
    enabled
    value
  }
`;
export const ReminderSettingsDocument = gql`
  query ReminderSettings(
    $where: ReminderSettingWhereInput
    $orderBy: [ReminderSettingOrderByWithRelationInput!]
  ) {
    reminderSettings(where: $where, orderBy: $orderBy) {
      ...ReminderSetting
    }
  }
  ${ReminderSettingFragmentDoc}
`;

/**
 * __useReminderSettingsQuery__
 *
 * To run a query within a React component, call `useReminderSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReminderSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReminderSettingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useReminderSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReminderSettingsQuery,
    ReminderSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReminderSettingsQuery, ReminderSettingsQueryVariables>(
    ReminderSettingsDocument,
    options
  );
}
export function useReminderSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReminderSettingsQuery,
    ReminderSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReminderSettingsQuery,
    ReminderSettingsQueryVariables
  >(ReminderSettingsDocument, options);
}
export type ReminderSettingsQueryHookResult = ReturnType<
  typeof useReminderSettingsQuery
>;
export type ReminderSettingsLazyQueryHookResult = ReturnType<
  typeof useReminderSettingsLazyQuery
>;
export type ReminderSettingsQueryResult = Apollo.QueryResult<
  ReminderSettingsQuery,
  ReminderSettingsQueryVariables
>;
export const UpdateReminderSettingDocument = gql`
  mutation UpdateReminderSetting(
    $data: ReminderSettingUpdateInput!
    $where: ReminderSettingWhereUniqueInput!
  ) {
    updateOneReminderSetting(data: $data, where: $where) {
      id
    }
  }
`;
export type UpdateReminderSettingMutationFn = Apollo.MutationFunction<
  UpdateReminderSettingMutation,
  UpdateReminderSettingMutationVariables
>;

/**
 * __useUpdateReminderSettingMutation__
 *
 * To run a mutation, you first call `useUpdateReminderSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReminderSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReminderSettingMutation, { data, loading, error }] = useUpdateReminderSettingMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateReminderSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReminderSettingMutation,
    UpdateReminderSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateReminderSettingMutation,
    UpdateReminderSettingMutationVariables
  >(UpdateReminderSettingDocument, options);
}
export type UpdateReminderSettingMutationHookResult = ReturnType<
  typeof useUpdateReminderSettingMutation
>;
export type UpdateReminderSettingMutationResult =
  Apollo.MutationResult<UpdateReminderSettingMutation>;
export type UpdateReminderSettingMutationOptions = Apollo.BaseMutationOptions<
  UpdateReminderSettingMutation,
  UpdateReminderSettingMutationVariables
>;

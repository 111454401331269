/* eslint-disable max-len */
import "./ResetPasswordForm.scss";
import React, { useMemo, useState } from "react";
import {
  FormBuilder,
  generateFormElementConfigs,
  IFormElement
} from "components/form-builder";
import { Button } from "elements/button/Button";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { IForm, useForm } from "hooks/use-form";
import { COLORS } from "models/colors";
import { VARIANTS } from "models/variants";
import { bemElement } from "utils/bem-class-names";
import { validators } from "validators";
import { joinClassNames } from "utils/join-class-names";

const baseClassName = "reset-password-form";
const bem = bemElement(baseClassName);
const defaultDescription =
  "Enter the email address associated with your account, and we'll send you a link to reset your password.";
const resetPasswordFormConfig = {
  email: ""
};

const getFormElements = (form: IForm): IFormElement[] => {
  return generateFormElementConfigs(form, [
    {
      type: "Input",
      formValueName: "email",
      extraProps: {
        label: "Email",
        type: "email"
      }
    }
  ]);
};

export interface IResetPasswordFormProps {
  className?: string;
  onResetPassword?: () => void;
  onRedirectToLogin?: () => void;
  description?: string;
  onErrorMessage?: (error: string) => void;
}

export const ResetPasswordForm = ({
  className = "",
  onResetPassword,
  onRedirectToLogin,
  description = defaultDescription,
  onErrorMessage
}: IResetPasswordFormProps) => {
  const [resetPasswordSubmitting, setResetPasswordSubmitting] =
    useState<boolean>(false);
  const form = useForm(resetPasswordFormConfig, {
    email: [validators.required(), validators.email()]
  });
  const formElements = useMemo(() => getFormElements(form), [form]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.setSubmitted(true);

    if (!form.valid) {
      return;
    }

    setResetPasswordSubmitting(true);

    setTimeout(() => {
      setResetPasswordSubmitting(false);
      onResetPassword && onResetPassword();
      onRedirectToLogin && onRedirectToLogin();
    }, 3000);
  };

  return (
    <div className={joinClassNames(baseClassName, className)}>
      {form.submitted && form.valid && !resetPasswordSubmitting ? (
        <div className={bem("successfully")}>
          <div className={bem("successfully-checkmark")}>
            <SvgIcon
              name="checkmark"
              className={bem("successfully-checkmark-icon")}
            />
          </div>
          <div className={bem("successfully-title")}>Successfully</div>
          <div className={bem("successfully-text")}>
            <div className={bem("successfully-text-message")}>
              We have sent you a reset link to the:
            </div>
            <div className={bem("successfully-text-email")}>
              {typeof form.values.email === "string" && form.values.email}
            </div>
          </div>
          <Button
            color={COLORS.PRIMARY}
            variant={VARIANTS.FILLED}
            text="Go to Log in"
            className={bem("successfully-button")}
            onClick={onRedirectToLogin}
          />
        </div>
      ) : (
        <>
          <h2>Reset your password</h2>
          <div className={bem("message")}>{description}</div>
          <FormBuilder
            className={bem("form")}
            elements={formElements}
            onSubmit={onSubmit}
          >
            <div className={bem("form-controls")}>
              <Button
                className="flexible flex-1"
                color={COLORS.PRIMARY}
                variant={VARIANTS.OUTLINED}
                text="Return to sign in"
                iconLeftName="arrow_left"
                onClick={onRedirectToLogin}
              />
              <Button
                color={COLORS.PRIMARY}
                variant={VARIANTS.FILLED}
                text="Continue"
                disabled={
                  (form.submitted && !form.valid) || resetPasswordSubmitting
                }
                type="submit"
              />
            </div>
          </FormBuilder>
        </>
      )}
    </div>
  );
};

export default ResetPasswordForm;

import { joinClassNames } from "utils/join-class-names";
import "./wavy-line.scss";
import { bemElement } from "utils/bem-class-names";

const baseClassName = "wavy-line";
const bem = bemElement(baseClassName);

interface IWavyLineProps {
  className?: string;
}

export const WavyLine = ({ className = "" }: IWavyLineProps): JSX.Element => {
  return (
    <div className={joinClassNames(baseClassName, className)}>
      <div className={bem("ellipse")}></div>
      <div className={joinClassNames(bem("ellipse"), bem("ellipse2"))}></div>
    </div>
  );
};

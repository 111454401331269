import "./AddCreditCardModal.scss";
import SlidingModal from "../sliding-modal/SlidingModal";
import useResponsive from "../../../hooks/useResponsive";
import { bemElement } from "../../../utils/bem-class-names";

const baseClassName = "add-credit-card-modal";
const bem = bemElement(baseClassName);

interface IAddCreditCardModalData {
  show: boolean;
  onHide: () => void;
  children?: JSX.Element;
}

const AddCreditCardModal = ({
  show,
  onHide,
  children
}: IAddCreditCardModalData) => {
  const { deviceHeight } = useResponsive();

  return (
    <SlidingModal
      className={baseClassName}
      slideTo={deviceHeight - 300}
      title="Add credit card"
      show={show}
      onHide={onHide}
    >
      <div className={bem("content")}>{children}</div>
    </SlidingModal>
  );
};

export default AddCreditCardModal;

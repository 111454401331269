import "./Radio.scss";
import { ForwardedRef, forwardRef } from "react";
import { COLORS } from "models/colors";
import { joinClassNames } from "utils/join-class-names";

interface IRadioProps {
  color: COLORS;
  value: string;
  checked?: boolean;
  className?: string;
  cyId?: string;
  disabled?: boolean;
  id?: string;
  onChange: (value: string) => void;
}

export const Radio = forwardRef<HTMLSpanElement, IRadioProps>(
  (props: IRadioProps, ref: ForwardedRef<HTMLSpanElement>): JSX.Element => {
    const {
      color,
      value,
      checked,
      className = "",
      cyId = "",
      disabled,
      id,
      onChange
    } = props;

    const toggleCheck = (): void => onChange(value);

    return (
      <span
        className={joinClassNames(
          "radio",
          color,
          checked ? "checked" : "",
          disabled ? "disabled" : "",
          className
        )}
        data-cy={cyId}
        ref={ref}
      >
        <label htmlFor={id} className="state-layer">
          <div className="radio__container">
            <div className="mark"></div>
          </div>
        </label>
        <input
          id={id}
          checked={checked}
          type="radio"
          disabled={disabled}
          value={value}
          onChange={toggleCheck}
        />
      </span>
    );
  }
);

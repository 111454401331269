import "./SegmentedControl.scss";
import { bemElement, bemModifier } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { Avatar } from "elements/avatar/Avatar";

interface ISegmentedControlProps {
  leftButtonActive: boolean;
  rightIconMonogram: string;
  rightIconSrc: string;
  rightIconTitle: string;
  className?: string;
  onLeftButtonClick?: () => void;
  onRightButtonClick?: () => void;
}

const baseClassName = "segmented-control";
const bem = bemElement(baseClassName);

export const SegmentedControl = ({
  leftButtonActive,
  rightIconMonogram,
  rightIconSrc,
  rightIconTitle,
  className = "",
  onLeftButtonClick = () => {},
  onRightButtonClick = () => {}
}: ISegmentedControlProps): JSX.Element => {
  const onLeftButtonClickHandler = () => {
    onLeftButtonClick();
  };

  const onRightButtonClickHandler = () => {
    onRightButtonClick();
  };

  return (
    <div className={joinClassNames(baseClassName, className)}>
      <div
        className={bemModifier(bem("button"), { active: leftButtonActive })}
        aria-hidden
        onClick={onLeftButtonClickHandler}
      >
        <SvgIcon className={bem("logo")} name="logo_colorized_mobile" />
      </div>
      <div
        className={bemModifier(bem("button"), { active: !leftButtonActive })}
        aria-hidden
        onClick={onRightButtonClickHandler}
      >
        <Avatar
          key={rightIconSrc}
          className={bem("avatar")}
          src={rightIconSrc}
          monogram={rightIconMonogram}
          alt={rightIconMonogram}
          size="xs"
        />
        <span className={bem("title")}>{rightIconTitle}</span>
      </div>
    </div>
  );
};

/* eslint-disable max-len */
import { useCart } from "../../providers/cart-provider";
import { Avatar } from "elements/avatar/Avatar";

const dynamicStyles = [
  // 2 avatar bubble styles
  [
    "top-[6px] left-[6px] w-[24px] h-[24px] text-[18px]",
    "top-[26px] left-[25px] w-[16px] h-[16px] text-[14px]"
  ],
  // 3 avatar BubbleStyles
  [
    "top-[5px] left-[8px] w-[22px] h-[22px] text-[18px]",
    "top-[16px] left-[31px] w-[16px] h-[16px] text-[14px]",
    "top-[29px] left-[15px] w-[16px] h-[16px] text-[14px]"
  ],
  // 4 avatar bubble styles
  [
    "top-[7px] left-[7px] w-[22px] h-[22px] text-[18px]",
    "top-[24px] left-[28px] w-[14px] h-[14px] text-[12px]",
    "top-[33px] left-[14px] w-[10px] h-[10px] text-[12px]",
    "top-[10px] left-[31px] w-[10px] h-[10px] text-[8px]"
  ],
  // 5 avatar bubble styles
  [
    "top-[7px] left-[7px] w-[22px] h-[22px] text-[18px]",
    "top-[20px] left-[30px] w-[14px] h-[14px] text-[12px]",
    "top-[31px] left-[17px] w-[14px] h-[14px] text-[12px]",
    "top-[7px] left-[31px] w-[10px] h-[10px] text-[8px]",
    "top-[30px] left-[7px] w-[8px] h-[8px] text-[6px]"
  ]
];

const dynamicMonogramFontSizes = [
  // 2 avatar bubble styles
  ["text-18px", "text-[14px]"],
  // 3 avatar BubbleStyles
  ["text-[18px]", "text-[14px]", "text-[14px]"],
  // 4 avatar bubble styles
  ["text-[18px]", "text-[12px]", "text-[12px]", "text-[8px]"],
  // 5 avatar bubble styles
  ["text-[18px]", "text-[12px]", "text-[12px]", "text-[8px]", "text-[6px]"]
];

const artistColors = [
  "#F8C0E5",
  "#7ED9B8",
  "#7EC1DE",
  "#EDB59D",
  "#DE7EBD",
  "#AADF98",
  "#7E88DE",
  "#F0C899",
  "#BA7EDE",
  "#CFF8C0",
  "#C0E7F8",
  "#F8D1C0",
  "#E3C0F8",
  "#F8ECC0",
  "#BEF3E0",
  "#C0C6F8",
  "#F8DEC0"
];

export const SelectedArtistsBubble = () => {
  const { cart, artists, mainArtistId } = useCart();

  const unique =
    cart?.services?.[0]?.artist?.artist_id &&
    cart?.services
      ?.reduce((acc: any, e: any) => {
        const found = acc?.find(
          (x: any) => e?.artist?.artist_id === x?.artist_id
        );
        !found && acc?.push(e?.artist);
        return acc;
      }, [])
      ?.filter((artist: any) => artist !== undefined);

  const artistsWithoutMainArtist =
    unique?.filter((item: any) => item.artist_id !== mainArtistId) || [];

  const mainArtist = unique?.find(
    (item: any) => item.artist_id === mainArtistId
  );
  const uniqueArtists = mainArtist
    ? [mainArtist, ...artistsWithoutMainArtist]
    : artistsWithoutMainArtist;

  const isMultiPerson = !!uniqueArtists?.find((i: any) => i?.personIndex > 0);
  const firstArtist = artists?.find(
    (a) => a?.id === uniqueArtists?.[0]?.artist_id
  );
  const secondArtist = artists?.find(
    (a) => a?.id === uniqueArtists?.[1]?.artist_id
  );

  const mainArtistIndex = artists?.findIndex(
    (a) => a?.id === uniqueArtists?.[0]?.artist_id
  );

  return (
    <div className="">
      {uniqueArtists?.length === 1 ? (
        <div
          className="border-[4px] rounded-full"
          style={{
            borderColor:
              mainArtistIndex !== undefined
                ? artistColors?.[mainArtistIndex]
                : "transparent"
          }}
        >
          <Avatar
            key={firstArtist?.user?.first_name}
            size="md"
            monogram={firstArtist?.user?.first_name || ""}
            alt={firstArtist?.user?.first_name || ""}
            src={
              firstArtist?.cover_photo_url || firstArtist?.user?.photo_url || ""
            }
          />
        </div>
      ) : uniqueArtists?.length === 2 && !isMultiPerson ? (
        <div className="flex items-center relative">
          <div
            className="border-[4px] rounded-[28px] z-[2]"
            style={{
              borderColor:
                mainArtistIndex !== undefined
                  ? artistColors?.[mainArtistIndex]
                  : "transparent"
            }}
          >
            <Avatar
              key={firstArtist?.user?.first_name}
              size="md"
              monogram={firstArtist?.user?.first_name || ""}
              alt={firstArtist?.user?.first_name || ""}
              src={
                firstArtist?.cover_photo_url ||
                firstArtist?.user?.photo_url ||
                ""
              }
            />
          </div>
          <div className="w-[32px]">
            <div className="absolute top-[4px] left-[36px] z-[1]">
              <Avatar
                key={secondArtist?.user?.first_name}
                size="md"
                monogram={secondArtist?.user?.first_name || ""}
                alt={secondArtist?.user?.first_name || ""}
                src={
                  secondArtist?.cover_photo_url ||
                  secondArtist?.user?.photo_url ||
                  ""
                }
              />
            </div>
          </div>
        </div>
      ) : uniqueArtists?.length === 2 && isMultiPerson ? (
        <div
          className="flex items-center relative rounded-[28px]"
          style={{
            backgroundColor:
              mainArtistIndex !== undefined
                ? artistColors?.[mainArtistIndex]
                : "transparent"
          }}
        >
          <div
            className="border-[4px] rounded-[28px] z-[2]"
            style={{
              borderColor:
                mainArtistIndex !== undefined
                  ? artistColors?.[mainArtistIndex]
                  : "transparent"
            }}
          >
            <Avatar
              key={firstArtist?.user?.first_name}
              size="md"
              monogram={firstArtist?.user?.first_name || ""}
              alt={firstArtist?.user?.first_name || ""}
              src={
                firstArtist?.cover_photo_url ||
                firstArtist?.user?.photo_url ||
                ""
              }
            />
          </div>
          <div className="w-[32px]">
            <div className="absolute top-[4px] left-[36px] z-[1]">
              <Avatar
                key={secondArtist?.user?.first_name}
                size="md"
                monogram={secondArtist?.user?.first_name || ""}
                alt={secondArtist?.user?.first_name || ""}
                src={
                  secondArtist?.cover_photo_url ||
                  secondArtist?.user?.photo_url ||
                  ""
                }
              />
            </div>
          </div>
        </div>
      ) : uniqueArtists?.length > 2 ? (
        <div
          className="flex items-center gap-[2px] rounded-[28px] p-1"
          style={{
            backgroundColor:
              mainArtistIndex !== undefined
                ? artistColors?.[mainArtistIndex]
                : "transparent"
          }}
        >
          <Avatar
            key={firstArtist?.user?.first_name}
            size="md"
            monogram={firstArtist?.user?.first_name || ""}
            alt={firstArtist?.user?.first_name || ""}
            src={
              firstArtist?.cover_photo_url || firstArtist?.user?.photo_url || ""
            }
          />
          <div className="w-[48px] h-[48px] bg-white rounded-[28px] relative">
            {uniqueArtists?.slice(1, 6)?.map((item: any, index: number) => {
              const foundArtist = artists?.find(
                (a) => a?.id === item?.artist_id
              );
              return (
                <div
                  key={item?.artist_id}
                  className={`absolute rounded-full ${
                    dynamicStyles?.[
                      uniqueArtists?.length - 3 > 3
                        ? 3
                        : uniqueArtists?.length - 3
                    ]?.[index]
                  }`}
                >
                  <Avatar
                    key={item?.artist_id}
                    className="min-w-[8px] min-h-[8px] w-full h-full"
                    monogram={foundArtist?.user?.first_name || ""}
                    alt={foundArtist?.user?.first_name || ""}
                    src={
                      foundArtist?.cover_photo_url ||
                      foundArtist?.user?.photo_url ||
                      ""
                    }
                    monogramFontSize={
                      dynamicMonogramFontSizes?.[
                        uniqueArtists?.length - 3 > 3
                          ? 3
                          : uniqueArtists?.length - 3
                      ]?.[index]
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

import { bemElement } from "utils/bem-class-names";
import "./PrivacyPolicy.scss";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import TocPrivacyPolicyFooter from "components/toc-privacypolicy-footer/TocPrivacyPolicyFooter";

const baseClassName = "privacy-policy";
const bem = bemElement(baseClassName);

const PrivacyPolicy = () => {
  return (
    <div className={baseClassName}>
      <div className={bem("title-container")}>
        <h3>Privacy Policy</h3>
        <div className={bem("last-updated")}>
          Effective Date: January 28, 2024{" "}
          <SvgIcon name="arrow_bottom" className={bem("arrow-icon")} />
        </div>
      </div>
      <section className={bem("contents")}>
        <article className={bem("content")}>
          <h5>1. Introduction</h5>
          <h6>1.1. Purpose of Policy.</h6>
          <p>
            Pointment, Inc (“Company”) is committed to respecting the privacy
            rights of all users of the Company’s Website (the “Site”) and Mobile
            Application (the “App”).{" "}
            <span className={bem("warning-text")}>
              Subscribers refers to any customer or business that uses or
              subscribes to Pointment software or services, including but not
              limited to Subscriber’s employees, advisors, contractors, agents,
              consultants
            </span>{" "}
            or others acting on behalf of the Subscriber. Guest refers to our
            Subscribers’ customer.
            <br />
            <br />
            We created this Website and Mobile Application Privacy Policy (this
            “Policy”) to give you confidence as you visit and use the Site, and
            to demonstrate our commitment to fair information practices and the
            protection of privacy. This policy is only applicable to the Site
            and the App, and not to any other websites or mobile applications
            that may be accessible from the Site or the App, each of which may
            have data collection and use practices and policies that differ
            materially from this Policy.
          </p>
          <h6>1.2. NOTICE CONCERNING CHILDREN</h6>
          <p>
            PLEASE NOTE: We are a general audience site, and do not direct any
            of our content specifically at children under thirteen (13) years of
            age. We understand and are committed to respecting the sensitive
            nature of children’s privacy online. If we learn or have reason to
            suspect that a Site user is under age of thirteen (13), we will
            promptly delete any personal information in that user’s account once
            we have verified the request to delete the personal information per
            Section 8.4.3.
          </p>
        </article>
        <article className={bem("content")}>
          <h5>2. Information Collection Practices</h5>
          <h6>2.1. WHAT BASIC INFORMATION DOES THE COMPANY COLLECT?</h6>
          <p>
            We collect information that identifies, relates to, describes,
            references, is reasonably capable of being associated with, or could
            be reasonably linked, directly or indirectly, with a particular
            consumer, household, or device <b>(“personal information”)</b>.
            Personal information does not include deidentified or aggregated
            consumer information.
            <br />
            <br />
            In particular, we collect the following categories of personal
            information from consumers:
            <br />
            <br />
          </p>
          <ul className={bem("list")}>
            <li className={bem("list-item")}>
              Contact information, such as your name, address, phone, email, and
              other similar information.
            </li>
            <li className={bem("list-item")}>
              Financial information, such as the full debit card numbers and/or
              credit card numbers that you link to your account or give us when
              you purchase our services.
            </li>
            <li className={bem("list-item")}>
              Personal information such as photographs, preferred pronouns, and
              a record of services purchased.
            </li>
            <li className={bem("list-item")}>
              Any content that you post to our company social media channels, or
              tag to include our company social accounts.
            </li>
            <li className={bem("list-item")}>
              IP addresses (the number assigned to computers on the Internet).
            </li>
            <li className={bem("list-item")}>
              Location information from your mobile device.
            </li>
          </ul>
          <p>
            We obtain the categories of personal information listed above
            directly from you through the forms you complete or the products and
            services you purchase.
          </p>
          <h6>2.2. WHAT SUBSCRIBER INFORMATION DOES THE COMPANY COLLECT?</h6>
          <p>
            Some of our services include processing of data, including the
            Personal Information of Guests, on behalf of our Subscribers in
            relation to applications, tools, or software that we provide. The
            Personal Information we collect and process on behalf of our
            Subscribers may include the following:
          </p>
          <ul className={bem("list")}>
            <li className={bem("list-item")}>
              Demographic & Identity Data: We may collect personal data of the
              Guest (our Subscriber’s guest or customer) such as first name,
              last name, email, address, contact number, gender, date of birth,
              photograph, and signature. Additionally, location & device ID are
              also collected when using mobile apps.
            </li>
            <li className={bem("list-item")}>
              Financial Data: Data collected from the Guest may include name on
              the card, last 4 digits of the card number, card expiry date, card
              scheme, card token no. CVV information is also collected but not
              stored at our end but shared with the payment processor.
            </li>
            <li className={bem("list-item")}>
              Employee Information: We may collect personal data of the Employee
              (our Subscriber’s employees, advisors, contractors, agents,
              consultants, or others acting on behalf of the Subscriber) such as
              first name, last name, email, address, contact number, gender,
              date of birth, photograph, signature. Additionally, location,
              device ID and IP addresses are also collected when using mobile
              apps.
            </li>
          </ul>
          <span className={bem("warning-text")}>
            Save for the limited circumstances set out in this Privacy Policy,
            we are not the data controller of this information as we do not
            determine the purposes or the means of the processing.
          </span>
          <h6>2.3. WHAT ADDITIONAL INFORMATION DOES COMPANY COLLECT?</h6>
          <p>
            The categories of information described below are collected
            indirectly from you:
          </p>
          <p>
            (a) AUTOMATIC COLLECTION. The Site may also gather anonymous
            “traffic data” that may not personally identify you, but that may be
            helpful for marketing purposes or for improving the services we
            offer.
          </p>
          <p>
            (b) COOKIES. From time to time, we may use the standard “cookies”
            feature of major browser applications that allows us to store a
            small piece of data on your computer or mobile device about your
            visit to our Site or App. We do not employ any data capture
            mechanisms on our website other than cookies. Cookies help us learn
            which areas of our Site and App are useful and which areas need
            improvement. You can choose whether to accept cookies by changing
            the settings on your browser. However, if you choose to disable this
            function, your experience at our Site and on our App may be
            diminished and some features may not work as they were intended.
          </p>
        </article>
        <article className={bem("content")}>
          <h5>3. Use and Sharing of Information</h5>
          <h6>3.1. WHAT DOES COMPANY DO WITH COLLECTED INFORMATION?</h6>
          <p>
            (a) PERSONAL INFORMATION. We will not share your personal
            information with any third parties without your consent, except as
            necessary to provide you with the services offered by us or to
            comply with the law. For instance, when making a purchase on either
            our Site or App, your credit card information is transmitted to and
            processed by Stripe, our payment processor. You may view Stripe’s
            privacy policy at{" "}
            <a
              href="http://www.stripe.com/privacy"
              className={bem("link")}
              target="_blank"
              rel="noreferrer"
            >
              http://www.stripe.com/privacy
            </a>
            . We may use your personal information to verify your identity or to
            follow up with transactions initiated on the Site or the App. We may
            also use your contact information to inform you of any changes to
            the Site or the App, or to send you additional information about
            Company.{" "}
            <span className={bem("warning-text")}>
              Select personal information of Guests is shared at-will with
              Subscriber partners when Pointment is used to book services with
              that Subscriber.
            </span>{" "}
            Finally,{" "}
            <span className={bem("warning-text")}> Subscriber and Guest</span>{" "}
            personal information{" "}
            <span className={bem("warning-text")}> may be used</span> for
            marketing purposes. Company uses Flodesk to manage its email
            marketing campaigns. You can view the Flodesk privacy policy at{" "}
            <a
              href="https://flodesk.com/privacy-policy"
              className={bem("link")}
              target="_blank"
              rel="noreferrer"
            >
              https://flodesk.com/privacy-policy
            </a>
            .
            <br />
            <br />
            (b) ANONYMOUS INFORMATION. We use anonymous information to analyze
            the traffic on our Site and App, but we do not examine this
            information for individually identifying information. In addition,
            we may use anonymous IP addresses to help diagnose problems with our
            server, to administer our site, or to display the content according
            to your preferences. Traffic and transaction information may also be
            shared with business partners and advertisers on an aggregate and
            anonymous basis.
            <br />
            <br />
            (c) USE OF COOKIES. We may use cookies to deliver content specific
            to your interests, to save your password so you don’t have to
            re-enter it each time you visit our site, or for other purposes.
            Promotions or advertisements displayed on our site may contain
            cookies. We do not have access to or control over information
            collected by outside advertisers on our site.
            <br />
            <br />
            (d) DISCLOSURE OF PERSONAL INFORMATION. We may disclose personal
            information if required to do so by law or in the good-faith belief
            that such action is necessary to (1) conform to the edicts of the
            law or comply with legal process served on Company or its parent
            company, subsidiaries or affiliates, (2) protect and defend the
            rights or property of Company or the users of the Site, or (3) act
            under exigent circumstances to protect the safety of the public or
            users of the Site.
            <br />
            <br />
            (e) SALE OF INFORMATION. In order to accommodate changes in our
            business, we may sell portions of our company or buy other companies
            or assets, including the information collected through this Site and
            through the App. If Company or substantially all of its assets are
            acquired, customer information will be one of the assets transferred
            to the acquirer.
          </p>
        </article>
        <article className={bem("content")}>
          <h5>4. Security</h5>
          <p>
            The Site and the App have security measures in place to prevent the
            loss, misuse, and alteration of the information that we obtain from
            you, but we make no assurances about our ability to prevent any such
            loss, misuse, to you or to any third party arising out of any such
            loss, misuse, or alteration.
          </p>
        </article>
        <article className={bem("content")}>
          <h5>5. Third Party Websites & Social Media</h5>
          <h6>5.1. THIRD PARTY WEBSITES</h6>
          <p>
            The Site may contain links to other websites. If you choose to use
            these links to visit other websites, we are not responsible for the
            privacy practices or content of those other websites, and it is your
            responsibility to review the privacy policies at those websites to
            confirm that you understand and agree with their policies regarding
            your personal information.
          </p>
          <h6>5.2. SOCIAL MEDIA</h6>
          <p>
            Company utilizes a number of social media platforms in our marketing
            efforts. By posting comments, photos, and other content on our
            social media channels, you are consenting to Company sharing or
            reposting this content. We are not responsible for the privacy
            practices of third-party social media platforms. It is your
            responsibility to review the privacy policies on these platforms to
            confirm that you understand and agree with their policies regarding
            your personal information.
          </p>
        </article>
        <article className={bem("content")}>
          <h5>6. Contact Information and Policy Updates</h5>
          <h6>6.1. CONTACTING US</h6>
          <p>
            If you have any questions about this Policy, our practices related
            to this Site and/or the App, or if you would like to have us remove
            your information from our database, please contact us at the
            following:{" "}
            <a href="mailto:info@pointmentapp.com" className={bem("link")}>
              info@pointmentapp.com
            </a>
            <br />
          </p>
          <h6>6.2. UPDATES AND CHANGES</h6>
          <p>
            e reserve the right, at any time, to add to, change, update, or
            modify this Policy, simply by posting such change, update, or
            modification on the Site and the App without any other notice to
            you. Any such change, update, or modification will be effective
            immediately upon posting on the Site and/or the App. It is your
            responsibility to review this Policy from time to time to ensure
            that you continue to agree with all of its terms.
          </p>
          <h6>6.3. UNSUBSCRIBE POLICY</h6>
          <p>
            You can unsubscribe your name from our email and marketing lists by
            writing to us at{" "}
            <a href="mailto:info@pointmentapp.com" className={bem("link")}>
              info@pointmentapp.com
            </a>
            , or by clicking on the Unsubscribe link at the bottom of any of the
            emails you receive from us.
          </p>
        </article>
        <article className={bem("content")}>
          <h5>7. California Do Not Track Disclosures</h5>
          <p>
            We do not currently respond to your browser’s Do Not Track signal,
            and we do not permit third parties other than our analytics and
            service providers to track your activity over time on the Site or
            the App. We also do not track your online browsing activity on other
            online services over time.
          </p>
        </article>
        <article className={bem("content")}>
          <h5>8. CCPA Privacy Policy</h5>
          <p>
            This Privacy Policy for California Residents supplements the
            aforementioned information and applies solely to all visitors,
            users, and others who reside in the State of California (“consumers”
            or “you”). We adopt this notice to comply with the California
            Consumer Privacy Act of 2018 (CCPA) and any terms defined in the
            CCPA have the same meaning when used in this Notice.
          </p>
          <h6>8.1. INFORMATION WE COLLECT</h6>
          <p>
            When you register on our Site or our App, request information, make
            a purchase from us or otherwise provide us with information about
            you such as your name, username, password, email address, and phone
            number we collect and use this information as described in Sections
            2 and 3 above. When you make a purchase from us, we will collect
            your payment card information, but we do not store or process this
            information beyond providing it to Stripe, our payment processor as
            described in Section 3.1(a) above.
          </p>
          <p>
            <b>
              Please see Section 2 above for further details about the personal
              data we collect and the additional data that we collect.
            </b>
          </p>
          <h6>8.2. USE OF PERSONAL INFORMATION</h6>
          <p>
            <span className={bem("warning-text")}>
              We do not use Personal Information for purposes that are
              materially different to the purposes for which they were
              originally collected.
            </span>{" "}
            For example, if you share your name and contact information to
            request information about our services, we will use that personal
            information to respond to your inquiry. If you provide your personal
            information to purchase a product or service, we will use that
            information to process your payment and facilitate delivery.
          </p>
          <p>
            <b>
              Please see Section 3 above for further details about how we use
              the personal information we collect.
            </b>
          </p>
          <p>
            We will not collect additional categories of personal information or
            use the personal information we collected for materially different,
            unrelated, or incompatible purposes without providing you notice.
          </p>
          <h6>8.3. DISCLOSURE OF PERSONAL INFORMATION</h6>
          <p>
            We may disclose your personal information to a third party for a
            business purpose. When we disclose personal information for a
            business purpose, we enter a contract that describes the business
            purpose and requires the recipient to both keep that personal
            information confidential and not use it for any purpose except for
            the direct performance of the contract. The CCPA prohibits third
            parties who purchase the personal information we hold from reselling
            it unless you have received explicit notice that your personal
            information is subject to be sold and provides you with an
            opportunity to opt-out of further sales.
          </p>
          <p>
            We disclose the following categories of personal information with
            our payment processor to complete transactions initiated by our
            customers:
          </p>
          <ul className={bem("list")}>
            <li className={bem("list-item")}>
              Consumers’ full names and billing addresses; and
            </li>
            <li className={bem("list-item")}>Credit and debit card numbers.</li>
          </ul>
          <h6>8.3.2. SALES OF PERSONAL INFORMATION</h6>
          <p>
            As of the date of this Policy, the Company has not sold any of the
            personal information it has collected.
          </p>
          <h6>8.4. YOUR RIGHTS AND CHOICES</h6>
          <p>
            The CCPA provides consumers (California residents) with specific
            rights regarding personal information. This section describes your
            CCPA rights and explains how to exercise those rights.
          </p>
        </article>
      </section>
      <TocPrivacyPolicyFooter />
    </div>
  );
};

export default PrivacyPolicy;

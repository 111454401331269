import { useMemo } from "react";
import useWindowSize from "./useWindowSize";

export const BREAKPOINTS = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200
};

const useResponsive = () => {
  const { width: deviceWidth, height: deviceHeight } = useWindowSize();

  const isXS = useMemo<boolean>(
    () => deviceWidth < BREAKPOINTS.SM,
    [deviceWidth]
  );
  const isSM = useMemo<boolean>(
    () => deviceWidth >= BREAKPOINTS.SM && deviceWidth < BREAKPOINTS.MD,
    [deviceWidth]
  );
  const isMD = useMemo<boolean>(
    () => deviceWidth >= BREAKPOINTS.MD && deviceWidth < BREAKPOINTS.LG,
    [deviceWidth]
  );
  const isLG = useMemo<boolean>(
    () => deviceWidth >= BREAKPOINTS.LG && deviceWidth < BREAKPOINTS.XL,
    [deviceWidth]
  );
  const isXL = useMemo<boolean>(
    () => deviceWidth >= BREAKPOINTS.XL,
    [deviceWidth]
  );
  const isMobile = useMemo<boolean>(
    () => deviceWidth < BREAKPOINTS.LG,
    [deviceWidth]
  );
  const isDesktop = useMemo<boolean>(
    () => deviceWidth >= BREAKPOINTS.LG,
    [deviceWidth]
  );

  return {
    deviceWidth,
    deviceHeight,
    isXS,
    isSM,
    isMD,
    isLG,
    isXL,
    isMobile,
    isDesktop
  };
};

export default useResponsive;

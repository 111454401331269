import "./Notifications.scss";
import React, { useEffect, useState } from "react";
import { IconButton } from "../../../elements/icon-button/IconButton";
import { COLORS } from "../../../models/colors";
import { VARIANTS } from "../../../models/variants";
import { bemElement, bemModifier } from "../../../utils/bem-class-names";
import { joinClassNames } from "../../../utils/join-class-names";
import {
  NotificationFragment,
  useNotificationsQuery
} from "./Notifications.generated";
import { usePeopleCount } from "../../../hooks/use-people-count";
import { TAppointment } from "../../../types";
import { useHeader } from "../../../providers/header-provider";
import { ImageElement } from "../../../elements/image/Image";
import { getRelativeDateTime, getZonedDate } from "../../../utils/date-time";
import { Link, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../routes";
import { useUser } from "../../../providers/user";
import { SortOrder } from "../../../types.generated";
import { SvgIcon } from "../../../elements/svg-icon/svg-icon";
import { useTimeout } from "../../../hooks/use-timeout";
// eslint-disable-next-line
import { useUpdateUserMutation } from "../../account-details/edit-account-section/EditAccountSection.generated";

const baseClassName = "notifications";
const bem = bemElement(baseClassName);

interface INotificationData {
  notification: NotificationFragment;
}

const Notification = ({ notification }: INotificationData) => {
  const navigate = useNavigate();
  const { setShowNotifications } = useHeader();
  const { user, updateUser } = useUser();
  const peopleCount = usePeopleCount(
    notification.appointment as unknown as TAppointment
  );
  const [isViewed, setIsViewed] = useState<boolean>(
    new Date(user.last_viewed_notifications) > new Date(notification.created_at)
  );
  const [updateAccount] = useUpdateUserMutation();

  const { start, clear } = useTimeout(() => {
    setIsViewed(true);
    updateAccount({
      variables: {
        id: user.id,
        data: {
          last_viewed_notifications: {
            set: new Date().toISOString()
          }
        }
      },
      onCompleted: (data) => updateUser({ ...user, ...data.updateOneUser })
    });
  }, 2000);

  useEffect(() => {
    start();

    return clear;
  }, [clear, start]);

  const onClick = () => {
    navigate(`${APP_ROUTES.ME}/booking/${notification.appointment?.id}`);
    setShowNotifications(false);
  };

  return (
    <div aria-hidden className={bem("item")} onClick={onClick}>
      <div
        className={bemModifier(bem("item-image"), {
          past:
            getZonedDate(
              new Date().toISOString(),
              notification.appointment?.location.timezone
            ) >=
            getZonedDate(
              notification.appointment?.start_time,
              notification.appointment?.location.timezone
            )
        })}
      >
        <ImageElement
          alt=""
          aspectRatio="1:1"
          src={notification.appointment?.location.cover_photo_url || ""}
        />
        {getZonedDate(
          new Date().toISOString(),
          notification.appointment?.location.timezone
        ) >=
          getZonedDate(
            notification.appointment?.start_time,
            notification.appointment?.location.timezone
          ) && (
          <SvgIcon name="filled_star" className={bem("item-image-star-icon")} />
        )}
      </div>
      <div className={bem("info")}>
        <div
          className={bemModifier(bem("info-title"), {
            past:
              getZonedDate(
                new Date().toISOString(),
                notification.appointment?.location.timezone
              ) >=
              getZonedDate(
                notification.appointment?.start_time,
                notification.appointment?.location.timezone
              )
          })}
        >
          <div>
            {getZonedDate(
              new Date().toISOString(),
              notification.appointment?.location.timezone
            ) <
            getZonedDate(
              notification.appointment?.start_time,
              notification.appointment?.location.timezone
            )
              ? "Upcoming Pointment"
              : "Rate your Pointment"}
          </div>
          <div className={bemModifier(bem("info-marker"), { hide: isViewed })}>
            •
          </div>
        </div>
        <div className={bem("info-content")}>
          <span className={bem("info-content-truncated")}>
            {notification.appointment?.location.name}
          </span>
          <span className={bem("info-content-nowrap")}>
            &nbsp;•{" "}
            {peopleCount === 1
              ? `${peopleCount} person`
              : `${peopleCount} people`}
            &nbsp;•{" "}
            {getRelativeDateTime(
              notification.appointment?.start_time,
              notification.appointment?.location.timezone
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

interface INotificationsData {
  className?: string;
}

const Notifications = ({ className = "" }: INotificationsData) => {
  const { setShowNotifications } = useHeader();
  const { user } = useUser();
  const { data } = useNotificationsQuery({
    variables: {
      where: {
        user_id: {
          equals: user.id
        }
      },
      orderBy: {
        created_at: SortOrder.Desc
      }
    },
    skip: !user.id
  });
  const notifications = data?.notifications.filter(
    (notification) =>
      getZonedDate(
        new Date().toISOString(),
        notification.appointment?.location.timezone
      ) <
      getZonedDate(
        notification.appointment?.start_time,
        notification.appointment?.location.timezone
      )
  );

  if (!notifications) {
    return null;
  }

  return (
    <div className={joinClassNames(bem("wrapper"), className)}>
      <div className={bem("header")}>
        <IconButton
          color={COLORS.BACKGROUND}
          variant={VARIANTS.FILLED}
          iconName="close"
          onClick={() => setShowNotifications(false)}
        />
        <div className={bem("header-title")}>Notifications</div>
        <IconButton
          color={COLORS.BACKGROUND}
          variant={VARIANTS.FILLED}
          iconName="notifications"
        />
      </div>
      <div className={joinClassNames(baseClassName, "hide-scroll-bar")}>
        {notifications && notifications.length > 0 ? (
          <>
            {notifications.map((notification: NotificationFragment) => (
              <Notification key={notification.id} notification={notification} />
            ))}
          </>
        ) : (
          <div className={bem("empty")}>
            <IconButton
              color={COLORS.TERTIARY_CONTAINER}
              variant={VARIANTS.FILLED}
              iconName={"notifications"}
              className={bem("empty-icon")}
            />
            <div className={bem("empty-text")}>
              <div className={bem("empty-title")}>No notifications yet</div>
              <Link
                to={APP_ROUTES.HOME}
                onClick={() => setShowNotifications(false)}
                className={bem("empty-caption")}
              >
                Book your first Pointment
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;

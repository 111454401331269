/* eslint-disable max-len */
import "./Services.scss";
import "swiper/scss";
import ReactDOM from "react-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { ListItem } from "components/list-item/ListItem";
import { InputChip } from "elements/input-chip/InputChip";
import { COLORS } from "models/colors";
import { useEffect, useState } from "react";
import { Button } from "elements/button/Button";
import { VARIANTS } from "models/variants";
import ServiceDetailModal from "../modals/service-detail-modal/ServiceDetailModal";
import { IconButton } from "elements/icon-button/IconButton";
import { bemElement } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { Controller, Pagination } from "swiper";
import { TService } from "types";
import { useCart } from "providers/cart-provider";
import { Toast } from "elements/toast/Toast";
import { isNumber } from "utils/is-number";
import { IBreadcrumb } from "components/breadcrumbs/Breadcrumbs";
import { LocationPageServiceFragment } from "../../routes/location/Location.generated";
import {
  IBookAppointmentServiceFull,
  isSelectedService
} from "providers/cart-provider.utils";
import { MenuSection, MenuSectionService } from "types.generated";
import { clearCache } from "utils/cacheHelper";

export interface IServicesProps {
  items: MenuSection[];
  className?: string;
  onCurrentRoute?: (route?: IBreadcrumb) => void;
  onSubmit?: () => void;
}

const swiperBreakpoints = {
  0: {
    slidesPerView: 1.07
  },
  768: {
    slidesPerView: 1.99
  },
  992: {
    slidesPerView: 1.07
  }
};
const baseClassName = "services-component";
const bem = bemElement(baseClassName);

export const Services = ({
  items,
  className = "",
  onCurrentRoute,
  onSubmit
}: IServicesProps): JSX.Element => {
  const {
    cart,
    multiPerson,
    activePersonsNumber,
    addActivePerson,
    removeActivePerson,
    selectService,
    copyFromYou,
    resetFromServicesScreen
  } = useCart();
  const [currentService, setCurrentService] =
    useState<LocationPageServiceFragment | null>(null);
  const [currentPersonIndex, setCurrentPersonIndex] = useState<number | null>(
    null
  );
  const [paginationBullet, setPaginationBullet] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState("");

  const navigateToBooking = (): void => {
    for (let i = 0; i < activePersonsNumber; i++) {
      if (!cart.services.some((service) => service.personIndex === i)) {
        setWarningMsg("Please add at least one service to your cart");
        break;
      } else if (i === activePersonsNumber - 1) {
        onSubmit && onSubmit();
      }
    }
  };

  const onCloseModalHandler = (): void => {
    setShowModal(false);
    setCurrentService(null);
    setCurrentPersonIndex(null);
    onCurrentRoute && onCurrentRoute();
  };

  const onMenuItemClick = (
    option: LocationPageServiceFragment | undefined | null,
    personIndex: number
  ): void => {
    if (option) {
      setShowModal(true);
      setCurrentService(option);
      setCurrentPersonIndex(personIndex);
      onCurrentRoute && onCurrentRoute({ title: option.name || "", url: "" });
    }
  };

  const closeToastAndReset = () => {
    setWarningMsg("");
  };

  useEffect(() => {
    resetFromServicesScreen();
    clearCache();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getServicesBlock = (_personNumber: number): JSX.Element => {
    return (
      <div className={joinClassNames(bem("services-block"), "hide-scroll-bar")}>
        <div className={bem("services-block-header")}>
          {activePersonsNumber > 1 && (
            <span className={bem("services-block-person-title")}>
              Person {_personNumber + 1} {_personNumber === 0 ? "(You)" : ""}
            </span>
          )}
          {activePersonsNumber > 1 && _personNumber === 0 && (
            <div
              className={bem("services-block-copy-btn invisible")} // it is invisible fake button (not clickable) that is only needed for correct spacing.
            >
              <SvgIcon name="plus" />
              Copy from You
            </div>
          )}
          {_personNumber > 0 && (
            <div
              className={bem("services-block-copy-btn")}
              aria-hidden="true"
              onClick={() => copyFromYou(_personNumber)}
            >
              <SvgIcon name="plus" />
              Copy from You
            </div>
          )}
        </div>

        {items.map((service: MenuSection, index: number) => (
          <div key={index}>
            <h4 className={bem("services-block-title")}>{service.name}</h4>
            <div>
              {service.menu_section_services.map(
                (option: MenuSectionService, i: number) => {
                  const hasMandatoryAddOn = option.service?.add_ons.find(
                    (item: any) =>
                      item?.core_service?.choice_is_required === true
                  );
                  const canNotBeSelectedManicureTwice = !!cart.services?.find(
                    (cartService: IBookAppointmentServiceFull) =>
                      cartService.personIndex === _personNumber &&
                      option.service?.category === "MANICURE" &&
                      cartService.service.category === "MANICURE"
                  );
                  const canNotBeSelectedPedicureTwice = !!cart.services?.find(
                    (cartService: IBookAppointmentServiceFull) =>
                      cartService.personIndex === _personNumber &&
                      option.service?.category === "PEDICURE" &&
                      cartService.service.category === "PEDICURE"
                  );
                  const value = isSelectedService(
                    cart,
                    option.service?.id || "",
                    _personNumber
                  );
                  const control = (
                    <InputChip
                      color={COLORS.PRIMARY}
                      value={value}
                      text={value ? "Added" : "Add"}
                      iconLeftName={value ? "minus" : "plus"}
                      cyId="select-service-button"
                      disabled={
                        !value &&
                        (canNotBeSelectedManicureTwice ||
                          canNotBeSelectedPedicureTwice)
                      }
                      category={option.service?.category}
                      onClick={() => {
                        if (warningMsg) {
                          closeToastAndReset();
                        }
                        if (!value && hasMandatoryAddOn) {
                          onMenuItemClick(option.service, _personNumber);
                          return;
                        }
                        selectService(
                          option.service as TService,
                          _personNumber,
                          [] // empty add-ons
                        );
                      }}
                    />
                  );

                  return (
                    <ListItem
                      key={i}
                      control={control}
                      className="items-center"
                      onClick={() => {
                        if (warningMsg) {
                          closeToastAndReset();
                        }
                        onMenuItemClick(option.service, _personNumber);
                      }}
                    >
                      <span className="list-item-component__content-title">
                        {option.service?.name}
                      </span>
                      <span className="list-item-component__content-description">
                        ${option.service?.price} • {option.service?.duration}{" "}
                        mins
                      </span>
                    </ListItem>
                  );
                }
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const div = document.createElement("div");
    ReactDOM.render(
      <>
        <SvgIcon
          className={bem("body-swiper-custom-pagination-icon-active")}
          name="filled_person"
        />
        <SvgIcon
          className={bem("body-swiper-custom-pagination-icon")}
          name="person"
        />
      </>,
      div,
      () => {
        setPaginationBullet(div.innerHTML);
      }
    );
  }, []);

  useEffect(() => {
    if (warningMsg && cart.services.length > 0) {
      setWarningMsg("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  return (
    <div className={joinClassNames(baseClassName, className)}>
      {multiPerson > 1 && (
        <div className={bem("header")}>
          <div className={bem("header-left-side")}>
            {activePersonsNumber < 2 ? (
              <Button
                color={COLORS.TERTIARY_CONTAINER}
                variant={VARIANTS.FILLED}
                text="Group Booking"
                iconLeftName="add_person"
                size="sm"
                onClick={addActivePerson}
              />
            ) : (
              <>
                <div className={bem("body-swiper-custom-pagination")} />
                <span className={bem("pagination-title")}>
                  Up to {activePersonsNumber}
                </span>
              </>
            )}
          </div>
          <div className={bem("header-right-side")}>
            <IconButton
              color={COLORS.TERTIARY_CONTAINER}
              variant={
                activePersonsNumber < 2 ? VARIANTS.DEFAULT : VARIANTS.FILLED
              }
              iconName="minus"
              disabled={activePersonsNumber < 2}
              onClick={removeActivePerson}
            />
            <span className={bem("active-persons-counter")}>
              {activePersonsNumber}
            </span>
            <IconButton
              color={COLORS.TERTIARY_CONTAINER}
              variant={
                activePersonsNumber === multiPerson
                  ? VARIANTS.DEFAULT
                  : VARIANTS.FILLED
              }
              iconName="plus"
              disabled={activePersonsNumber === multiPerson}
              onClick={addActivePerson}
            />
          </div>
        </div>
      )}
      <div
        className={joinClassNames(
          bem("body"),
          "hide-scroll-bar",
          activePersonsNumber > 1 ? "bg-background" : ""
        )}
      >
        {activePersonsNumber > 1 ? (
          <>
            <Swiper
              className={bem("body-swiper")}
              breakpoints={swiperBreakpoints}
              modules={[Pagination, Controller]}
              spaceBetween={8}
              navigation={{
                nextEl: `.${bem("body-swiper-navigation-right-button")}`,
                prevEl: `.${bem("body-swiper-navigation-left-button")}`,
                disabledClass: bem("body-swiper-navigation-disabled-button")
              }}
              pagination={{
                clickable: true,
                type: "bullets",
                bulletClass: bem("body-swiper-custom-pagination-bullet"),
                bulletActiveClass: bem(
                  "body-swiper-custom-pagination-bullet-active"
                ),
                clickableClass: bem("body-swiper-custom-pagination-clickable"),
                el: `.${bem("body-swiper-custom-pagination")}`,
                renderBullet: (i: number, className: string) =>
                  `<span class="${className}">${paginationBullet}</span>`
              }}
            >
              {Array.from(Array(activePersonsNumber).keys()).map(
                (_, index: number) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className={bem("body-swiper-slide")}>
                        <div className={bem("body-swiper-slide-content")}>
                          {getServicesBlock(index)}
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                }
              )}
            </Swiper>
            <IconButton
              className={bem("body-swiper-navigation-left-button")}
              color={COLORS.BACKGROUND}
              variant={VARIANTS.FILLED}
              iconName="arrow_left"
            />
            <IconButton
              className={bem("body-swiper-navigation-right-button")}
              color={COLORS.BACKGROUND}
              variant={VARIANTS.FILLED}
              iconName="arrow_right"
            />
          </>
        ) : (
          <div className="h-100% relative">{getServicesBlock(0)}</div>
        )}
      </div>

      <div className={bem("footer")}>
        <div className="absolute -top-42px left-0 right-0">
          {warningMsg && (
            <Toast
              msg={warningMsg}
              type="warning"
              showIcon
              onClose={closeToastAndReset}
            />
          )}
        </div>
        <div className={bem("footer-info")}>
          <SvgIcon name="filled_cart" /> {cart.services.length}{" "}
          <SvgIcon name="slash" /> <SvgIcon name="filled_person" />{" "}
          {activePersonsNumber}
        </div>
        <Button
          className="hidden lg:block flexible flex-2"
          color={COLORS.SECONDARY}
          variant={VARIANTS.FILLED}
          onClick={navigateToBooking}
          text="Book a Pointment"
          cyId="book-appointment-button"
          disabled={!!warningMsg}
        />
        <Button
          className="lg:hidden"
          color={COLORS.SECONDARY}
          variant={VARIANTS.FILLED}
          onClick={navigateToBooking}
          text="Book a Pointment"
          cyId="book-appointment-button-mobile"
          disabled={!!warningMsg}
        />
      </div>
      {currentService && isNumber(currentPersonIndex) && (
        <ServiceDetailModal
          show={showModal}
          onHide={onCloseModalHandler}
          onAdd={(
            _service: LocationPageServiceFragment,
            addOns?: string[],
            isEdit?: boolean
          ) =>
            currentPersonIndex !== null &&
            selectService(
              _service as TService,
              currentPersonIndex,
              addOns,
              isEdit
            )
          }
          personIndex={currentPersonIndex as number}
          service={currentService}
        />
      )}
    </div>
  );
};

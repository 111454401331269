import { ReactNode } from "react";
import useResponsive from "../../hooks/useResponsive";

interface IResponsiveData {
  children: ReactNode;
}

export const Desktop = ({ children }: IResponsiveData) => {
  const { isDesktop } = useResponsive();

  if (!isDesktop) {
    return null;
  }

  return <>{children}</>;
};

export const Mobile = ({ children }: IResponsiveData) => {
  const { isMobile } = useResponsive();

  if (!isMobile) {
    return null;
  }

  return <>{children}</>;
};

export const XsScreen = ({ children }: IResponsiveData) => {
  const { isXS } = useResponsive();

  if (!isXS) {
    return null;
  }

  return <>{children}</>;
};

export const SmScreen = ({ children }: IResponsiveData) => {
  const { isSM } = useResponsive();

  if (!isSM) {
    return null;
  }

  return <>{children}</>;
};

export const MdScreen = ({ children }: IResponsiveData) => {
  const { isMD } = useResponsive();

  if (!isMD) {
    return null;
  }

  return <>{children}</>;
};

export const LgScreen = ({ children }: IResponsiveData) => {
  const { isLG } = useResponsive();

  if (!isLG) {
    return null;
  }

  return <>{children}</>;
};

export const XlScreen = ({ children }: IResponsiveData) => {
  const { isXL } = useResponsive();

  if (!isXL) {
    return null;
  }

  return <>{children}</>;
};

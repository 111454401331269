import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";

export interface IGeolocationContextInterface {
  calculateDistance: (lat: number, lng: number) => Promise<number | null>;
}

interface IGeolocationPosition {
  lat: number;
  lng: number;
}

interface IGeolocationProviderProps {
  children: ReactNode;
}

const getGeolocation = async (): Promise<IGeolocationPosition> => {
  const response = await fetch(
    `https://www.googleapis.com/geolocation/v1/geolocate?key=${
      process.env.REACT_APP_GOOGLE_API_KEY || ""
    }`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: ""
    }
  );

  if (!response.ok) {
    throw new Error(response.status.toString());
  }

  const position = await response.json();
  return {
    lat: position.location.lat,
    lng: position.location.lng
  };
};

const GeolocationStateContext = createContext<
  IGeolocationContextInterface | undefined
>(undefined);

export function GeolocationProvider({
  children
}: IGeolocationProviderProps): JSX.Element {
  const [position, setPosition] =
    useState<Promise<IGeolocationPosition | null> | null>(null);

  useEffect(() => {
    setPosition(
      new Promise<IGeolocationPosition | null>((resolve) => {
        getGeolocation()
          .then(resolve)
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error("Getting geolocation: " + error);
            resolve(null);
          });
      })
    );
  }, []);

  const calculateDistance = async (
    lat: number,
    lng: number
  ): Promise<number | null> => {
    if (!position) {
      return null;
    }

    const userPosition = await position;
    if (!userPosition) {
      return null;
    }

    const point1 = new google.maps.LatLng(userPosition.lat, userPosition.lng);
    const point2 = new google.maps.LatLng(lat, lng);

    const distanceInMeters =
      google.maps.geometry.spherical.computeDistanceBetween(point1, point2);

    const distanceInMiles = distanceInMeters * 0.000621371192;

    return +distanceInMiles.toFixed(2);
  };

  return (
    <GeolocationStateContext.Provider
      value={{
        calculateDistance
      }}
    >
      {children}
    </GeolocationStateContext.Provider>
  );
}

export function useGeolocation(): IGeolocationContextInterface {
  const context = useContext(GeolocationStateContext);
  if (context === undefined) {
    throw new Error("useGeolocation must be used within a GeolocationProvider");
  }
  return context;
}

import "./Navigation.scss";
import { IconButton, TIconButtonSize } from "elements/icon-button/IconButton";
import { COLORS } from "models/colors";
import { VARIANTS } from "models/variants";
import { joinClassNames } from "utils/join-class-names";

interface INavigationButtonConfig {
  color?: COLORS;
  className?: string;
  disabled?: boolean;
  size?: TIconButtonSize;
  variant?: VARIANTS;
  onClick?: () => void;
}

export interface INavigation {
  text?: string;
  leftButtonConfig?: INavigationButtonConfig;
  rightButtonConfig?: INavigationButtonConfig;
  className?: string;
}

const defaultButtonConfig = {
  color: COLORS.PRIMARY
};

const Navigation = ({
  text,
  leftButtonConfig,
  rightButtonConfig,
  className = ""
}: INavigation): JSX.Element => {
  return (
    <div className={joinClassNames("navigation", className)}>
      <IconButton
        iconName="arrow_left"
        color={
          leftButtonConfig?.color
            ? leftButtonConfig?.color
            : defaultButtonConfig.color
        }
        variant={leftButtonConfig?.variant}
        size={leftButtonConfig?.size}
        disabled={leftButtonConfig?.disabled}
        className={leftButtonConfig?.className}
        onClick={leftButtonConfig?.onClick}
      />
      {text}
      <IconButton
        iconName="arrow_right"
        color={
          rightButtonConfig?.color
            ? rightButtonConfig?.color
            : defaultButtonConfig.color
        }
        variant={rightButtonConfig?.variant}
        size={rightButtonConfig?.size}
        disabled={rightButtonConfig?.disabled}
        className={rightButtonConfig?.className}
        onClick={rightButtonConfig?.onClick}
      />
    </div>
  );
};

export default Navigation;

import "./ReviewModal.scss";
import React from "react";
import { bemElement } from "../../../utils/bem-class-names";
import SlidingModal from "../sliding-modal/SlidingModal";
import { joinClassNames } from "../../../utils/join-class-names";
import useResponsive from "../../../hooks/useResponsive";
import DesktopModal from "../desktop-modal/DesktopModal";
import { IReview } from "../../review/Review";
import { Avatar } from "../../../elements/avatar/Avatar";
import { SvgIcon } from "../../../elements/svg-icon/svg-icon";

interface IReviewModalData {
  value: IReview | null;
  show: boolean;
  onHide: () => void;
  className?: string;
}

interface ContentData {
  value: IReview | null;
}

const baseClassName = "review-modal";
const bem = bemElement(baseClassName);

const Content = ({ value }: ContentData) => {
  if (value === null) return null;

  const { userName, userPhotoUrl, date, rating, text } = value;

  return (
    <div className={bem("content")}>
      <Avatar src={userPhotoUrl} monogram={userName} size="xl" />
      <div className={bem("name")}>{userName}</div>
      <div className={bem("info")}>
        <SvgIcon name="filled_star" />
        <div>
          {rating} •{" "}
          {new Date(date).toLocaleDateString("en-US", {
            month: "short",
            year: "numeric"
          })}
        </div>
      </div>
      <div className={bem("text")}>{text}</div>
    </div>
  );
};

const ReviewModal = ({
  value,
  show,
  onHide,
  className = ""
}: IReviewModalData) => {
  const { isDesktop } = useResponsive();

  return (
    <>
      {isDesktop ? (
        <DesktopModal
          show={show}
          onHide={onHide}
          title="Review"
          className={joinClassNames(baseClassName, className)}
        >
          <Content value={value} />
        </DesktopModal>
      ) : (
        <SlidingModal
          show={show}
          onHide={onHide}
          title="Review"
          className={joinClassNames(baseClassName, className)}
        >
          <Content value={value} />
        </SlidingModal>
      )}
    </>
  );
};

export default ReviewModal;

import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
export const getPhone = (value: string): string => {
  if (!value) return "";

  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    const numberProto = phoneUtil.parseAndKeepRawInput(value);
    return phoneUtil.format(numberProto, PhoneNumberFormat.INTERNATIONAL);
  } catch (error) {
    return value;
  }
};

import "./header-menu.scss";
import { Auth } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { joinClassNames } from "utils/join-class-names";
import { bemElement, bemModifier } from "utils/bem-class-names";
import { IconButton } from "elements/icon-button/IconButton";
import { Avatar } from "elements/avatar/Avatar";
import { COLORS } from "models/colors";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { Button } from "elements/button/Button";
import { Desktop, Mobile } from "components/responsive/Responsive";
import { VARIANTS } from "models/variants";
import { useUser } from "providers/user";
import * as Cognito from "providers/cognito";
import { APP_ROUTES } from "routes";
import { useHeader } from "providers/header-provider";

const baseClassName = "header-menu";
const bem = bemElement(baseClassName);
const loggedInMenu = [
  {
    title: "Account details",
    cyId: "account-details-button",
    link: APP_ROUTES.ACCOUNT_DETAILS
  },
  {
    title: "Upcoming Pointments",
    link: APP_ROUTES.ME
  },
  {
    title: "Favorite artists",
    link: APP_ROUTES.ME
  },
  {
    title: "Favorite locations",
    link: APP_ROUTES.ME
  },
  {
    title: "Recent Pointments",
    link: APP_ROUTES.ME
  }
];

interface IHeaderMenuProps {
  className?: string;
}

export const HeaderMenu = ({
  className = ""
}: IHeaderMenuProps): JSX.Element => {
  const navigate = useNavigate();
  const { setShowMenu } = useHeader();
  const { signOut } = Cognito.useCognito();
  const { updateUser, user } = useUser();

  function handleIsWebview() {
    if (globalThis.isReactNativeWebview === "true") {
      return true;
    }
    if (globalThis.isReactNativeWebview2 === "yes") {
      return true;
    }
    return false;
  }

  const isWebview = handleIsWebview();

  const logOutButtonHandler = async () => {
    try {
      await Auth.signOut();
      await signOut();
      updateUser(null);
      setShowMenu(false);
      navigate(APP_ROUTES.HOME);
    } catch (error) {
      //console.log('error signing out: ', error);
    }
  };

  return (
    <div
      className={joinClassNames(
        baseClassName,
        className,
        bemModifier(baseClassName, { "not-logged-in": !user })
      )}
    >
      <div
        className={bem("wrapper")}
        onClick={() => setShowMenu(false)}
        aria-hidden="true"
      >
        {user ? (
          <div className={bem("menu")}>
            <div className={bem("menu-header")}>
              <IconButton
                color={COLORS.PRIMARY}
                iconName="close"
                onClick={() => setShowMenu(false)}
              />
              <div className={bem("menu-header-info")}>
                <Avatar
                  src={user?.photo_url}
                  monogram={user?.first_name}
                  alt={user?.title}
                  size="xs"
                />
                <div>{user?.first_name}</div>
              </div>
            </div>
            {loggedInMenu.map((menuItem) => (
              <div
                key={menuItem.title}
                className={bem("menu-item")}
                aria-hidden
                data-cy={menuItem.cyId}
                onClick={() => {
                  setShowMenu(false);
                  navigate(menuItem.link);
                }}
              >
                <SvgIcon name="person" />
                <div>{menuItem.title}</div>
              </div>
            ))}
            <hr />
            <button className={bem("menu-item")} onClick={logOutButtonHandler}>
              <SvgIcon name="person" />
              <span>Log out</span>
            </button>
            {!isWebview && (
              <div className={bem("menu-bottom")}>
                <Button
                  className={"flexible"}
                  color={COLORS.SECONDARY}
                  variant={VARIANTS.OUTLINED}
                  text="For business"
                  onClick={() =>
                    window.open("https://business.pointmentapp.com")
                  }
                />
                <div className={bem("menu-bottom-app-info")}>
                  <div className={bem("menu-bottom-left-side")}>
                    <SvgIcon
                      name="ico_pointment"
                      className={bem("menu-bottom-icon")}
                    />

                    <div className={bem("menu-bottom-caption")}>
                      <Mobile>
                        <div>There's more to love in the app</div>
                        <div className={bem("menu-bottom-caption-download")}>
                          Download
                        </div>
                      </Mobile>
                      <Desktop>
                        <div>
                          Scan QR to
                          <span className={bem("menu-bottom-caption-download")}>
                            download{" "}
                          </span>
                          the Pointment App
                        </div>
                      </Desktop>
                    </div>
                  </div>
                  <Desktop>
                    <img
                      src={require("assets/Qr.png")}
                      alt=""
                      width={170}
                      height={170}
                    />
                  </Desktop>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={joinClassNames(bem("menu"), bem("menu-default"))}>
            <Button
              className={joinClassNames(bem("menu-button"), "flexible")}
              color={COLORS.SECONDARY}
              text="Get started"
              variant={VARIANTS.FILLED}
              onClick={() => navigate(APP_ROUTES.SIGN_UP)}
            />
            <Button
              className={joinClassNames(bem("menu-button"), "flexible")}
              color={COLORS.SECONDARY}
              text="Log in"
              variant={VARIANTS.OUTLINED}
              onClick={() => navigate(APP_ROUTES.LOGIN)}
            />
            {!isWebview && (
              <Button
                className={joinClassNames(bem("menu-button"), "flexible")}
                color={COLORS.PRIMARY}
                text="For business"
                iconLeftName="nail"
                onClick={() => window.open("https://business.pointmentapp.com")}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

import "./ExpandableText.scss";
import { useEffect, useRef, useState } from "react";
import { bemElement, bemModifier } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";

export type TTextLimitMethod = "lines" | "chars";

export interface IExpandableTextProps {
  text: string;
  limit?: number;
  method?: TTextLimitMethod;
  onExpand?: () => void;
  className?: string;
}

const baseClassName = "expanded-text";
const bem = bemElement(baseClassName);

export const ExpandableText = ({
  text,
  limit,
  method = "lines",
  onExpand,
  className = ""
}: IExpandableTextProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [truncatedText, setTruncatedText] = useState<string>(text);

  useEffect(() => {
    if (method === "lines") {
      if (ref.current !== null) {
        if (limit && limit > 0) {
          ref.current.style.webkitLineClamp = limit.toString();
        }
        setIsOverflowed(
          ref.current.scrollHeight > ref.current.clientHeight ||
            ref.current.scrollWidth > ref.current.clientWidth
        );
      }
    } else {
      if (limit && text.length > limit) {
        setTruncatedText(text.substring(0, limit) + "...");
        setIsOverflowed(true);
      }
    }
  }, [text, limit, method]);

  const onExpandClickHandler = (): void => {
    if (ref.current !== null) {
      setIsExpanded(true);
      setIsOverflowed(false);
      if (method === "lines") {
        ref.current.style.webkitLineClamp = "10000";
      } else {
        setTruncatedText(text);
      }
      if (onExpand) {
        onExpand();
      }
    }
  };

  return (
    <div className={joinClassNames(baseClassName, className)}>
      <div
        className={joinClassNames(
          bemModifier(bem("content"), { "truncate-lines": method === "lines" })
        )}
        ref={ref}
      >
        {method === "lines" ? text : truncatedText}
      </div>
      {isOverflowed && !isExpanded && (
        <div
          className={bem("read-more")}
          onClick={onExpandClickHandler}
          aria-hidden="true"
        >
          ...read more
        </div>
      )}
    </div>
  );
};

export default ExpandableText;

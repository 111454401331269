import { useRef } from "react";
import { Avatar } from "elements/avatar/Avatar";
import { TArtist } from "types";

interface ArtistItemProps {
  artist: TArtist;
  selectMainArtist: (artistId: string) => void;
  mainArtistId: string | undefined;
  artistColors: string[];
  artists: TArtist[];
  artistsRefForScroll: React.RefObject<HTMLDivElement>;
}

const ArtistItem = ({
  artist,
  selectMainArtist,
  mainArtistId,
  artistColors,
  artists,
  artistsRefForScroll
}: ArtistItemProps) => {
  const artistRef = useRef<HTMLDivElement>(null);

  return (
    <div
      aria-hidden
      key={artist.id}
      className="artist"
      ref={artistRef}
      onClick={() => {
        if (mainArtistId === artist.id) {
          selectMainArtist("");
        } else {
          selectMainArtist(artist.id);
          if (artistsRefForScroll?.current && artistRef?.current) {
            const container = artistsRefForScroll.current;
            const artistElement = artistRef.current;
            const artistLeft = artistElement.offsetLeft;
            const artistWidth = artistElement.offsetWidth;
            const containerWidth = container.offsetWidth;
            const scrollPosition =
              artistLeft - (containerWidth / 2 - artistWidth / 2);
            artistsRefForScroll.current.scrollTo({
              left: scrollPosition,
              behavior: "smooth"
            });
          }
        }
      }}
    >
      <div className="avatars">
        <div
          style={{
            border: `solid 4px ${
              mainArtistId === artist.id
                ? artistColors?.[
                    artists?.findIndex((a: any) => a?.id === artist.id)
                  ]
                : "transparent"
            }`,
            borderRadius: "28px"
          }}
        >
          <Avatar
            src={artist?.cover_photo_url || artist?.user?.photo_url || ""}
            monogram={artist?.user?.first_name || ""}
            size="md"
          />
        </div>
      </div>
      <div
        className="artist-name"
        style={{
          marginTop: mainArtistId === artist.id ? "4px" : "0px",
          marginBottom: mainArtistId === artist.id ? "0px" : "4px"
        }}
      >
        {artist?.user?.first_name}
      </div>
    </div>
  );
};

export default ArtistItem;

import "./Suggestion-chip.scss";
import { bemModifier } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";

export type TSuggestionChipType =
  | "low"
  | "low-alternative"
  | "high"
  | "high-alternative"
  | "primary";

interface ISuggestionChipProps {
  type: TSuggestionChipType;
  className?: string;
  value?: string;
}

const baseClassName = "suggestion-chip";

export const SuggestionChip = ({
  type,
  className = "",
  value
}: ISuggestionChipProps): JSX.Element | null => {
  if (!value) return null;

  return (
    <span
      className={joinClassNames(bemModifier(baseClassName, type), className)}
    >
      {value}
    </span>
  );
};

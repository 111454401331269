/* eslint-disable max-len */
import "./PaymentMethodsSection.scss";
import { useState } from "react";
import { COLORS } from "../../../models/colors";
import { Button } from "../../../elements/button/Button";
import { VARIANTS } from "../../../models/variants";
import { bemElement, bemModifier } from "../../../utils/bem-class-names";
import { joinClassNames } from "../../../utils/join-class-names";
import AddCreditCardModal from "../../modals/add-credit-card-modal/AddCreditCardModal";
import { Desktop, Mobile } from "../../responsive/Responsive";
import useResponsive from "../../../hooks/useResponsive";

// import { ICreditCard } from "types";
import { CreditCard } from "elements/credit-card/CreditCard";
import { StripePaymentMethodFragment } from "hooks/use-payment/PaymentMethodsSection.generated";
import { usePayment } from "hooks/use-payment";
import { useUser } from "providers/user";
import {
  useElements,
  CardElement,
  useStripe,
  Elements
} from "@stripe/react-stripe-js";
import { stripePromise, CARD_ELEMENT_OPTIONS } from "stripeConfig";
import { PaymentMethod } from "@stripe/stripe-js";

const baseClassName = "payment-methods-section";
const bem = bemElement(baseClassName);

type TDefaultPaymentMethod = "card" | "apple-pay";

interface IPaymentMethodsData {
  defaultMethod: TDefaultPaymentMethod;
}

const PaymentMethodsSection = ({ defaultMethod }: IPaymentMethodsData) => {
  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    useState<TDefaultPaymentMethod>(defaultMethod);
  const [showAddCreditCard, setShowAddCreditCard] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [deletingCard, setDeletingCard] = useState<
    StripePaymentMethodFragment | undefined
  >(undefined);
  const { isDesktop } = useResponsive();
  const { cards, addCard, deleteCard, changeDefaultPaymentMethod } =
    usePayment();

  if (!cards) {
    return null;
  }

  const handleAddCard = async (cardDetails: PaymentMethod) => {
    if (cardDetails) {
      setErrorMsg(undefined);
      setLoading(true);

      try {
        const isDefault = cards.find(
          (card: StripePaymentMethodFragment) => card.is_default
        );
        await addCard({
          paymentMethod: {
            id: cardDetails?.id,
            brand: cardDetails?.card?.brand,
            exp_month: cardDetails?.card?.exp_month,
            exp_year: cardDetails?.card?.exp_year,
            last4: cardDetails?.card?.last4
          },
          is_default: !isDefault
        });
        setShowAddCreditCard(false);
      } catch (e) {
        setErrorMsg(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteCardClick = async (card: StripePaymentMethodFragment) => {
    setDeletingCard(card);
    await deleteCard(card.id);
    setDeletingCard(undefined);
  };

  const onChangeDefaultPaymentMethod = (card: StripePaymentMethodFragment) => {
    const defaultCard = cards.find(
      (card: StripePaymentMethodFragment) => card.is_default
    );
    if (defaultCard && defaultCard.id !== card.id) {
      changeDefaultPaymentMethod(card);
      setDefaultPaymentMethod("card");
    }
  };

  return (
    <div className={baseClassName}>
      <div className={bem("cards-wrapper")}>
        <div className={bem("title")}>Your credit cards:</div>
        <div className={bem("payment-methods-list")}>
          {cards.map((card: StripePaymentMethodFragment, index: number) => (
            <CreditCard
              key={card.id}
              card={card}
              cyId={`credit-card-${index}`}
              idDefault={defaultPaymentMethod === "card" && card.is_default}
              checked={defaultPaymentMethod === "card" && card.is_default}
              deleting={deletingCard?.id === card.id}
              onClick={() => onChangeDefaultPaymentMethod(card)}
              onDelete={() => deleteCardClick(card)}
            />
          ))}
        </div>
      </div>
      <div
        className={bemModifier(bem("add-button"), {
          hidden: isDesktop && showAddCreditCard
        })}
      >
        <Button
          className={joinClassNames("w-100%", "flexible")}
          color={COLORS.SECONDARY}
          variant={VARIANTS.OUTLINED}
          cyId="add-credit-card-button"
          iconLeftName="plus"
          text="Add credit card"
          onClick={() => setShowAddCreditCard(true)}
        />
      </div>
      <Mobile>
        <AddCreditCardModal
          show={showAddCreditCard}
          onHide={() => {
            setErrorMsg(undefined);
            setShowAddCreditCard(false);
          }}
        >
          <div>
            {showAddCreditCard && (
              <Elements stripe={stripePromise}>
                <CardForm
                  handleAddCard={handleAddCard}
                  errorMsg={errorMsg}
                  setErrorMsg={setErrorMsg}
                >
                  <div className={bem("add-card-controls")}>
                    <Button
                      type="submit"
                      className="flexible w-100%"
                      color={COLORS.PRIMARY}
                      variant={VARIANTS.FILLED}
                      text="Confirm"
                      cyId="confirm-credit-card-button"
                      submitting={loading}
                    />
                  </div>
                </CardForm>
              </Elements>
            )}
          </div>
        </AddCreditCardModal>
      </Mobile>
      <Desktop>
        {showAddCreditCard && (
          <div>
            <Elements stripe={stripePromise}>
              <CardForm
                handleAddCard={handleAddCard}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
              >
                <div className={bem("add-card-controls")}>
                  <Button
                    type="button"
                    className={bem("add-card-controls-left")}
                    color={COLORS.SECONDARY}
                    variant={VARIANTS.OUTLINED}
                    text="Cancel"
                    onClick={() => {
                      setShowAddCreditCard(false);
                      setErrorMsg(undefined);
                    }}
                    disabled={loading || deletingCard !== undefined}
                  />
                  <Button
                    type="submit"
                    className={joinClassNames("w-100%", "flexible")}
                    color={COLORS.PRIMARY}
                    variant={VARIANTS.FILLED}
                    text="Confirm"
                    submitting={loading || deletingCard !== undefined}
                  />
                </div>
              </CardForm>
            </Elements>
          </div>
        )}
      </Desktop>
    </div>
  );
};

export default PaymentMethodsSection;

const CardForm = ({ handleAddCard, children, errorMsg, setErrorMsg }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useUser();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: `${user?.first_name} ${user?.last_name}`,
          email: user?.email,
          phone: user?.phone_number || ""
        }
      });

      if (error) {
        setErrorMsg(error.message);
      } else {
        handleAddCard(paymentMethod);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement
        onChange={(e) => {
          if (e.complete) {
            setErrorMsg(undefined);
          }
        }}
        options={CARD_ELEMENT_OPTIONS}
      />
      <div className={bem("error-msg-container")}>
        {errorMsg && <span className={bem("error-msg")}>{errorMsg}</span>}
      </div>
      {children}
    </form>
  );
};

import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type PaymentMethodsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.StripePaymentMethodWhereInput>;
  orderBy?: Types.InputMaybe<
    | Array<Types.StripePaymentMethodOrderByWithRelationInput>
    | Types.StripePaymentMethodOrderByWithRelationInput
  >;
}>;

export type PaymentMethodsQuery = {
  __typename?: "Query";
  stripePaymentMethods: Array<{
    __typename?: "StripePaymentMethod";
    id: string;
    brand: string;
    last4: string;
    exp_month: number;
    exp_year: number;
    is_default: boolean;
    object_json: any;
    user: { __typename?: "User"; id: string };
  }>;
};

export type StripePaymentMethodFragment = {
  __typename?: "StripePaymentMethod";
  id: string;
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
  is_default: boolean;
  object_json: any;
  user: { __typename?: "User"; id: string };
};

export type DeletePaymentMethodMutationVariables = Types.Exact<{
  where: Types.StripePaymentMethodWhereUniqueInput;
}>;

export type DeletePaymentMethodMutation = {
  __typename?: "Mutation";
  deleteOneStripePaymentMethod?: {
    __typename?: "StripePaymentMethod";
    id: string;
  } | null;
};

export type UpdatePaymentMethodMutationVariables = Types.Exact<{
  where: Types.StripePaymentMethodWhereUniqueInput;
  data: Types.StripePaymentMethodUpdateInput;
}>;

export type UpdatePaymentMethodMutation = {
  __typename?: "Mutation";
  updateOneStripePaymentMethod?: {
    __typename?: "StripePaymentMethod";
    id: string;
  } | null;
};

export const StripePaymentMethodFragmentDoc = gql`
  fragment StripePaymentMethod on StripePaymentMethod {
    id
    brand
    last4
    exp_month
    exp_year
    is_default
    object_json
    user {
      id
    }
  }
`;
export const PaymentMethodsDocument = gql`
  query PaymentMethods(
    $where: StripePaymentMethodWhereInput
    $orderBy: [StripePaymentMethodOrderByWithRelationInput!]
  ) {
    stripePaymentMethods(where: $where, orderBy: $orderBy) {
      ...StripePaymentMethod
    }
  }
  ${StripePaymentMethodFragmentDoc}
`;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePaymentMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PaymentMethodsQuery,
    PaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(
    PaymentMethodsDocument,
    options
  );
}
export function usePaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentMethodsQuery,
    PaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(
    PaymentMethodsDocument,
    options
  );
}
export type PaymentMethodsQueryHookResult = ReturnType<
  typeof usePaymentMethodsQuery
>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<
  typeof usePaymentMethodsLazyQuery
>;
export type PaymentMethodsQueryResult = Apollo.QueryResult<
  PaymentMethodsQuery,
  PaymentMethodsQueryVariables
>;
export const DeletePaymentMethodDocument = gql`
  mutation DeletePaymentMethod($where: StripePaymentMethodWhereUniqueInput!) {
    deleteOneStripePaymentMethod(where: $where) {
      id
    }
  }
`;
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<
  DeletePaymentMethodMutation,
  DeletePaymentMethodMutationVariables
>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
  >(DeletePaymentMethodDocument, options);
}
export type DeletePaymentMethodMutationHookResult = ReturnType<
  typeof useDeletePaymentMethodMutation
>;
export type DeletePaymentMethodMutationResult =
  Apollo.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentMethodMutation,
  DeletePaymentMethodMutationVariables
>;
export const UpdatePaymentMethodDocument = gql`
  mutation UpdatePaymentMethod(
    $where: StripePaymentMethodWhereUniqueInput!
    $data: StripePaymentMethodUpdateInput!
  ) {
    updateOneStripePaymentMethod(where: $where, data: $data) {
      id
    }
  }
`;
export type UpdatePaymentMethodMutationFn = Apollo.MutationFunction<
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables
>;

/**
 * __useUpdatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodMutation, { data, loading, error }] = useUpdatePaymentMethodMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePaymentMethodMutation,
    UpdatePaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePaymentMethodMutation,
    UpdatePaymentMethodMutationVariables
  >(UpdatePaymentMethodDocument, options);
}
export type UpdatePaymentMethodMutationHookResult = ReturnType<
  typeof useUpdatePaymentMethodMutation
>;
export type UpdatePaymentMethodMutationResult =
  Apollo.MutationResult<UpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables
>;

import "./Switch.scss";
import React from "react";
import { bemElement, bemModifier } from "../../utils/bem-class-names";
import { COLORS } from "../../models/colors";
import { joinClassNames } from "../../utils/join-class-names";

const baseClassName = "switch";
const bem = bemElement(baseClassName);

interface ISwitchData {
  color: COLORS;
  value?: string;
  checked: boolean;
  className?: string;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

const Switch = ({
  color,
  value,
  checked,
  className = "",
  disabled,
  onChange
}: ISwitchData) => {
  return (
    <label
      className={joinClassNames(
        bemModifier(baseClassName, { disabled: disabled }),
        `${baseClassName}--${color}`,
        className
      )}
    >
      <input
        type="checkbox"
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={() => onChange(!checked)}
      />
      <span className={bem("slider")} />
    </label>
  );
};

export default Switch;

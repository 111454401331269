export const schedulingBufferMinutes = (impersonate = false) =>
  impersonate ? -10 : 0;

export const amenitiesNameHelper = {
  Non_Toxic: "Non-toxic",
  Waterless: "Waterless",
  Natural_Nails_Only: "Natural Nails Only",
  Handicap_Accessible: "Handicap-accessible",
  Dog_Friendly: "Dog Friendly",
  Kid_Friendly: "Kid Friendly",
  Good_For_Groups: "Good for Groups",
  Private_Parties: "Private Parties",
  TV: "TV",
  Cash_Tips_Only: "Cash Tips Only",
  Free_Wifi: "Free Wifi",
  Masked_Workforce: "Masked Workforce",
  Woman_Owned: "Woman Owned",
  Black_Owned: "Black Owned",
  LGBTQ_Owned: "LGBTQ Owned",
  Asian_Owned: "Asian Owned",
  Veteran_Owned: "Veteran Owned"
};

export const amenitiesWithAlternativeIcons = [
  "Black_Owned",
  "LGBTQ_Owned",
  "Asian_Owned"
];

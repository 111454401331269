import React from "react";
import { Auth } from "@aws-amplify/auth";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { CognitoUser } from "amazon-cognito-identity-js";

interface CognitoContextInterface {
  loading: boolean;
  fetchAuthenticatedUser: () => void;
  signedIn: boolean;
  setCognitoState: () => CognitoState;
  signInApple: () => Promise<CognitoUser>;
  signInGoogle: () => Promise<CognitoUser>;
  signOut: () => Promise<any>;
  user: User | null;
}

const defaultContext: CognitoContextInterface = {
  loading: true,
  fetchAuthenticatedUser: () => new Promise(() => {}),
  signedIn: false,
  user: null,
  setCognitoState: () => defaultState,
  signInApple: () => new Promise(() => {}),
  signInGoogle: () => new Promise(() => {}),
  signOut: () => new Promise(() => {})
};

const CognitoContext = React.createContext<CognitoContextInterface | undefined>(
  undefined
);

interface CognitoState {
  loading: boolean;
  signedIn: boolean;
}

export interface User {
  id: string;
  access_token: string;
  email: string;
  isVerified: boolean;
}

const defaultState = {
  signedIn: false,
  loading: true
};

export function CognitoProvider(props: any) {
  const [state, setState] = React.useState<CognitoState>(defaultState);
  const [user, setUser] = React.useState<User | null>(null);

  const hostname = process.env.REACT_APP_API_CORE_URL?.replace(
    /https?:\/\//,
    ""
  ).replace(/:\d+/, "");

  const cognito = React.useMemo(() => {
    Auth.configure({
      Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        oauth: {
          domain: process.env.REACT_APP_COGNITO_DOMAIN,
          redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
          redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT,
          scope: ["email", "openid"],
          clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
          responseType: "code"
        }
      },
      Analytics: {
        disabled: true
      },
      cookieStorage: {
        domain:
          document.location.hostname === hostname
            ? hostname
            : ".pointmentapp.com",
        secure: document.location.hostname !== hostname,
        path: "/",
        expires: 365
      }
    });

    return Auth;
  }, [hostname]);

  const fetchAuthenticatedUser = React.useCallback(() => {
    cognito
      .currentAuthenticatedUser()
      .then((user: any) => {
        const idTokenPayload = user.signInUserSession.idToken.payload;

        setUser({
          id: idTokenPayload.sub,
          access_token: user.signInUserSession.accessToken.jwtToken,
          email: idTokenPayload.email,
          isVerified: idTokenPayload.email_verified
        });

        setState({
          loading: false,
          signedIn: true
        });
      })
      .catch(() => setState({ loading: false, signedIn: false }));
  }, [cognito]);

  React.useEffect(() => {
    fetchAuthenticatedUser();
  }, [fetchAuthenticatedUser]);

  const signInApple = () =>
    cognito.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Apple
    });

  const signInGoogle = () =>
    cognito.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google
    });

  const signOut = async (): Promise<any> => {
    await cognito.signOut();
    setUser(null);
    setState({ loading: false, signedIn: false });
  };

  return (
    <CognitoContext.Provider
      value={{
        fetchAuthenticatedUser,
        setCognitoState: setState,
        signInApple,
        signInGoogle,
        signOut,
        user,
        ...state
      }}
      {...props}
    />
  );
}

export function useCognito() {
  const context = React.useContext(CognitoContext);

  if (context === undefined) {
    throw new Error("useCognito must be used within a CognitoProvider.");
  }

  return context;
}

export async function getToken() {
  try {
    if (Auth) {
      const session = await Auth.currentSession();

      return session?.getAccessToken()?.getJwtToken();
    }

    return false;
  } catch (error: any) {
    return error.message;
  }
}

export function TestProvider({
  value,
  children
}: {
  value?: Partial<CognitoContextInterface>;
  children: React.ReactNode;
}) {
  return (
    <CognitoContext.Provider value={{ ...defaultContext, ...value }}>
      {children}
    </CognitoContext.Provider>
  );
}

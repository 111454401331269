import { useLoadScript } from "@react-google-maps/api";
import Providers from "./providers";
import Router from "./router";

const libraries = ["geometry"] as "geometry"[];

function App() {
  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
    libraries
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div className="opacity-0">Loading Maps</div>;
  }

  return (
    <Providers>
      <>
        <Router />
      </>
    </Providers>
  );
}

export default App;

import "./TwoColsLayout.scss";
import { bemElement, bemModifier } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { useUser } from "../../../providers/user";

interface ITwoColsLayoutProps {
  children: JSX.Element[];
  className?: string;
  equalCols?: boolean;
  separator?: boolean;
}

const baseClassName = "two-cols-layout";
const bem = bemElement(baseClassName);

export const TwoColsLayout = ({
  children,
  className = "",
  equalCols,
  separator
}: ITwoColsLayoutProps): JSX.Element => {
  const { user } = useUser();

  return (
    <div
      className={joinClassNames(
        bemModifier(baseClassName, { gap: !separator }),
        className
      )}
      style={{ paddingTop: user?.impersonate_name ? "46px" : "" }}
    >
      {children[0] && (
        <div className={bemModifier(bem("left-col"), { default: !equalCols })}>
          {children[0]}
        </div>
      )}
      {separator && (
        <div className={bem("separator")}>
          <SvgIcon name="circle" />
          <div className={bem("separator-line")}></div>
          <SvgIcon name="circle" />
        </div>
      )}
      {children[1] && (
        <div className={bemModifier(bem("right-col"), { default: !equalCols })}>
          {children[1]}
        </div>
      )}
    </div>
  );
};

export default TwoColsLayout;

import "./UserInfo.scss";
import { Avatar } from "../../../elements/avatar/Avatar";
import { bemElement } from "../../../utils/bem-class-names";
import { TUser } from "../../../types";

const baseClassName = "user";
const bem = bemElement(baseClassName);

interface IUserInfoData {
  user: TUser;
}

const UserInfo = ({ user }: IUserInfoData) => {
  return (
    <div className={baseClassName}>
      <Avatar
        src={user.photo_url || ""}
        monogram={user.first_name || ""}
        size="xl"
      />
      <div className={bem("info")}>
        <div className={bem("info-name")}>
          {user.first_name} {user.last_name}
        </div>
        <div className={bem("info-phone")}>{user.phone_number}</div>
      </div>
    </div>
  );
};

export default UserInfo;

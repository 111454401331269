import { ValidateFn } from "./validate-values";
import { PhoneNumberUtil } from "google-libphonenumber";

const EMAIL_RE =
  /^[a-zA-Z0-9]+(?:[_.-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[_.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/i;
const STANDARD_ASCII_RE = /^[\x21-\x7E]*$/; // standard ASCII printable characters (except Space)
const CARD_NUMBER_RE = /^(\d{4}) (\d{4}) (\d{4}) (\d{4})$/;
const PASSWORD_LENGTH = 8;
const translations = {
  required: "This field is required",
  invalid: "Invalid value",
  invalidEmail: "Not valid email format",
  passwordMinLength: "Password must be at least 8 characters",
  passwordStandardASCIICharsOnly:
    "Password should contain ASCII-standard characters only",
  invalidPhoneNumber: "Not valid phone number format",
  invalidCardNumber: "Not valid card number format",
  mismatch: "Values don't match"
};

function required(message = translations.required): ValidateFn {
  return function (value: unknown): string | null {
    return value ? null : message;
  };
}

function requiredIf(
  propName: string,
  propValue: unknown,
  message = translations.required
): ValidateFn {
  return function (
    value: unknown,
    actualValues?: { [k: string]: unknown }
  ): string | null {
    if (actualValues && actualValues[propName] === propValue) {
      return value ? null : message;
    }
    return null;
  };
}

function pattern(re: RegExp, message = translations.invalid): ValidateFn {
  return function (value: unknown): string | null {
    return !value || re.test(typeof value === "string" ? value : "")
      ? null
      : message;
  };
}

function email(message = translations.invalidEmail): ValidateFn {
  return pattern(EMAIL_RE, message);
}

function phone(message = translations.invalidPhoneNumber): ValidateFn {
  const isPhoneValid = (phone: string | undefined) => {
    const phoneUtil = PhoneNumberUtil.getInstance();

    if (!phone) {
      return false;
    }

    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  return function (value: unknown): string | null {
    return isPhoneValid(value as string) ? null : message;
  };
}

function creditCard(message = translations.invalidCardNumber): ValidateFn {
  return pattern(new RegExp(CARD_NUMBER_RE), message);
}

function password(
  lengthMessage = translations.passwordMinLength,
  standardASCIICharsOnlyMessage = translations.passwordStandardASCIICharsOnly
): ValidateFn {
  return function (value: unknown): string | null {
    if (typeof value !== "string") {
      return null;
    }
    if (value && value.length < PASSWORD_LENGTH) {
      return lengthMessage;
    }
    if (value && !STANDARD_ASCII_RE.test(value)) {
      return standardASCIICharsOnlyMessage;
    }
    return null;
  };
}

function matchWith(
  propName: string,
  message = translations.mismatch
): ValidateFn {
  return function (
    value: unknown,
    actualValues?: { [k: string]: unknown }
  ): string | null {
    return actualValues && value !== actualValues[propName] ? message : null;
  };
}

export const validators = {
  required,
  requiredIf,
  pattern,
  email,
  phone,
  creditCard,
  password,
  matchWith
};

import { createContext, ReactNode, useContext } from "react";
import Stripe from "stripe";

export interface IStripeContextInterface {
  stripe: Stripe;
}

interface IStripeProviderProps {
  children: ReactNode;
}

const StripeStateContext = createContext<IStripeContextInterface | undefined>(
  undefined
);

export function StripeProvider({
  children
}: IStripeProviderProps): JSX.Element {
  const stripe = new Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "", {
    apiVersion: "2022-11-15"
  });

  return (
    <StripeStateContext.Provider
      value={{
        stripe
      }}
    >
      {children}
    </StripeStateContext.Provider>
  );
}

export function useStripe(): IStripeContextInterface {
  const context = useContext(StripeStateContext);
  if (context === undefined) {
    throw new Error("useStripe must be used within a StripeProvider");
  }
  return context;
}

/* eslint-disable max-len */
import "./AccountDetails.scss";
import React, { useEffect, useMemo, useState } from "react";
import { bemElement } from "../../utils/bem-class-names";
import { useLocation, useNavigate } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";
import { Helmet } from "react-helmet";
import Menu, { menu } from "../../components/account-details/menu/Menu";
import AccountDetailsPageSections from "../../components/account-details/AccountDetailsPageSections";
import EditAccountSection from "../../components/account-details/edit-account-section/EditAccountSection";
import NotificationsSection from "../../components/account-details/notifications-section/NotificationsSection";
import DeleteAccountSection from "../../components/account-details/delete-account-section/DeleteAccountSection";
import PaymentMethodsSection from "../../components/account-details/payment-methods/PaymentMethodsSection";
import CreditsSection from "components/account-details/credits-section/CreditsSection";
import { Desktop } from "../../components/responsive/Responsive";
import UserInfo from "../../components/account-details/user-info/UserInfo";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { SvgIcon } from "../../elements/svg-icon/svg-icon";
import { useHeader } from "providers/header-provider";
import { useUser } from "../../providers/user";
import { APP_ROUTES } from "routes";
import { useCart } from "providers/cart-provider";
import { gql, useQuery } from "@apollo/client";
import { clearCache } from "utils/cacheHelper";

export interface ICredits {
  id: string;
  amount: number;
  consumed: boolean;
  company: {
    name: string;
    logo_url: string;
    id: string;
    slug: string;
  };
}

const GET_CREDITS = gql`
  query Refunds($where: RefundWhereInput) {
    refunds(where: $where) {
      id
      amount
      consumed
      company {
        name
        logo_url
        id
        slug
      }
    }
  }
`;

const baseClassName = "account-details-page";
const bem = bemElement(baseClassName);

const AccountDetails = () => {
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();
  const { user } = useUser();
  const { reset } = useHeader();
  const { resetEverything: resetCart } = useCart();
  const [currentSection, setCurrentSection] =
    useState<AccountDetailsPageSections>(AccountDetailsPageSections.DEFAULT);

  const { data: creditsData } = useQuery(GET_CREDITS, {
    variables: {
      where: {
        consumed: {
          equals: false
        },
        user_id: {
          equals: user?.id
        }
      }
    },
    skip: currentSection !== AccountDetailsPageSections.CREDITS,
    fetchPolicy: "network-only"
  });

  const pageTitle = useMemo(
    () =>
      menu.some((item) => item.section === currentSection)
        ? menu.find((item) => item.section === currentSection)?.label ||
          "Account details"
        : "Account details",
    [currentSection]
  );

  useEffect(() => {
    reset();
    resetCart();
    clearCache();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDesktop && !hash) {
      navigate(AccountDetailsPageSections.EDIT_ACCOUNT);
    } else if (
      !Object.values(AccountDetailsPageSections).includes(
        hash as AccountDetailsPageSections
      )
    ) {
      if (isDesktop) {
        navigate(AccountDetailsPageSections.EDIT_ACCOUNT);
      } else {
        navigate(AccountDetailsPageSections.DEFAULT);
      }
    }
  }, [isDesktop, hash, navigate]);

  useEffect(() => {
    setCurrentSection(hash as AccountDetailsPageSections);
  }, [hash]);

  if (!user || Object.keys(user).length === 0) {
    return null;
  }

  const renderSection = () => {
    switch (currentSection) {
      case AccountDetailsPageSections.DEFAULT:
        return (
          <div className={bem("mobile-menu")}>
            <UserInfo user={user} />
            <Menu />
          </div>
        );
      case AccountDetailsPageSections.EDIT_ACCOUNT:
        return <EditAccountSection />;
      case AccountDetailsPageSections.PAYMENT_METHODS:
        return <PaymentMethodsSection defaultMethod="card" />;
      case AccountDetailsPageSections.NOTIFICATIONS:
        return <NotificationsSection />;
      case AccountDetailsPageSections.CREDITS:
        return <CreditsSection credits={creditsData?.refunds} />;
      case AccountDetailsPageSections.DELETE_ACCOUNT:
        return <DeleteAccountSection />;
    }
  };

  return (
    <>
      <Helmet>
        <title>Pointment | {pageTitle}</title>
        <meta property="og:title" content={`Pointment | ${pageTitle}`} />
      </Helmet>
      <div className={baseClassName}>
        <Desktop>
          <div className={bem("top-block")}>
            <Breadcrumbs
              className={bem("top-block-breadcrumbs")}
              breadcrumbs={[{ title: "Account details", url: pathname }]}
              rootCrumbUrl={APP_ROUTES.ME}
            />
            <div className={bem("top-block-center")}>
              <UserInfo user={user} />
              <div className={bem("divider")}>
                <SvgIcon name="dot" />
                <hr />
                <SvgIcon name="dot" />
              </div>
            </div>
          </div>
        </Desktop>
        <div className={bem("content-wrapper")}>
          <div className={bem("content")}>
            <Desktop>
              <Menu />
            </Desktop>
            {renderSection()}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDetails;

import { ReactNode } from "react";
import { ApolloProvider as BaseApolloProvider } from "@apollo/client";
import { createApolloClient } from "../utils/create-apollo-client";
import { useCognito } from "./cognito";

interface IApolloProviderProps {
  children: ReactNode;
}

export function ApolloProvider({
  children
}: IApolloProviderProps): JSX.Element {
  const { user } = useCognito();
  const apolloClient = createApolloClient(user?.access_token);

  return (
    <BaseApolloProvider client={apolloClient}>{children}</BaseApolloProvider>
  );
}

/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { SignUpForm } from "forms/sign-up-form/SignUpForm";
import { useHeader } from "providers/header-provider";
import { APP_ROUTES } from "routes";
import { Toast } from "elements/toast/Toast";

const baseClassName = "sign-up-page";

export const SignUp = (): JSX.Element => {
  const navigate = useNavigate();
  const { reset } = useHeader();
  const [formErrorMessage, setFormErrorMessage] = useState<string>("");

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRedirectToLoginHandler = () => {
    navigate(APP_ROUTES.LOGIN);
  };

  return (
    <>
      <Helmet>
        <title>Pointment | Sign Up</title>
        <meta property="og:title" content="Pointment | Sign Up" />
      </Helmet>
      <div className={baseClassName}>
        <div className="form-wrapper">
          {formErrorMessage && (
            <Toast
              msg={formErrorMessage}
              type="error"
              showIcon={true}
              onClose={() => setFormErrorMessage("")}
            />
          )}
          <SignUpForm
            className="p-16px md:p-48px"
            onErrorMessage={setFormErrorMessage}
            onRedirectToLogin={onRedirectToLoginHandler}
          />
        </div>
      </div>
    </>
  );
};

import "./title-subtitle.scss";
import { joinClassNames } from "utils/join-class-names";
import { bemElement } from "utils/bem-class-names";

const baseClassName = "title-subtitle";
const bem = bemElement(baseClassName);

interface ITitleSubTitleProps {
  subtitle: JSX.Element;
  title: string;
  className?: string;
}

export const TitleSubtitle = ({
  subtitle,
  title,
  className = ""
}: ITitleSubTitleProps): JSX.Element => {
  return (
    <div className={joinClassNames(baseClassName, className)}>
      <span className={bem("title")}>{title}</span>
      <div className={bem("subtitle")}>{subtitle}</div>
    </div>
  );
};

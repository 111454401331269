import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CompanyQueryVariables = Types.Exact<{
  slug?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type CompanyQuery = {
  __typename?: "Query";
  company?: {
    __typename?: "Company";
    id: string;
    about?: string | null;
    caption?: string | null;
    name: string;
    slug?: string | null;
    amenities_enums: Array<Types.AmenityEnum>;
    payment_type: Types.PaymentType;
    cover_photo_url?: string | null;
    logo_url?: string | null;
    facebook_url?: string | null;
    instagram_url?: string | null;
    twitter_url?: string | null;
    website_url?: string | null;
    late_cancellation_charge: number;
    late_cancellation_hours: number;
    no_show_charge: number;
    amenities: Array<{
      __typename?: "CompanyAmenity";
      id: string;
      amenity: { __typename?: "Amenity"; type?: string | null; name: string };
    }>;
    locations: Array<{
      __typename?: "Location";
      id: string;
      cover_photo_url?: string | null;
      name: string;
      address_display?: string | null;
      slug?: string | null;
    }>;
    users: Array<{
      __typename?: "User";
      artist?: {
        __typename?: "Artist";
        id: string;
        cover_photo_url?: string | null;
        user?: { __typename?: "User"; first_name?: string | null } | null;
      } | null;
    }>;
  } | null;
};

export type CompanyLocationItemFragment = {
  __typename?: "Location";
  id: string;
  cover_photo_url?: string | null;
  name: string;
  address_display?: string | null;
  slug?: string | null;
};

export type CompanyAmenityFragment = {
  __typename?: "CompanyAmenity";
  id: string;
  amenity: { __typename?: "Amenity"; type?: string | null; name: string };
};

export const CompanyLocationItemFragmentDoc = gql`
  fragment CompanyLocationItem on Location {
    id
    cover_photo_url
    name
    address_display
    slug
  }
`;
export const CompanyAmenityFragmentDoc = gql`
  fragment CompanyAmenity on CompanyAmenity {
    id
    amenity {
      type
      name
    }
  }
`;
export const CompanyDocument = gql`
  query Company($slug: String) {
    company(where: { slug: $slug }) {
      id
      about
      caption
      name
      slug
      amenities {
        ...CompanyAmenity
      }
      amenities_enums
      payment_type
      locations {
        ...CompanyLocationItem
      }
      users {
        artist {
          id
          cover_photo_url
          user {
            first_name
          }
        }
      }
      cover_photo_url
      logo_url
      facebook_url
      instagram_url
      twitter_url
      website_url
      late_cancellation_charge
      late_cancellation_hours
      no_show_charge
    }
  }
  ${CompanyAmenityFragmentDoc}
  ${CompanyLocationItemFragmentDoc}
`;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCompanyQuery(
  baseOptions?: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(
    CompanyDocument,
    options
  );
}
export function useCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(
    CompanyDocument,
    options
  );
}
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<
  CompanyQuery,
  CompanyQueryVariables
>;

import "./LocationPreview.scss";
import { Avatar } from "elements/avatar/Avatar";
import { ImageElement } from "elements/image/Image";
import { bemElement } from "utils/bem-class-names";
import { useHeight } from "hooks/use-height";
import { joinClassNames } from "utils/join-class-names";
import { TLocation } from "types";

export interface ILocationPreviewLogo {
  src?: string;
  alt: string;
}

export interface ILocationPreviewProps {
  value: TLocation;
  aspectRatio: string;
  className?: string;
  logo?: ILocationPreviewLogo;
}

const baseClassName = "location-preview";
const bem = bemElement(baseClassName);

export const LocationPreview = ({
  value,
  aspectRatio,
  className = "",
  logo
}: ILocationPreviewProps): JSX.Element => {
  const paddingBottom = useHeight(aspectRatio);

  return (
    <div
      style={{ paddingBottom }}
      className={joinClassNames(baseClassName, className)}
      data-cy={"location-preview"}
    >
      <div className={bem("content")}>
        <ImageElement
          src={value.cover_photo_url || ""}
          alt={value.name}
          className={bem("image")}
          aspectRatio={aspectRatio}
        />
        <div className={bem("info")}>
          {!!logo && (
            <Avatar
              className={bem("logo")}
              src={logo.src || ""}
              alt={logo.alt}
              shape="square"
              size="md"
            />
          )}
          <div className={bem("main-info")}>
            <span
              className={bem("title")}
              data-cy={`card-${value.name.toLowerCase().replace(/\s+/g, "-")}`}
            >
              {value.name}
            </span>
            {value.address_display && (
              <span className={bem("address")}>{value.address_display}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

import "./CreditsSection.scss";
import { COLORS } from "../../../models/colors";
import { Button } from "../../../elements/button/Button";
import { VARIANTS } from "../../../models/variants";
import { bemElement } from "../../../utils/bem-class-names";
import { ICredits } from "routes/account-details/AccountDetails";
import { useNavigate } from "react-router-dom";

const baseClassName = "credits-section";
const bem = bemElement(baseClassName);

const CreditsSection = ({ credits }: { credits: ICredits[] }) => {
  const navigate = useNavigate();

  return (
    <div className={baseClassName}>
      <div className={bem("credits-wrapper")}>
        <div className={bem("title")}>
          Use credits to pay for your next Pointment at the following companies:
        </div>
        <div className={bem("credits-list")}>
          {credits?.map((credit, i) => (
            <div className={bem("credit-cont")} key={credit.id}>
              <div className={bem("credit")}>
                <div className={bem("company-logo")}>
                  <img
                    src={credit.company.logo_url}
                    alt={credit.company.name}
                  />
                </div>
                <div className={bem("credit-company")}>
                  <div className={bem("company-name")}>
                    {credit.company.name}
                  </div>
                  <div className={bem("credit-amount")}>${credit.amount}</div>
                </div>
                <Button
                  variant={VARIANTS.OUTLINED}
                  color={COLORS.SECONDARY}
                  text="Book"
                  iconRightName="arrow_right"
                  onClick={() => navigate(`/company/${credit.company.slug}`)}
                />
              </div>
              {i !== credits.length - 1 && <div className={bem("divider")} />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CreditsSection;

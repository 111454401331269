import "./Accordion.scss";
import { useEffect, useState } from "react";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { bemElement, bemModifier } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";

interface IAccordionProps {
  children: JSX.Element[];
  className?: string;
  expanded?: boolean;
}

const baseClassName = "accordion";
const bem = bemElement(baseClassName);

export const Accordion = ({
  children,
  className = "",
  expanded = false
}: IAccordionProps): JSX.Element => {
  const [open, setOpen] = useState(!!expanded);
  const onClickHandler = (): void => {
    setOpen((prevValue: boolean) => !prevValue);
  };

  useEffect(() => {
    setOpen(!!expanded);
  }, [expanded]);

  return (
    <div
      className={joinClassNames(
        bemModifier(baseClassName, open ? "expanded" : "collapsed"),
        className
      )}
    >
      <div className={bem("header")}>
        <div className={bem("header-data")}>{children[0]}</div>
        <div
          className={bem("header-icon")}
          onClick={onClickHandler}
          aria-hidden="true"
        >
          <SvgIcon name="arrow_bottom" />
        </div>
      </div>
      <div className={bem("body")}>{children[1]}</div>
    </div>
  );
};

export default Accordion;

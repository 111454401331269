import "./AuthSection.scss";
import { useState } from "react";
import { LoginForm } from "forms/login-form/LoginForm";
import { ResetPasswordForm } from "forms/reset-password-form/ResetPasswordForm";
import { SignUpForm } from "forms/sign-up-form/SignUpForm";
import Verify from "routes/Verify";
import { Toast } from "elements/toast/Toast";

enum AUTH_SECTION_TYPE {
  LOGIN = "login",
  SIGN_UP = "sign-up",
  RESET_PASSWORD = "reset-password",
  VERIFY = "verify"
}
type TAuthSectionType =
  | AUTH_SECTION_TYPE.LOGIN
  | AUTH_SECTION_TYPE.SIGN_UP
  | AUTH_SECTION_TYPE.RESET_PASSWORD
  | AUTH_SECTION_TYPE.VERIFY;

interface IAuthSectionData {
  type: TAuthSectionType;
  payload: string;
}

const getLogin = (
  setType: (type: IAuthSectionData) => void,
  onErrorMessage: (msg: string) => void
): JSX.Element => (
  <LoginForm
    onLoggedIn={() => {}}
    onUserNotConfirmed={(email: string) => {
      setType({ type: AUTH_SECTION_TYPE.VERIFY, payload: email.toLowerCase() });
    }}
    onRedirectToSignUp={() => {
      setType({ type: AUTH_SECTION_TYPE.SIGN_UP, payload: "" });
    }}
    onRedirectToResetPassword={() => {
      setType({ type: AUTH_SECTION_TYPE.RESET_PASSWORD, payload: "" });
    }}
    description="To make a reservation please log in first."
    onErrorMessage={onErrorMessage}
  />
);

const getSignUp = (
  setType: (type: IAuthSectionData) => void,
  onErrorMessage: (msg: string) => void
): JSX.Element => (
  <SignUpForm
    onRedirectToLogin={() => {
      setType({ type: AUTH_SECTION_TYPE.LOGIN, payload: "" });
    }}
    onRedirectToVerify={(email: string) => {
      setType({ type: AUTH_SECTION_TYPE.VERIFY, payload: email.toLowerCase() });
    }}
    description="To make a reservation please sign up first."
    onErrorMessage={onErrorMessage}
  />
);

const getResetPassword = (
  setType: (type: IAuthSectionData) => void,
  onErrorMessage: (msg: string) => void
): JSX.Element => (
  <ResetPasswordForm
    onResetPassword={() => {}}
    onRedirectToLogin={() => {
      setType({ type: AUTH_SECTION_TYPE.LOGIN, payload: "" });
    }}
    onErrorMessage={onErrorMessage}
  />
);

const getVerify = (
  setType: (type: IAuthSectionData) => void,
  data: IAuthSectionData
): JSX.Element => (
  <Verify
    email={data.payload}
    onReturnToSignUp={() => {
      setType({ type: AUTH_SECTION_TYPE.SIGN_UP, payload: "" });
    }}
    onSuccess={() => {
      setType({ type: AUTH_SECTION_TYPE.LOGIN, payload: "" });
    }}
  />
);

export const AuthSection = (): JSX.Element => {
  const [type, setType] = useState<IAuthSectionData>({
    type: AUTH_SECTION_TYPE.LOGIN,
    payload: ""
  });
  const [formErrorMessage, setFormErrorMessage] = useState<string>("");

  const getContent = (data: IAuthSectionData): JSX.Element => {
    switch (data.type) {
      case AUTH_SECTION_TYPE.LOGIN:
        return getLogin(setType, setFormErrorMessage);
      case AUTH_SECTION_TYPE.SIGN_UP:
        return getSignUp(setType, setFormErrorMessage);
      case AUTH_SECTION_TYPE.RESET_PASSWORD:
        return getResetPassword(setType, setFormErrorMessage);
      case AUTH_SECTION_TYPE.VERIFY:
        return getVerify(setType, data);
      default:
        return <></>;
    }
  };

  return (
    <div className="auth-section">
      {formErrorMessage && (
        <Toast
          msg={formErrorMessage}
          type="error"
          showIcon={true}
          onClose={() => setFormErrorMessage("")}
        />
      )}
      <div className="auth-section__form">{getContent(type)}</div>
    </div>
  );
};

import "./Footer.scss";
import { joinClassNames } from "utils/join-class-names";

export interface IFooterProps {
  className?: string;
}

const Footer = ({ className = "" }: IFooterProps): JSX.Element => {
  return (
    <footer className={joinClassNames("app-footer", className)}>
      <a href="mailto:info@pointmentapp.com">info@pointmentapp.com</a>
      <div>•</div>
      <div>© Pointment {new Date().getFullYear()}</div>
    </footer>
  );
};

export default Footer;

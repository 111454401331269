/* eslint-disable no-console */

import "./LoginForm.scss";
import { useMemo, useState } from "react";
import { Button } from "elements/button/Button";
import { IForm, useForm } from "hooks/use-form";
import { COLORS } from "models/colors";
import { VARIANTS } from "models/variants";
import { validators } from "validators";
import * as Axios from "providers/axios";
import {
  FormBuilder,
  IFormElement,
  generateFormElementConfigs
} from "components/form-builder";
import { bemElement } from "utils/bem-class-names";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { Auth } from "@aws-amplify/auth";
import * as Cognito from "providers/cognito";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES, getVerifyUserRoute } from "routes";
import { joinClassNames } from "utils/join-class-names";
// import { OauthButtons } from "components/oauth-buttons/OAuthButtons";

const baseClassName = "login-form";
const bem = bemElement(baseClassName);
const loginFormConfig = {
  email: "",
  password: ""
};

let showValidationErrors = true;

const getFormElements = (form: IForm): IFormElement[] => {
  return generateFormElementConfigs(form, [
    {
      type: "Input",
      formValueName: "email",
      extraProps: {
        cyId: "email-input",
        label: "Email",
        type: "email",
        showErrors: showValidationErrors
      }
    },
    {
      type: "Input",
      formValueName: "password",
      extraProps: {
        cyId: "password-input",
        label: "Password",
        type: "password",
        hint: "Your password should be at least 8 characters long.",
        showErrors: showValidationErrors
      }
    }
  ]);
};

export interface ILoginFormProps {
  className?: string;
  onRedirectToSignUp: () => void;
  onRedirectToResetPassword: () => void;
  description?: string;
  onLoggedIn?: () => void;
  onUserNotConfirmed?: (email: string) => void;
  onErrorMessage?: (error: string) => void;
}

export const LoginForm = ({
  className = "",
  onLoggedIn,
  onRedirectToSignUp,
  onRedirectToResetPassword,
  onUserNotConfirmed,
  description,
  onErrorMessage
}: ILoginFormProps): JSX.Element => {
  const { api } = Axios.useAxios();
  const { fetchAuthenticatedUser } = Cognito.useCognito();
  const [loginSubmitting, setLoginSubmitting] = useState<boolean>(false);
  const form = useForm(loginFormConfig, {
    email: [validators.required(), validators.email()],
    password: [validators.required(), validators.password()]
  });

  const formElements = useMemo(() => getFormElements(form), [form]);
  const navigate = useNavigate();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.setSubmitted(true);

    if (!form.valid) {
      return;
    }

    setLoginSubmitting(true);
    const emailLowerCase = (form.values.email as string).toLowerCase();
    const password = form.values.password as string;
    try {
      const user = await Auth.signIn(emailLowerCase, password);
      if (!user.attributes.email_verified) {
        onUserNotConfirmed
          ? onUserNotConfirmed(emailLowerCase)
          : navigate(getVerifyUserRoute(emailLowerCase), {
              state: { password }
            });
      }
      if (user.attributes.sub && user.attributes.email_verified) {
        fetchAuthenticatedUser();

        await api.post("/v1/users/login", {
          email: user.attributes.email,
          cognito_id: user.attributes.sub
        });

        onLoggedIn ? onLoggedIn() : navigate(APP_ROUTES.HOME);
      }
    } catch (error) {
      form.errors.email = error.message;
      onErrorMessage && onErrorMessage(error.message);
      if (error.message === "User is not confirmed.") {
        onUserNotConfirmed
          ? onUserNotConfirmed(emailLowerCase)
          : navigate(getVerifyUserRoute(emailLowerCase), {
              state: { password }
            });
      }
      console.log("error signing in", error.message);
      showValidationErrors = false;
      setLoginSubmitting(false);
    }
  };

  return (
    <div className={joinClassNames(baseClassName, className)}>
      <h2>Log in</h2>
      {/* <OauthButtons /> */}
      {description && <p className={bem("description")}>{description}</p>}

      <FormBuilder
        className={bem("form")}
        elements={formElements}
        onSubmit={onSubmit}
      >
        <div className={bem("form-controls")}>
          <Button
            className="flexible flex-1"
            cyId="redirect-to-sign-up-button"
            color={COLORS.PRIMARY}
            variant={VARIANTS.OUTLINED}
            text="New to Pointment?"
            onClick={onRedirectToSignUp}
          />
          <Button
            cyId="submit-sign-in-button"
            color={COLORS.PRIMARY}
            variant={VARIANTS.FILLED}
            text="Log in"
            disabled={(form.submitted && !form.valid) || loginSubmitting}
            type="submit"
          />
        </div>
      </FormBuilder>
      <hr className="divider" />
      <div
        aria-hidden
        className="forgot-password"
        onClick={onRedirectToResetPassword}
      >
        Forgot your password?
        <SvgIcon name="arrow_right" className="forgot-password-icon" />
      </div>
    </div>
  );
};

import { bemElement } from "utils/bem-class-names";
import "./TocPrivacyPolicyFooter.scss";
import { Link } from "react-router-dom";

const baseClassName = "toc-privacypolicy-footer";
const bem = bemElement(baseClassName);

const TocPrivacyPolicyFooter = () => {
  return (
    <footer className={baseClassName}>
      <p className={bem("copy-right")}>© Pointment 2024</p>
      <nav className={bem("nav")}>
        <Link className={bem("nav-item")} to="/toc">
          Terms and Conditions
        </Link>
        <span className={bem("nav-item-divider")} />
        <Link className={bem("nav-item")} to="/privacypolicy">
          Privacy Policy
        </Link>
        <span className={bem("nav-item-divider")} />
        <Link className={bem("nav-item")} to="/contact-us">
          Contact Us
        </Link>
      </nav>
    </footer>
  );
};

export default TocPrivacyPolicyFooter;

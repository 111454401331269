import { useMemo } from "react";
import { TAppointment, TAppointmentService } from "../types";

export const usePeopleCount = (appointment: TAppointment | undefined) => {
  return useMemo(() => {
    if (appointment?.appointment_services) {
      return (
        Math.max(
          ...appointment.appointment_services.map(
            (service: TAppointmentService) => service.person_number
          )
        ) + 1
      );
    }

    return 0;
  }, [appointment]);
};

import { joinClassNames } from "utils/join-class-names";
import "./icon-title.scss";
import { bemElement } from "utils/bem-class-names";
import { Avatar } from "elements/avatar/Avatar";

const baseClassName = "icon-title";
const bem = bemElement(baseClassName);

interface IIconTitleProps {
  src: string;
  title: string;
  className?: string;
}

export const IconTitle = ({
  src,
  title,
  className = ""
}: IIconTitleProps): JSX.Element => {
  return (
    <div className={joinClassNames(baseClassName, className)}>
      <Avatar
        className="mr-8px"
        src={src}
        alt={title}
        shape="square"
        size="xs"
      />
      <span className={bem("title")}>{title}</span>
    </div>
  );
};

// /* eslint-disable no-console */
// import { useEffect, useState } from "react";
// import { Helmet } from "react-helmet";
// import { useNavigate } from "react-router-dom";
// import { ResetPasswordForm } from "forms/reset-password-form/ResetPasswordForm";
// import { useHeader } from "providers/header-provider";
// import { APP_ROUTES } from "routes";
// import { Toast } from "elements/toast/Toast";

// const baseClassName = "reset-password-page";

// export const ResetPassword = () => {
//   const navigate = useNavigate();
//   const { reset } = useHeader();
//   const [formErrorMessage, setFormErrorMessage] = useState<string>("");

//   useEffect(() => {
//     reset();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const onRedirectToLoginHandler = () => {
//     navigate(APP_ROUTES.LOGIN);
//   };

//   const onResetPasswordHandler = () => {
//     console.log("password reset");
//   };

//   return (
//     <>
//       <Helmet>
//         <title>Pointment | Reset password</title>
//         <meta property="og:title" content="Pointment | Reset password" />
//       </Helmet>
//       <div className={baseClassName}>
//         <div className="form-wrapper">
//           {formErrorMessage && (
//             <Toast
//               msg={formErrorMessage}
//               type="error"
//               showIcon={true}
//               onClose={() => setFormErrorMessage("")}
//             />
//           )}
//           <ResetPasswordForm
//             className="p-16px md:p-48px"
//             onResetPassword={onResetPasswordHandler}
//             onRedirectToLogin={onRedirectToLoginHandler}
//             onErrorMessage={setFormErrorMessage}
//           />
//         </div>
//       </div>
//     </>
//   );
// };

// export default ResetPassword;

import React from "react";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import { Auth } from "@aws-amplify/auth";
import { Button, BUTTON_SIZES } from "../../components/Button";
import AuthInput from "../../components/AuthInput";
import AuthLayout from "../../components/AuthLayout";

/* eslint-disable */
const authLinkButtonStyles =
  "w-full rounded-[8px] !py-[13px] !bg-mono-tertiary !border !border-mono-primary !text-[15px] !font-['Inter'] !text-mono-primary hover:!text-mono-primary hover:!bg-mono-secondary focus:!bg-mono-secondary whitespace-nowrap !px-0";

const authSubmitButtonStyles =
  "w-auto rounded-[8px] !py-[13px] !bg-mono-primary border-none !text-[15px] !font-['Inter'] text-mono-white hover:!text-mono-white hover:!bg-mono-primaryLight focus:!bg-mono-primaryLight whitespace-nowrap !px-7 disabled:!bg-mono-300";
/* eslint-enable */
/* eslint-disable no-console */

export default function ResetPassword() {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const navigate = useNavigate();

  const { handleSubmit, handleChange, touched, errors, values } = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: ResetPasswordSchema,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: false,
    onSubmit: async ({ email }: { email: string }) => {
      try {
        setSubmitting(true);
        await Auth.forgotPassword(email.toLowerCase());
        return navigate(`/verify-reset?email=${email.toLowerCase()}`);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  return (
    <>
      <Helmet>
        <title>Pointment | Reset Password</title>
        <meta property="og:title" content="Pointment | Reset Password" />
      </Helmet>
      <AuthLayout title="Reset your password" error={error} setError={setError}>
        <form onSubmit={handleSubmit}>
          <AuthInput
            type="email"
            name="email"
            label="Email"
            placeholder="hello@pointmentapp.com"
            value={values.email}
            onChange={handleChange}
            touched={touched}
            errors={errors}
            autocapitalize="none"
          />
          <div className="flex gap-[16px]">
            <Link className="cursor-pointer w-full" to={"/login"} tabIndex={-1}>
              <Button
                className={authLinkButtonStyles}
                text={"Return to sign in"}
                size={BUTTON_SIZES.MEDIUM}
                type="button"
                backIcon
              />
            </Link>
            <Button
              className={authSubmitButtonStyles}
              text={"Continue"}
              type="submit"
              size={BUTTON_SIZES.MEDIUM}
              submitting={submitting}
              disabledOnSubmit
            />
          </div>
        </form>
      </AuthLayout>
    </>
  );
}

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required("This field is required")
});

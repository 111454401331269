import "./Header.scss";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "elements/button/Button";
import { IconButton } from "elements/icon-button/IconButton";
import { COLORS } from "models/colors";
import { VARIANTS } from "models/variants";
import { useScroll } from "hooks/use-scroll";
import { useHeader } from "providers/header-provider";
import { bemElement, bemModifier } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";
import { CenterDefault } from "./sections/center-default/center-default";
import { HeaderMenu } from "./header-menu/header-menu";
import { useUser } from "providers/user";
import useResponsive from "hooks/useResponsive";
import { APP_ROUTES } from "routes";
import Notifications from "./notifications/Notifications";
import { useNotificationsQuery } from "./notifications/Notifications.generated";
import { SortOrder } from "../../types.generated";
import { useInterval } from "react-use";

export interface IHeaderProps {
  className?: string;
}

const translation = {
  buttons: {
    businessLogin: "For businesses",
    logIn: "Log in",
    signUp: "Get started"
  }
};

const baseClassName = "header";
const bem = bemElement(baseClassName);

export const useHeaderTransparent = (): boolean => {
  const { scrollY } = useScroll();

  return scrollY < 20;
};

export const Header = ({ className = "" }: IHeaderProps): JSX.Element => {
  const { isLoggedIn } = useUser();
  const { isDesktop } = useResponsive();
  const navigate = useNavigate();
  const location = useLocation();
  const isHeaderTransparent = useHeaderTransparent();
  const { user } = useUser();

  function handleIsWebview() {
    if (globalThis.isReactNativeWebview === "true") {
      return true;
    }
    if (globalThis.isReactNativeWebview2 === "yes") {
      return true;
    }
    return false;
  }

  const isWebview = handleIsWebview();

  const {
    centerSection,
    rightSection,
    leftSection,
    showMenu,
    showNotifications,
    type,
    setShowMenu,
    setShowNotifications,
    setLeftSection
  } = useHeader();

  const { data, refetch } = useNotificationsQuery({
    variables: {
      where: {
        user_id: {
          equals: user?.id
        }
      },
      orderBy: {
        created_at: SortOrder.Desc
      },
      take: 1
    },
    skip: !user?.id
  });
  const haveNewNotifications =
    data?.notifications &&
    data.notifications.length === 1 &&
    new Date(user?.last_viewed_notifications) <
      new Date(data.notifications[0].created_at);

  useInterval(() => {
    refetch();
  }, 120000);

  useEffect(() => {
    setLeftSection(
      showMenu ? (
        <IconButton
          color={COLORS.PRIMARY}
          iconName="close"
          onClick={() => setShowMenu(false)}
        />
      ) : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu]);

  return (
    <header
      className={joinClassNames(
        bemModifier(baseClassName, type ? type : "", {
          "non-transparent": !isHeaderTransparent
        }),
        location.pathname === APP_ROUTES.HOME ? bem("home") : "",
        className
      )}
    >
      <div className={bem("container")}>
        <div className={joinClassNames(bem("column"), bem("left"))}>
          {leftSection ? (
            leftSection
          ) : isLoggedIn ? (
            <IconButton
              color={COLORS.PRIMARY}
              variant={VARIANTS.DEFAULT}
              iconName="burger_menu"
              cyId="burger-menu-button"
              onClick={() => setShowMenu(true)}
            />
          ) : isDesktop && !isWebview ? (
            <Button
              className={joinClassNames(
                bem("button"),
                bem("business-login-button")
              )}
              color={COLORS.SECONDARY}
              variant={VARIANTS.OUTLINED}
              text={translation.buttons.businessLogin}
              size="sm"
              onClick={() => window.open("https://business.pointmentapp.com")}
            />
          ) : (
            <IconButton
              color={COLORS.PRIMARY}
              variant={VARIANTS.DEFAULT}
              iconName="burger_menu"
              cyId="burger-menu-button"
              onClick={() => setShowMenu(true)}
            />
          )}
        </div>
        <div className={joinClassNames(bem("column"), bem("center"))}>
          {centerSection ? centerSection : <CenterDefault />}
        </div>
        <div className={joinClassNames(bem("column"), bem("right"))}>
          {rightSection ? (
            rightSection
          ) : isLoggedIn ? (
            <IconButton
              color={COLORS.PRIMARY}
              variant={VARIANTS.DEFAULT}
              iconName={haveNewNotifications ? "notified" : "notifications"}
              onClick={() => setShowNotifications(true)}
            />
          ) : (
            isDesktop && (
              <>
                {location.pathname !== APP_ROUTES.LOGIN && (
                  <Button
                    className={joinClassNames(
                      bem("button"),
                      bem("login-button")
                    )}
                    color={COLORS.PRIMARY}
                    text={translation.buttons.logIn}
                    size="sm"
                    onClick={() => navigate(APP_ROUTES.LOGIN)}
                  />
                )}
                {location.pathname !== APP_ROUTES.SIGN_UP && (
                  <Button
                    className={joinClassNames(
                      bem("button"),
                      bem("sign-up-button")
                    )}
                    color={COLORS.SECONDARY}
                    text={translation.buttons.signUp}
                    variant={VARIANTS.OUTLINED}
                    size="sm"
                    onClick={() => navigate(APP_ROUTES.SIGN_UP)}
                  />
                )}
              </>
            )
          )}
        </div>
      </div>
      {showMenu && <HeaderMenu />}
      {showNotifications && <Notifications />}
    </header>
  );
};

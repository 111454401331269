/* eslint-disable max-len */
export const LogoutIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill={props?.fill || "#302F2E"}
      fillOpacity={props?.fill ? 1 : 0.56}
      d="M9.494 10.206a.833.833 0 0 1-.207-.553.7.7 0 0 1 .207-.516L10.88 7.75H5.5a.726.726 0 0 1-.534-.216A.726.726 0 0 1 4.75 7c0-.213.072-.39.216-.534A.726.726 0 0 1 5.5 6.25h5.381L9.494 4.862a.73.73 0 0 1-.225-.534.73.73 0 0 1 .225-.534.67.67 0 0 1 .515-.225.7.7 0 0 1 .516.206l2.7 2.7a.659.659 0 0 1 .16.244.831.831 0 0 1 .046.281c0 .1-.015.194-.047.281a.659.659 0 0 1-.159.244l-2.7 2.7c-.162.162-.34.234-.534.216a.79.79 0 0 1-.497-.235ZM1.75 13.75c-.413 0-.766-.147-1.06-.44a1.445 1.445 0 0 1-.44-1.06V1.75c0-.413.147-.766.44-1.06.294-.293.647-.44 1.06-.44h4.5c.213 0 .39.072.534.216A.726.726 0 0 1 7 1c0 .212-.072.39-.216.534a.726.726 0 0 1-.534.216h-4.5v10.5h4.5c.213 0 .39.072.534.216A.726.726 0 0 1 7 13c0 .213-.072.39-.216.534a.726.726 0 0 1-.534.216h-4.5Z"
    />
  </svg>
);

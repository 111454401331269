import { useMemo } from "react";

/**
 * Calculate height according to width.
 * @param {string} aspectRatio, e.g. "16:9"
 * @returns {string} height, e.g. "56.25%"
 */
export const useHeight = (aspectRatio: string): string => {
  return useMemo(() => {
    const [width, height] = aspectRatio.split(":");
    if (!!width && !!height) {
      return `${(100 / parseInt(width)) * parseInt(height)}%`;
    } else {
      return "100%";
    }
  }, [aspectRatio]);
};

import { QueryClient, QueryClientProvider } from "react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";

import * as Auth from "./auth";
import * as Axios from "./axios";
import { GeolocationProvider } from "./geolocation-provider";
import { CartProvider } from "./cart-provider";
import { CognitoProvider } from "./cognito";
import { UserProvider } from "./user";
import { StripeProvider } from "./stripe-provider";
import { ApolloProvider } from "./apollo-provider";
import { HeaderProvider } from "./header-provider";

type Props = {
  children: JSX.Element;
};

const queryClient = new QueryClient();

export default function AppProviders({ children }: Props) {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
    >
      <CognitoProvider>
        <Auth.AuthProvider>
          <Axios.AxiosProvider>
            <ApolloProvider>
              <UserProvider>
                <QueryClientProvider client={queryClient}>
                  <StripeProvider>
                    <HeaderProvider>
                      <GeolocationProvider>
                        <CartProvider>{children}</CartProvider>
                      </GeolocationProvider>
                    </HeaderProvider>
                  </StripeProvider>
                </QueryClientProvider>
              </UserProvider>
            </ApolloProvider>
          </Axios.AxiosProvider>
        </Auth.AuthProvider>
      </CognitoProvider>
    </GoogleOAuthProvider>
  );
}

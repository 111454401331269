import "./AddToCalendarModal.scss";
import Modal from "../modal/Modal";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { AddToCalendarButtonProps } from "add-to-calendar-button-react/dist/AddToCalendarButton";
import { bemElement } from "../../../utils/bem-class-names";
import { joinClassNames } from "../../../utils/join-class-names";
import { TCalendarEvent } from "../../../types";

const baseClassName = "add-to-calendar-modal";
const bem = bemElement(baseClassName);

interface IAddToCalendarModalData {
  show: boolean;
  onHide: () => void;
  event: TCalendarEvent;
  className?: string;
}

export const AddToCalendarModal = ({
  show,
  onHide,
  event,
  className = ""
}: IAddToCalendarModalData) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Pick a Calendar"
      align="bottom"
      className={joinClassNames(baseClassName, className)}
    >
      <div className={bem("container")}>
        <div className={bem("buttons")}>
          <AddToCalendarButton
            styleLight="--font: Inter; --btn-text: #3B3B3B"
            options={["Apple"]}
            label="Apple Calendar"
            buttonStyle="round"
            size="8"
            hideBranding
            hideCheckmark
            {...(event as AddToCalendarButtonProps)}
          />
          <AddToCalendarButton
            styleLight="--font: Inter; --btn-text: #3B3B3B"
            options={["Google"]}
            label="Google Calendar"
            buttonStyle="round"
            size="8"
            hideBranding
            hideCheckmark
            {...(event as AddToCalendarButtonProps)}
          />
          <AddToCalendarButton
            styleLight="--font: Inter; --btn-text: #3B3B3B"
            options={["Outlook.com"]}
            label={"Outlook Calendar"}
            buttonStyle="round"
            size="8"
            hideBranding
            hideCheckmark
            {...(event as AddToCalendarButtonProps)}
          />
        </div>
      </div>
    </Modal>
  );
};

import { CardInput, TCardInputValueName } from "elements/card-input/CardInput";
import { IFormElementProps } from "../FormBuilder";

interface ICardInputFormElementProps {
  elementProps: IFormElementProps;
}

const onBlurHandler = (
  name: TCardInputValueName,
  elementProps: IFormElementProps
) => {
  switch (name) {
    case "card":
      elementProps.onBlur &&
        elementProps.onBlur(elementProps.value0name as string);
      break;
    case "date":
      elementProps.onBlur &&
        elementProps.onBlur(elementProps.value1name as string);
      break;
    case "CVC":
      elementProps.onBlur &&
        elementProps.onBlur(elementProps.value2name as string);
      break;
  }
};

const onChangeHandler = (
  value: string,
  name: TCardInputValueName,
  elementProps: IFormElementProps
) => {
  switch (name) {
    case "card":
      elementProps.onChange &&
        elementProps.onChange(value, elementProps.value0name as string);
      break;
    case "date":
      elementProps.onChange &&
        elementProps.onChange(value, elementProps.value1name as string);
      break;
    case "CVC":
      elementProps.onChange &&
        elementProps.onChange(value, elementProps.value2name as string);
      break;
  }
};

export const CardInputFormElement = ({
  elementProps
}: ICardInputFormElementProps): JSX.Element => {
  return (
    <CardInput
      cardValue={elementProps.value0 ? elementProps.value0.toString() : ""}
      dateValue={elementProps.value1 ? elementProps.value1.toString() : ""}
      CVCValue={elementProps.value2 ? elementProps.value2.toString() : ""}
      errorMsg={elementProps.errorMsg}
      onBlur={(name: TCardInputValueName) => onBlurHandler(name, elementProps)}
      onChange={(value: string, name: TCardInputValueName) =>
        onChangeHandler(value, name, elementProps)
      }
    />
  );
};

/* eslint-disable max-len */
import "./DateAndTime.scss";
import { useEffect, useMemo, useState, useRef } from "react";
import { IBreadcrumb } from "components/breadcrumbs/Breadcrumbs";
import { Desktop } from "components/responsive/Responsive";
import { Button } from "elements/button/Button";
import { LoadingElement } from "elements/loading-element/LoadingElement";
import { COLORS } from "models/colors";
import { VARIANTS } from "models/variants";
import DateAndTimeSelect from "../date-and-time-select/DateAndTimeSelect";
import ChangeArtistModal from "../modals/change-artist-modal/ChangeArtistModal";
import { IconButton } from "../../elements/icon-button/IconButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SvgIcon } from "../../elements/svg-icon/svg-icon";
import { useCart } from "providers/cart-provider";
import { useAxios } from "providers/axios";
import { useUser } from "providers/user";
import { APP_ROUTES } from "routes";
import {
  TArtist,
  TBookAppointmentResponse,
  TLocation,
  TPossibleAppointment,
  TPossibleAppointmentMultipleArtists,
  TService
} from "types";
// eslint-disable-next-line max-len
import BookingConfirmationModal from "../modals/booking-confirmation-modal/BookingConfirmationModal";
import { getRelativeDateTime } from "../../utils/date-time";
import { usePeopleCount } from "../../hooks/use-people-count";
import { SelectedArtistsBubble } from "components/selected-artists-bubble/SelectedArtistsBubble";
import { getAppointmentStatus } from "../../routes/booking-details/BookingDetails";
import { clearCache } from "utils/cacheHelper";
import ArtistItem from "./artistItem/ArtistItem";
import usePreventBrowserNavigationOnScroll from "hooks/usePreventBrowserNavigationOnScroll";

const artistColors = [
  "#F8C0E5",
  "#7ED9B8",
  "#7EC1DE",
  "#EDB59D",
  "#DE7EBD",
  "#AADF98",
  "#7E88DE",
  "#F0C899",
  "#BA7EDE",
  "#CFF8C0",
  "#C0E7F8",
  "#F8D1C0",
  "#E3C0F8",
  "#F8ECC0",
  "#BEF3E0",
  "#C0C6F8",
  "#F8DEC0"
];

export interface IModalError {
  type: string;
  text: string;
}
export interface IDateAndTime {
  className?: string;
  onCurrentRoutes?: (route?: IBreadcrumb[]) => void;
  onClose?: () => void;
  rescheduleAppointment?: any;
  setModalError?: (err: IModalError | null) => void;
}

const dateAndTimeBreadcrumbRoute = {
  title: "Date and time",
  url: ""
};

const DateAndTime = ({
  className,
  onCurrentRoutes,
  onClose,
  rescheduleAppointment,
  setModalError
}: IDateAndTime) => {
  const [searchParams] = useSearchParams();
  const {
    cart,
    possibleAppointment,
    setPossibleAppointment,
    activePersonsNumber,
    bookedAppointmentResponse,
    getDesiredAppointmentBody,
    selectMainArtist,
    autoSelectArtists,
    setCurrentLocation,
    selectTime,
    setArtists,
    setServices,
    artists,
    setBookedAppointmentResponse,
    resetEverything,
    mainArtistId,
    getBookAppointmentBody,
    setAddOnsWithCompleteData
  } = useCart();
  const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false);
  const [rescheduleLoading, setRescheduleLoading] = useState<boolean>(false);
  const [isFetchingPossibleAppointments, setIsFetchingPossibleAppointments] =
    useState<boolean>(false);
  const [, setErrorMsg] = useState<string>("");
  const [showChangeArtistModal, setShowChangeArtistModal] =
    useState<boolean>(false);
  const { api } = useAxios();
  const { user } = useUser();
  const peopleCount = usePeopleCount(
    bookedAppointmentResponse?.createdAppointment
  );
  const artistsRefForScroll = useRef<HTMLDivElement>(null);
  const startTimes = useMemo(() => {
    return (
      possibleAppointment?.map(
        (appointment: TPossibleAppointment) => appointment.start_time
      ) || []
    );
  }, [possibleAppointment]);
  const possibleArtists = useMemo(() => {
    if (!possibleAppointment) return [];

    return possibleAppointment
      .filter(
        (appointment: TPossibleAppointment) =>
          appointment.start_time === cart.start_time
      )
      .map((appointment: TPossibleAppointment) =>
        appointment.default_permutations
          .map((artists: TPossibleAppointmentMultipleArtists[]) =>
            artists.flat()
          )
          .flat()
      )
      .flat()
      .sort((prev, next) => {
        if (prev.personIndex < next.personIndex) {
          return -1;
        }
        if (prev.personIndex > next.personIndex) {
          return 1;
        }

        return 0;
      })
      .filter(
        (value, index, array) =>
          array.findIndex((value2) => value2.artist_id === value.artist_id) ===
          index
      );
  }, [possibleAppointment, cart.start_time]);

  const navigate = useNavigate();
  usePreventBrowserNavigationOnScroll(artistsRefForScroll);
  const onReserveButtonClickHandler = (): void => {
    if (cart.services.filter((service) => !service.artist).length > 0) {
      autoSelectArtists();
    }
    if (rescheduleAppointment) {
      const bookAppointment = async () => {
        (async function () {
          try {
            setRescheduleLoading(true);
            const body = {
              ...getBookAppointmentBody(),
              impersonate_id: user?.impersonate_name
                ? user?.impersonate_id
                : undefined
            };
            if (
              rescheduleAppointment?.id &&
              (getAppointmentStatus(rescheduleAppointment) === "upcoming" ||
                user?.impersonate_name)
            ) {
              body.reschedule_appointment_id = rescheduleAppointment.id;
            }
            const response: TBookAppointmentResponse = (await api
              .post(`/v1/book-appointment/`, body)
              .then((r) => r.data)) as TBookAppointmentResponse;

            setBookedAppointmentResponse(response);
            setIsPaymentSuccess(true);
          } catch (err) {
            setErrorMsg(err.response.data.message);
          } finally {
            setRescheduleLoading(false);
          }
        })();
      };

      bookAppointment();
    } else {
      onClose && onClose();
      navigate(APP_ROUTES.RESERVATION);
    }
  };

  useEffect(() => {
    if (searchParams.get("artist") && possibleArtists) {
      const artist = possibleArtists.find(
        (artist) =>
          artist.personIndex === 0 &&
          artist.artist_id === searchParams.get("artist") &&
          !cart.services.some(
            (personCart) =>
              personCart.artist &&
              personCart.artist.artist_id === artist.artist_id &&
              artist.personIndex === 0
          )
      );
      if (artist) {
        selectMainArtist(artist.artist_id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [possibleArtists, searchParams, cart]);

  useEffect(() => {
    (async function () {
      try {
        if (cart.services.length > 0) {
          setIsFetchingPossibleAppointments(true);
          // If this request is sent, we don't need data in the cache
          // because this is the first request.(I am not caching this request
          // because this is the main request, where everything begins.
          // It means the user has just chosen services and add-ons.
          // Without clearing the cache, when you change the screen size,
          // this component is reset and has an issue. I don't like handling these cases
          // because real users will not change screen size from the inspect element,
          // but if users rotate their tablet and the UI is changed from tablet to web or vice versa,
          // the date and time component will start working from the beginning, so we don't need a cache.
          clearCache();
          const body = {
            ...getDesiredAppointmentBody(),
            locations: rescheduleAppointment?.location?.id
              ? [rescheduleAppointment?.location?.id]
              : getDesiredAppointmentBody().locations,
            impersonate_id: user?.impersonate_name
              ? user?.impersonate_id
              : undefined
          };
          if (
            rescheduleAppointment?.id &&
            getAppointmentStatus(rescheduleAppointment) === "upcoming"
          ) {
            body.reschedule_appointment_id = rescheduleAppointment.id;
          }
          const response: {
            appointments: TPossibleAppointment[];
            location: TLocation;
            artists: TArtist[];
            services: TService[];
            add_ons: TService[];
          } = (await api
            .post(`/v1/default-permutations/`, body)
            .then((r) => r.data[0])) as {
            appointments: TPossibleAppointment[];
            location: TLocation;
            artists: TArtist[];
            services: TService[];
            add_ons: TService[];
          };

          setCurrentLocation(response.location);
          setPossibleAppointment(response.appointments);
          setArtists(response.artists);
          setServices(response.services);
          setAddOnsWithCompleteData(response.add_ons);
        }
      } catch (err) {
        setPossibleAppointment(null);
        setModalError &&
          setModalError({ type: "Error", text: err.response.data.message });
      } finally {
        setIsFetchingPossibleAppointments(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onCurrentRoutes && onCurrentRoutes([dateAndTimeBreadcrumbRoute]);

    return () => {
      onCurrentRoutes && onCurrentRoutes([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onCurrentRoutes) {
      showChangeArtistModal
        ? onCurrentRoutes([
            dateAndTimeBreadcrumbRoute,
            { title: "Details", url: "" }
          ])
        : onCurrentRoutes([dateAndTimeBreadcrumbRoute]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChangeArtistModal]);

  const onLogout = async () => {
    try {
      await api.post("v1/users/impersonate-logout");

      const adminUrl = process.env.REACT_APP_ADMIN_URL;
      window.location.href = `${adminUrl}/dashboard/Customer/${user?.impersonate_id}/show`;
    } catch (error) {
      throw new Error("Error logging out: " + error.message);
    }
  };

  return (
    <div
      className={`date-and-time-container${className ? " " + className : ""}`}
    >
      <div className={`date-and-time-wrapper lg:hide-scroll-bar`}>
        <div className="info">
          <Desktop>
            <IconButton
              className="back-icon"
              color={COLORS.PRIMARY}
              iconName="arrow_left"
              onClick={() => onClose && onClose()}
            />
          </Desktop>
          <div className="people-icons">
            {new Array(activePersonsNumber)
              .fill(1)
              .map((el: number, i: number) => (
                <SvgIcon key={i} name="person_circle" />
              ))}
          </div>
          <div className="text">
            <div>Available</div>
            <div>
              Date and time for {activePersonsNumber}{" "}
              {activePersonsNumber > 1 ? "people" : "person"}
            </div>
          </div>
          <div className="info-header">
            <div />
            <div className="middle-column">
              <div className="title">Date and time</div>
              <div className="caption">
                {cart.location?.name} • {activePersonsNumber}{" "}
                {activePersonsNumber === 1 ? "person" : "people"}
              </div>
            </div>
            <div className="right-column">
              <Button
                color={COLORS.PRIMARY}
                size="sm"
                text="Reset"
                disabled={false}
                onClick={() => {
                  if (possibleAppointment) {
                    selectTime(possibleAppointment[0].start_time);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <BookingConfirmationModal
          show={
            isPaymentSuccess && !!bookedAppointmentResponse?.createdAppointment
          }
          onHide={async () => {
            if (user?.impersonate_name) {
              await onLogout();
            }
            navigate(APP_ROUTES.ME);
            resetEverything();
          }}
          onViewDetailsClick={resetEverything}
          appointment={{
            id: bookedAppointmentResponse?.createdAppointment?.id,
            address:
              bookedAppointmentResponse?.createdAppointment?.location
                ?.address_display || "",
            preview:
              bookedAppointmentResponse?.createdAppointment?.location
                ?.cover_photo_url || "",
            slots: [
              {
                available: true,
                time: bookedAppointmentResponse?.createdAppointment?.start_time
                  ? getRelativeDateTime(
                      bookedAppointmentResponse?.createdAppointment
                        ?.start_time || "",
                      bookedAppointmentResponse?.createdAppointment?.location
                        ?.timezone
                    )
                  : ""
              }
            ],
            title:
              bookedAppointmentResponse?.createdAppointment?.location?.name ||
              "",
            peopleCount,
            servicesCount:
              bookedAppointmentResponse?.createdAppointment
                ?.appointment_services?.length,
            slotType: "primary"
          }}
        />
        {!isFetchingPossibleAppointments ? (
          <>
            <DateAndTimeSelect startTimes={startTimes} />
            <div className="artists-wrapper">
              <div
                className={`artists-header ${
                  artists?.length > 0 ? "min-h-[48px]" : ""
                }`}
              >
                {artists?.length > 0 && (
                  <div className="artists-title">Filter time by Artist</div>
                )}
                {!cart?.bookingUnavailable && (
                  <Button
                    color={COLORS.TERTIARY}
                    variant={VARIANTS.DEFAULT}
                    size="sm"
                    iconRightName="arrow_right"
                    text="Assignments"
                    onClick={() => setShowChangeArtistModal(true)}
                    className="assignments-button"
                  />
                )}
              </div>
              <div
                className={`artists-list hide-scroll-bar overscroll-none ${
                  artists?.length > 0 ? "py-[16px]" : ""
                }`}
                ref={artistsRefForScroll}
              >
                {artists
                  ?.filter(
                    (value, index, array) =>
                      array.findIndex((value2) => value2.id === value.id) ===
                      index
                  )
                  ?.map((artist: TArtist, index: number) => {
                    return (
                      <ArtistItem
                        key={index}
                        artist={artist}
                        selectMainArtist={selectMainArtist}
                        mainArtistId={mainArtistId}
                        artistColors={artistColors}
                        artists={artists}
                        artistsRefForScroll={artistsRefForScroll}
                      />
                    );
                  })}
              </div>
            </div>
          </>
        ) : (
          <div className="loading-container">
            <LoadingElement
              title="Please wait"
              description={`We are loading available Date and Time for ${activePersonsNumber} 
              ${activePersonsNumber === 1 ? "person" : "people"}...`}
            />
          </div>
        )}
      </div>
      <div className="buttons">
        {/* <div className="bottom-info">
          <SvgIcon name="filled_cart" /> {cart.services.length}{" "}
          <SvgIcon name="slash" className="slash" />{" "}
          <SvgIcon name="filled_person" /> {activePersonsNumber}
        </div> */}
        {!cart?.bookingUnavailable && <SelectedArtistsBubble />}
        <Button
          className="flexible flex-2 !h-[56px] customTransition"
          color={COLORS.SECONDARY}
          variant={VARIANTS.FILLED}
          onClick={onReserveButtonClickHandler}
          disabled={cart?.bookingUnavailable}
          cyId="reserve-button"
          text={
            rescheduleAppointment
              ? rescheduleLoading
                ? "Rescheduling"
                : "Reschedule"
              : "Reserve"
          }
          submitting={rescheduleLoading}
        />
      </div>
      {showChangeArtistModal && (
        <ChangeArtistModal
          show={showChangeArtistModal}
          onHide={() => setShowChangeArtistModal(false)}
        />
      )}
    </div>
  );
};

export default DateAndTime;

/* eslint-disable max-len */
import "./Me.scss";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "../../elements/avatar/Avatar";
import { Badge } from "../../elements/badge/Badge";
import { COLORS } from "../../models/colors";
import { VARIANTS } from "../../models/variants";
import { Button } from "../../elements/button/Button";
import { bemElement } from "../../utils/bem-class-names";
import { Helmet } from "react-helmet";
import { SvgIcon } from "../../elements/svg-icon/svg-icon";
import { ArtistPreview } from "../../components/artist-preview/ArtistPreview";
import { LocationPreview } from "../../components/location-preview/LocationPreview";
import { Card, ICard } from "../../components/card/Card";
import { joinClassNames } from "../../utils/join-class-names";
import { Desktop, Mobile } from "../../components/responsive/Responsive";
import Slider from "../../components/slider/Slider";
import { useUser } from "../../providers/user";
import {
  BookedAppointmentFragment,
  FavoriteArtistFragment,
  FavoriteLocationFragment,
  useAppointmentsQuery,
  useFavoriteArtistsQuery,
  useFavoriteLocationsQuery
} from "./Me.generated";
import { TAppointment, TArtist, TLocation } from "../../types";
import {
  getDate,
  getFormattedTime,
  getRelative,
  getZonedDate
} from "../../utils/date-time";
import { APP_ROUTES } from "routes";
import BookingConfirmationModal from "../../components/modals/booking-confirmation-modal/BookingConfirmationModal";
import { AddToCalendarModal } from "../../components/modals/add-to-calendar-modal/AddToCalendarModal";
import { usePeopleCount } from "../../hooks/use-people-count";
import { useHeader } from "../../providers/header-provider";
import { useCart } from "providers/cart-provider";
import { clearCache } from "utils/cacheHelper";

const baseClassName = "me-page";
const bem = bemElement(baseClassName);

const emptySectionData = {
  artists: {
    icon: "person",
    title: "Favorite Artists",
    caption: "Not added"
  },
  locations: {
    icon: "location",
    title: "Favorite Locations",
    caption: "Not added"
  },
  appointments: {
    icon: "recent_time",
    title: "Previous Appointments",
    caption: "No Pointments"
  }
};

interface ISectionData {
  type: keyof typeof emptySectionData;
  children: React.ReactNode;
}

interface IEmptySectionData {
  type: keyof typeof emptySectionData;
}

interface IAppointments {
  upcoming: ICard[];
  recent: ICard[];
}

const Section = ({ type, children }: ISectionData) => {
  return (
    <>
      <Mobile>
        <div className={bem("section")}>
          <div className={bem("section-header")}>
            <div className={bem("section-header-tittle")}>
              {emptySectionData[type].title}
            </div>
            <Button
              color={COLORS.PRIMARY}
              variant={VARIANTS.DEFAULT}
              size="sm"
              text="View all"
            />
          </div>
          <div
            className={joinClassNames(
              bem("section-content"),
              "hide-scroll-bar"
            )}
          >
            {children}
          </div>
        </div>
      </Mobile>
      <Desktop>
        <Slider
          title={emptySectionData[type].title}
          scrollConfig={{ distance: 300, speed: 7 }}
          className={bem("slider")}
        >
          {children}
        </Slider>
      </Desktop>
    </>
  );
};

const EmptySection = ({ type }: IEmptySectionData) => {
  return (
    <div className={bem("empty-section")}>
      <div className={bem("empty-section-icon")}>
        <SvgIcon name={emptySectionData[type].icon} />
      </div>
      <div>
        <div className={bem("empty-section-title")}>
          {emptySectionData[type].title}
        </div>
        <div className={bem("empty-section-caption")}>
          {emptySectionData[type].caption}
        </div>
      </div>
    </div>
  );
};

const Me = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { reset } = useHeader();
  const { resetEverything: resetCart } = useCart();
  const [selectedAppointmentCard, setSelectedAppointmentCard] = useState<
    ICard | undefined
  >();
  const [selectedAppointment, setSelectedAppointment] = useState<
    BookedAppointmentFragment | undefined
  >();
  const selectedAppointmentPeopleCount = usePeopleCount(
    selectedAppointment as TAppointment
  );
  const { data: favoriteArtistsData, loading: favoriteArtistsLoading } =
    useFavoriteArtistsQuery({
      variables: {
        where: {
          user_id: {
            equals: user.id
          }
        }
      },
      skip: !user.id
    });
  const {
    data: favoriteLocationsData,
    loading: favoriteLocationsLoading,
    refetch: refetchFavoriteLocations
  } = useFavoriteLocationsQuery({
    variables: {
      where: {
        user_id: {
          equals: user.id
        }
      }
    },
    skip: !user.id
  });
  const {
    data: appointmentsData,
    loading: appointmentsLoading,
    refetch: refetchAppointments
  } = useAppointmentsQuery({
    variables: {
      where: {
        user_id: {
          equals: user.id
        }
      }
    },
    skip: !user.id
  });
  const favoriteArtists = favoriteArtistsData?.favoriteArtists || [];
  const favoriteLocations = favoriteLocationsData?.userFavoriteLocations || [];

  useEffect(() => {
    refetchAppointments();
  }, [refetchAppointments]);

  useEffect(() => {
    refetchFavoriteLocations();
  }, [favoriteLocationsData?.userFavoriteLocations, refetchFavoriteLocations]);

  useEffect(() => {
    reset();
    resetCart();
    clearCache();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appointments = useMemo<IAppointments>(
    () => ({
      upcoming:
        appointmentsData?.appointments
          .filter(
            (appointment) =>
              getZonedDate(
                appointment.start_time,
                appointment.location.timezone
              ).getTime() >
                getZonedDate(
                  new Date().toISOString(),
                  appointment.location.timezone
                ).getTime() && !appointment.cancelled_at
          )
          .map((appointment) => ({
            id: appointment.id,
            preview: appointment.location.cover_photo_url || "",
            title: appointment.location.name,
            address: appointment.location.address_display || "",
            slots: [
              {
                time: getRelative(
                  appointment.start_time,
                  "eee d MMM",
                  "hh:mm a",
                  appointment.location.timezone
                ),
                available: true
              }
            ],
            peopleCount: appointment.appointment_services
              .map((service) => service.person_number)
              .reduce(
                (peopleCount: number, currentServicePeopleCount) =>
                  currentServicePeopleCount + 1 > peopleCount
                    ? currentServicePeopleCount + 1
                    : peopleCount,
                1
              ),
            servicesCount: appointment.appointment_services.length,
            slotType: "primary"
          })) || [],
      recent:
        appointmentsData?.appointments
          .filter(
            (appointment) =>
              getZonedDate(
                appointment.start_time,
                appointment.location.timezone
              ).getTime() <=
                getZonedDate(
                  new Date().toISOString(),
                  appointment.location.timezone
                ).getTime() || appointment.cancelled_at
          )
          .map((appointment) => ({
            id: appointment.id,
            preview: appointment.location.cover_photo_url || "",
            title: appointment.location.name,
            address: appointment.location.address_display || "",
            slots: [
              {
                time: getRelative(
                  appointment.start_time,
                  "eee d MMM",
                  "hh:mm a",
                  appointment.location.timezone
                ),
                available: true
              }
            ],
            peopleCount: appointment.appointment_services
              .map((service) => service.person_number)
              .reduce(
                (peopleCount: number, currentServicePeopleCount) =>
                  currentServicePeopleCount + 1 > peopleCount
                    ? currentServicePeopleCount + 1
                    : peopleCount,
                1
              ),
            servicesCount: appointment.appointment_services.length,
            slotType: "low",
            cancelled: !!appointment.cancelled_at,
            startTime: appointment.start_time
          })) || []
    }),
    [appointmentsData?.appointments]
  );

  if (
    !user ||
    Object.keys(user).length === 0 ||
    favoriteArtistsLoading ||
    favoriteLocationsLoading ||
    appointmentsLoading
  ) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Pointment | {user.first_name}</title>
        <meta property="og:title" content={`Pointment | ${user.first_name}`} />
      </Helmet>
      <div className={baseClassName}>
        <div className={bem("user")}>
          <Avatar
            src={user.photo_url || ""}
            monogram={user.first_name || ""}
            size="xl"
          />
          <div className={bem("user-info")}>
            <div className={bem("user-info-name")}>
              Hello, {user.first_name}.
            </div>
            <div className={bem("user-info-upcoming-pointments")}>
              <div>Upcoming Pointments</div>
              <Badge
                color={
                  appointments.upcoming.length > 0
                    ? COLORS.PRIMARY
                    : COLORS.SECONDARY
                }
                variant={
                  appointments.upcoming.length > 0
                    ? VARIANTS.FILLED
                    : VARIANTS.OUTLINED
                }
                value={appointments.upcoming.length.toString()}
              />
            </div>
          </div>
          {appointments.upcoming.length > 0 ? (
            <>
              <div
                className={joinClassNames(
                  bem("user-info-upcoming-pointments-cards"),
                  "hide-scroll-bar"
                )}
                data-cy="upcoming-appointments"
              >
                {appointments.upcoming.map((appointment: ICard, i: number) => (
                  <div key={i}>
                    <Card
                      value={appointment}
                      onClick={() => setSelectedAppointmentCard(appointment)}
                      className={bem("user-info-upcoming-pointments-card")}
                    />
                  </div>
                ))}
              </div>
              <Button
                className={bem(
                  joinClassNames("user-info-book-button", "flexible")
                )}
                color={COLORS.SECONDARY}
                variant={VARIANTS.FILLED}
                iconLeftName="search"
                text="Book a Pointment"
                onClick={() => navigate(APP_ROUTES.HOME)}
              />
            </>
          ) : (
            <>
              <Desktop>
                <Button
                  color={COLORS.SECONDARY}
                  variant={VARIANTS.FILLED}
                  iconLeftName="search"
                  text="Book a Pointment"
                  onClick={() => navigate(APP_ROUTES.HOME)}
                />
              </Desktop>
              <Mobile>
                <Button
                  color={COLORS.PRIMARY}
                  variant={VARIANTS.FILLED}
                  text="Book a Pointment"
                  onClick={() => navigate(APP_ROUTES.HOME)}
                />
              </Mobile>
            </>
          )}
        </div>
        <div className={bem("sections-wrapper")}>
          <div className={bem("sections")}>
            <>
              {favoriteArtists?.length > 0 ? (
                <Section type="artists">
                  {favoriteArtists.map(
                    (favoriteArtist: FavoriteArtistFragment) => (
                      <Link
                        to={`/artists/${favoriteArtist.artist.id}`}
                        key={favoriteArtist.id}
                      >
                        <ArtistPreview
                          value={favoriteArtist.artist as TArtist}
                        />
                      </Link>
                    )
                  )}
                </Section>
              ) : (
                <EmptySection type="artists" />
              )}
            </>
            <>
              {favoriteLocations?.length > 0 ? (
                <Section type="locations">
                  {favoriteLocations.map(
                    (favoriteLocation: FavoriteLocationFragment) => (
                      <Link
                        to={`/company/${favoriteLocation.location.company_id}/location/${favoriteLocation.location.slug}`}
                        key={favoriteLocation.id}
                      >
                        <div className={bem("location")}>
                          <LocationPreview
                            value={favoriteLocation.location as TLocation}
                            aspectRatio="32:19"
                          />
                        </div>
                      </Link>
                    )
                  )}
                </Section>
              ) : (
                <EmptySection type="locations" />
              )}
            </>
            <>
              {appointments.recent.length > 0 ? (
                <Section type="appointments">
                  {appointments.recent
                    .slice()
                    ?.sort((a: ICard, b: ICard) => {
                      if (b?.startTime && a?.startTime) {
                        return (
                          +new Date(b?.startTime) - +new Date(a?.startTime)
                        );
                      } else {
                        return 0;
                      }
                    })
                    .map((appointment: ICard, i: number) => (
                      <div key={i}>
                        <Card
                          value={appointment}
                          onClick={() =>
                            navigate(`/me/booking/${appointment?.id}`)
                          }
                          className={bem(
                            `user-info-recent-pointments-card${
                              appointment.cancelled ? "-cancelled" : ""
                            }`
                          )}
                        />
                      </div>
                    ))}
                </Section>
              ) : (
                <EmptySection type="appointments" />
              )}
            </>
          </div>
        </div>
      </div>
      <div className={`${baseClassName}-book-button-wrapper`}>
        <Button
          color={COLORS.SECONDARY}
          variant={VARIANTS.FILLED}
          iconLeftName="search"
          text="Book a Pointment"
          className="flexible w-100%"
          onClick={() => navigate(APP_ROUTES.HOME)}
        />
      </div>
      <BookingConfirmationModal
        show={!!selectedAppointmentCard}
        onHide={() => setSelectedAppointmentCard(undefined)}
        onAddToCalendarClick={() => {
          setSelectedAppointment(
            appointmentsData?.appointments.find(
              (a) => a.id === selectedAppointmentCard?.id
            )
          );
          setSelectedAppointmentCard(undefined);
        }}
        appointment={selectedAppointmentCard}
      />
      {selectedAppointment && (
        // TODO: add timezone
        <AddToCalendarModal
          show={!!selectedAppointment}
          onHide={() => setSelectedAppointment(undefined)}
          event={{
            name: `Upcoming Pointment in ${selectedAppointment?.location.name}`,
            location:
              selectedAppointment?.location.address_display ||
              `${selectedAppointment?.location?.address_latitude}, ${selectedAppointment?.location?.address_longitude}`,
            description: `${selectedAppointmentPeopleCount} ${
              selectedAppointmentPeopleCount > 1 ? "people" : "person"
            } • ${selectedAppointment?.appointment_services?.length} service${
              selectedAppointment?.appointment_services &&
              selectedAppointment?.appointment_services?.length > 1
                ? "s"
                : ""
            }`,
            startDate: getDate(
              selectedAppointment.start_time,
              selectedAppointment.location.timezone
            ),
            endDate: getDate(
              selectedAppointment.end_time,
              selectedAppointment.location.timezone
            ),
            startTime: getFormattedTime(
              selectedAppointment?.start_time,
              "HH:mm",
              selectedAppointment.location.timezone
            ),
            endTime: getFormattedTime(
              selectedAppointment?.end_time,
              "HH:mm",
              selectedAppointment.location.timezone
            ),
            timeZone: selectedAppointment.location.timezone || "currentBrowser"
          }}
        />
      )}
    </>
  );
};

export default Me;

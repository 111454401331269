import { useUser } from "providers/user";
import { useEffect, useMemo } from "react";
import * as Axios from "providers/axios";
import {
  StripePaymentMethodFragment,
  useDeletePaymentMethodMutation,
  usePaymentMethodsQuery,
  useUpdatePaymentMethodMutation
} from "./use-payment/PaymentMethodsSection.generated";
import { SortOrder } from "types.generated";

export interface IPaymentMethodBody {
  id: string;
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
}
export interface IPaymentMethodRequestBody {
  paymentMethod: IPaymentMethodBody;
  is_default?: boolean;
}

/**
 * Communicates with the backend to get/add/update/remove credit card
 */
export const usePayment = (): any => {
  const { user } = useUser();
  const { api } = Axios.useAxios();
  const { data, refetch } = usePaymentMethodsQuery({
    variables: {
      where: {
        user_id: {
          equals: user?.impersonate_id || user?.id
        }
      },
      orderBy: [
        {
          created_at: SortOrder.Asc
        }
      ]
    },
    skip: !user?.id
  });
  const [deletePaymentMethod] = useDeletePaymentMethodMutation();
  const [updatePaymentMethod] = useUpdatePaymentMethodMutation();
  const cards: StripePaymentMethodFragment[] = useMemo(() => {
    if (data && data.stripePaymentMethods) {
      return data?.stripePaymentMethods.map((method: any) => ({
        ...method,
        object_json:
          typeof method.object_json === "string"
            ? JSON.parse(method.object_json)
            : method.object_json
      }));
    }

    return [];
  }, [data]);

  const addCard = async (card: IPaymentMethodRequestBody) => {
    try {
      if (!card) {
        throw new Error("No card data provided");
      }

      if (!user) {
        throw new Error("No user found");
      }

      const paymentMethod = await api.post("/v1/payment-methods", {
        paymentMethod: card.paymentMethod,
        is_default: card.is_default,
        user_id: user.impersonate_id || user.id
      });

      if (!paymentMethod.data) {
        throw new Error("Error creating payment method");
      }

      refetch();
      return paymentMethod;
    } catch (e) {
      return Promise.reject(e.response?.data?.message || e);
    }
  };

  const deleteCard = async (id: string) => {
    const card = cards.find((card: StripePaymentMethodFragment) => {
      return card.id === id;
    });
    await deletePaymentMethod({
      variables: {
        where: { id }
      }
    });

    // If the deleted card was the default, set a new default card
    if (card?.is_default) {
      const remainingCards = cards.filter((c: StripePaymentMethodFragment) => {
        return c.id !== id;
      });
      if (remainingCards.length > 0) {
        const newDefaultCard = remainingCards[0];
        await updatePaymentMethod({
          variables: {
            where: { id: newDefaultCard.id },
            data: {
              is_default: { set: true }
            }
          }
        });
      }
    }

    await refetch();
  };

  const changeDefaultPaymentMethod = (card: StripePaymentMethodFragment) => {
    const defaultCard = cards.find(
      (card: StripePaymentMethodFragment) => card.is_default
    );
    if (defaultCard && defaultCard.id !== card.id) {
      const update1 = updatePaymentMethod({
        variables: {
          where: {
            id: defaultCard.id
          },
          data: {
            is_default: {
              set: false
            }
          }
        }
      });
      const update2 = updatePaymentMethod({
        variables: {
          where: {
            id: card.id
          },
          data: {
            is_default: {
              set: true
            }
          }
        }
      });
      Promise.all([update1, update2]).then(() => refetch());
    }
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    cards,
    addCard,
    deleteCard,
    changeDefaultPaymentMethod
  };
};

import { useEffect, useRef, memo } from "react";
import { joinClassNames } from "utils/join-class-names";

export interface ISvgIconProps {
  name: string;
  className?: string;
}

const getSize = (name: string): string => {
  switch (name) {
    case "logo_light":
    case "logo_light_colored":
    case "logo_dark":
    case "logo_colorized":
      return "w-168px h-40px";
    case "logo_colorized_mobile":
      return "w-[134px] h-[32px]";
    case "logo_symbol_dark":
    case "logo_symbol_light_colored":
    case "logo_symbol_light":
      return "w-21px h-30px";
    case "other_pin":
      return "w-24px h-30px";
    case "filled_star":
      return "w-20px h-19px";
    case "ico_pointment":
      return "w-56px h-56px";
    default:
      return "w-24px h-24px";
  }
};

function SvgIconElement({ name, className = "" }: ISvgIconProps): JSX.Element {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (svgRef.current) {
      const useElement = svgRef.current.querySelector("use");
      const href =
        useElement?.getAttribute("xlink:href") ||
        useElement?.getAttribute("href");
      const symbol = document.querySelector(
        `symbol[id="${href?.substring(1)}"]`
      );
      if (symbol && (name === "delete" || name === "edit")) {
        symbol.removeAttribute("fill");
      }
    }
  }, [name]);

  const href = `#${name}`;

  return (
    <svg ref={svgRef} className={joinClassNames(getSize(name), className)}>
      <use href={href} />
    </svg>
  );
}

export const SvgIcon = memo(SvgIconElement);

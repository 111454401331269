/* eslint-disable max-len */
import { PAYMENT_TYPES } from "../models/payment-types";

export const getRefundAndCancellationHtml = (
  late_cancellation_hours?: number,
  late_cancellation_charge?: number,
  no_show_charge?: number,
  payment_type?: unknown | PAYMENT_TYPES
) => {
  if (
    late_cancellation_hours === undefined ||
    late_cancellation_charge === undefined ||
    no_show_charge === undefined
  ) {
    return "";
  }

  switch (payment_type) {
    case PAYMENT_TYPES.PAY_FULL_UPFRONT:
      return `
      <h4 class="font-optima text-md text-on-background mb-8px">Full Prepay</h4>
      <p class="font-inter text-body-sm text-on-surface-variant">
        Take note! This salon requires full payment of the service total at the time of booking. Your card will be charged shortly. Change of plans? Just cancel or reschedule your Pointment at least ${late_cancellation_hours} hours before the start time in order to keep your salon credit. Failure to cancel within the allotted time frame will result in a loss of salon credit. 
      </p>`;
    case PAYMENT_TYPES.PARTIAL_PAY_UPFRONT:
      return `
      <h4 class="font-optima text-md text-on-background mb-8px">Partial Prepay / Deposit</h4>
      <p class="font-inter text-body-sm text-on-surface-variant">
        Take note! This salon requires a deposit of ${
          // late_cancellation_charge * 100
          parseFloat((late_cancellation_charge * 100).toFixed(2))
        }% of the service total at the time of booking. Your card will be charged shortly. Change of plans? Just cancel or reschedule your Pointment at least ${late_cancellation_hours} hours prior to the start time in order to get your money back or earn salon credit. Failure to cancel within the allotted time frame will result in a loss of the full deposit. You will be charged for the remainder of your booked service(s), as well as any a la carte add-ons, upon completion of your Pointment. 
      </p>`;
    case PAYMENT_TYPES.PAY_IN_STORE:
      return `
      <h3 class="font-optima text-md text-on-background mb-8px">Pay in Store Option </h3>
      <p class="font-inter text-body-sm text-on-surface-variant">
        Change of plans? Be sure to cancel your Pointment at least ${late_cancellation_hours} hours before the start time in order to avoid a cancellation fee. According to this salon's policy, late cancellations will be charged ${parseFloat(
        (late_cancellation_charge * 100).toFixed(2)
      )}% of the service total, and no shows will be charged ${parseFloat(
        (no_show_charge * 100).toFixed(2)
      )}%. 
      </p>`;
    default:
      return "";
  }
};

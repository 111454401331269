import { useState } from "react";
import { ValidateFn, validateValues } from "validators";

export interface IForm {
  values: { [k: string]: unknown };
  errors: Record<string, string>;
  hasErrors: boolean;
  submitted: boolean;
  valid: boolean;
  touched: Record<string, boolean>;
  isTouched: boolean;
  changeValue(propValue: unknown, propName: string): void;
  updateValues(props: { [k: string]: unknown }): void;
  touchAndChangeProp(propValue: unknown, propName: string): void;
  setTouched(propName: string, isTouched?: boolean): void;
  setSubmitted(submitted: boolean): void;
  validate(): void;
  resetTouched(): void;
  getPropError(propName: string): string | null;
  reset(): void;
}

const valid = (errors: Record<string, string>): boolean => {
  for (const _ in errors) return false;
  return true;
};

export function useForm(
  initialData: { [k: string]: unknown },
  validators?: Record<string, ValidateFn[]>
): IForm {
  const [values, setValues] = useState<{ [k: string]: unknown }>(initialData);
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(() => {
    // validate values on initial render
    return validators ? validateValues(values, validators) : {};
  });
  const isTouched = Object.keys(touched).length > 0;
  const hasErrors = Object.keys(errors).length > 0;

  const changeValue = (propValue: unknown, propName: string): void => {
    if (!propName) {
      // eslint-disable-next-line no-console
      console.error("Cannot update value of unknown property");
      return;
    }

    updateValues({ [propName]: propValue });
  };

  const updateValues = (props: { [k: string]: unknown }): void => {
    const newValues = { ...values, ...props };
    setValues(newValues);

    if (validators) {
      setErrors(validateValues(newValues, validators));
    }
  };

  const validate = (): void => {
    if (validators) {
      setErrors(validateValues(values, validators));
    }
  };

  const setTouchedProp = (propName: string, isPropTouched = true): void => {
    if (touched[propName] !== isPropTouched) {
      setTouched({ ...touched, [propName]: isPropTouched });
    }
  };

  const touchAndChangeProp = (propValue: unknown, propName: string): void => {
    changeValue(propValue, propName);
    setTouchedProp(propName);
  };

  const resetTouched = (): void => {
    setTouched({});
  };

  const getPropError = (propName: string): string | null =>
    errors[propName] || null;

  const reset = (): void => {
    setValues(initialData);
    setTouched({});
    setSubmitted(false);
    setErrors(() => (validators ? validateValues(values, validators) : {}));
  };

  return {
    values,
    errors,
    hasErrors,
    submitted,
    valid: valid(errors),
    touched,
    isTouched,
    changeValue,
    updateValues,
    touchAndChangeProp,
    setTouched: setTouchedProp,
    setSubmitted,
    validate,
    resetTouched,
    getPropError,
    reset
  };
}

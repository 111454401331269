import "./LocationGoogleMap.scss";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { LocationPageLocationFragment } from "routes/location/Location.generated";
import { joinClassNames } from "utils/join-class-names";

interface LocationGoogleMapProps {
  location: LocationPageLocationFragment;
  className?: string;
}

export const LocationGoogleMap = ({
  location,
  className = ""
}: LocationGoogleMapProps): JSX.Element | null => {
  if (
    location?.address_latitude &&
    location?.address_longitude &&
    (window as any).google &&
    google.maps
  ) {
    return (
      <div className={joinClassNames("location-google-map", className)}>
        <GoogleMap
          zoom={13}
          center={{
            lat: location.address_latitude,
            lng: location.address_longitude
          }}
          options={{ fullscreenControl: false }}
        >
          <Marker
            position={{
              lat: location.address_latitude,
              lng: location.address_longitude
            }}
          />
        </GoogleMap>
      </div>
    );
  } else {
    return null;
  }
};

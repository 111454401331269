import "./LocationMainData.scss";
import { joinClassNames } from "utils/join-class-names";
import { bemElement } from "utils/bem-class-names";
import { LocationPageLocationFragment } from "routes/location/Location.generated";

interface LocationMainDataProps {
  location: LocationPageLocationFragment;
  distance: number | null;
  className?: string;
  showTime?: boolean;
  locationWorkingHoursComponent?: JSX.Element;
}

const baseClassName = "location-main-data";
const bem = bemElement(baseClassName);

export const LocationMainData = ({
  location,
  distance,
  className = "",
  showTime = true,
  locationWorkingHoursComponent
}: LocationMainDataProps): JSX.Element => {
  return (
    <div className={joinClassNames(baseClassName, className)}>
      <h3 className={bem("title")}>{location.name}</h3>
      <address className={bem("address")}>{location.address_display}</address>
      {locationWorkingHoursComponent}
    </div>
  );
};

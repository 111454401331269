import * as libphoneNumber from "google-libphonenumber";

const phoneUtil = libphoneNumber.PhoneNumberUtil.getInstance();

export const getRawPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return null;
  }

  try {
    const number = phoneUtil.format(
      phoneUtil.parse(phoneNumber, "US"),
      libphoneNumber.PhoneNumberFormat.E164
    );

    return number;
  } catch (error) {
    // @the-real-adammork: I'm not sure if this is the best way to handle this error
    throw new Error("Invalid phone number");
  }
};

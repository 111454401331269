import "./Image.scss";
import { memo, MutableRefObject, useEffect, useRef, useState } from "react";
import { useHeight } from "hooks/use-height";
import { bemElement, bemModifier } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";

export interface IImageProps {
  alt: string;
  aspectRatio: string;
  src: string;
  className?: string;
  onError?: () => void;
}

const baseClassName = "image-element";
const bem = bemElement(baseClassName);
const clearImgRef = (
  imgRef: MutableRefObject<HTMLImageElement | null>
): void => {
  if (imgRef.current) {
    imgRef.current.src = "";
    imgRef.current = null;
  }
};

const CustomImageElement = ({
  alt,
  aspectRatio,
  src,
  className = "",
  onError
}: IImageProps): JSX.Element => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const imgElement = useRef<HTMLImageElement | null>(null);
  const paddingBottom = useHeight(aspectRatio);

  useEffect(() => {
    imgElement.current = null;
    setLoading(true);
    setImgSrc(undefined);
    clearImgRef(imgElement);

    imgElement.current = new Image();
    imgElement.current.src = src;
    imgElement.current.onload = (): void => {
      setImgSrc(src);
      setLoading(false);
    };
    imgElement.current.onerror = (): void => {
      if (onError) {
        onError();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  useEffect(() => {
    return (): void => {
      clearImgRef(imgElement);
    };
  }, []);

  return (
    <div
      style={{ paddingBottom }}
      className={joinClassNames(
        bemModifier(baseClassName, { loading: loading }),
        className
      )}
    >
      <div className={bem("container")}>
        {imgSrc && <img src={imgSrc} alt={alt} />}
      </div>
    </div>
  );
};

export const ImageElement = memo(CustomImageElement);

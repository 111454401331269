import { Input, TInputType } from "elements/input/Input";
import { COLORS } from "models/colors";
import { bemElement } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";
import { CheckboxFormElement } from "./form-elements/CheckboxFormElement";
import Select, { IOptionData } from "../../elements/select/Select";
import { CardInputFormElement } from "./form-elements/CardInputFormElement";
import PhoneInput from "../phone-input/PhoneInput";

export interface IFormElementProps {
  [key: string]: unknown;
  label?: string;
  cyId?: string;
  errorMsg?: string;
  checked?: boolean;
  color?: COLORS;
  type?: TInputType;
  placeholder?: string;
  value?: string | number;
  onChange?: (value: unknown, formName?: string) => void;
  onBlur?: (_formValueName?: string) => void;
  options?: IOptionData[];
  className?: string;
}

export type TFormElementType =
  | "Input"
  | "Checkbox"
  | "Select"
  | "CardInput"
  | "PhoneInput";

export interface IFormElement {
  type: TFormElementType;
  name: string;
  props: IFormElementProps;
}

export interface IFormBuilderProps {
  elements: IFormElement[];
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  children?: React.ReactNode;
  className?: string;
}

const baseClassName = "form-builder";
const bem = bemElement(baseClassName);

const getFormElement = (element: IFormElement): JSX.Element => {
  switch (element.type) {
    case "Input":
      return <Input {...element.props} />;
    case "Checkbox":
      return <CheckboxFormElement element={element} />;
    case "Select":
      return <Select {...element.props} />;
    case "CardInput":
      return <CardInputFormElement elementProps={element.props} />;
    case "PhoneInput":
      return (
        <PhoneInput
          {...element.props}
          errorMsg={element.props.errorMsg}
          cyId={element.props.cyId}
          onChange={element.props.onChange}
          initialValue={element.props.value as string}
          onBlur={element.props.onBlur}
        />
      );
    default:
      return <></>;
  }
};

export const FormBuilder = ({
  elements,
  onSubmit,
  children,
  className = ""
}: IFormBuilderProps): JSX.Element => {
  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onSubmit && onSubmit(e);
  };

  return (
    <form
      className={joinClassNames(baseClassName, className)}
      onSubmit={onSubmitHandler}
    >
      {elements.map((element: IFormElement, i: number) => (
        <div key={element.name + i}>{getFormElement(element)}</div>
      ))}
      <div className={bem("controls")}>{children}</div>
    </form>
  );
};

import { useEffect, useState } from "react";

const usePreventBrowserNavigationOnScroll = (
  ref: React.RefObject<HTMLElement>
) => {
  const [isRefLoaded, setIsRefLoaded] = useState<boolean>(false);
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (event.deltaX !== 0 && ref.current) {
        const maxScrollLeft = ref.current.scrollWidth - ref.current.clientWidth;
        if (
          ref.current.scrollLeft + event.deltaX < 0 ||
          ref.current.scrollLeft + event.deltaX > maxScrollLeft
        ) {
          event.stopPropagation();
          event.preventDefault();
        }
      }
    };

    ref?.current?.addEventListener("wheel", handleWheel);

    const removeListener = () => {
      ref?.current?.removeEventListener("wheel", handleWheel);
    };

    setTimeout(() => {
      setIsRefLoaded((prev) => !prev);
    }, 500);

    return removeListener;
  }, [ref, isRefLoaded]);
};

export default usePreventBrowserNavigationOnScroll;

import "./BookingConfirmationModal.scss";
import React, { useEffect, useState } from "react";
import Modal from "../modal/Modal";
import { Card, ICard } from "../../card/Card";
import { SvgIcon } from "../../../elements/svg-icon/svg-icon";
import { bemElement } from "../../../utils/bem-class-names";
import { joinClassNames } from "../../../utils/join-class-names";
import { WavyLine } from "../../../elements/wavy-line/wavy-line";
import { Button } from "../../../elements/button/Button";
import { COLORS } from "../../../models/colors";
import { VARIANTS } from "../../../models/variants";
import useResponsive from "../../../hooks/useResponsive";
import { useAxios } from "../../../providers/axios";
import { useUser } from "../../../providers/user";
import { useNavigate } from "react-router-dom";

const baseClassName = "booking-confirmation-modal";
const bem = bemElement(baseClassName);

export interface IBookingConfirmationModalData {
  show: boolean;
  onHide: () => void;
  appointment: ICard | undefined;
  onAddToCalendarClick?: () => void;
  onViewDetailsClick?: () => void;
  className?: string;
}

const BookingConfirmationModal = ({
  show,
  onHide,
  appointment,
  onAddToCalendarClick,
  onViewDetailsClick,
  className = ""
}: IBookingConfirmationModalData) => {
  const navigate = useNavigate();
  const { api } = useAxios();
  const { user } = useUser();
  const { isMobile } = useResponsive();
  const [_show, setShow] = useState<boolean>(show);
  const [_appointment, setAppointment] = useState<ICard>();

  useEffect(() => {
    if (!appointment) {
      setShow(false);
    } else {
      setShow(show);
      setAppointment(appointment);
    }
  }, [show, appointment]);

  if (!_appointment) {
    return null;
  }

  const onLogout = async () => {
    try {
      await api.post("v1/users/impersonate-logout");

      const adminUrl = process.env.REACT_APP_ADMIN_URL;
      window.location.href = `${adminUrl}/dashboard/Appointment/${appointment?.id}/show`;
    } catch (error) {
      throw new Error("Error logging out: " + error.message);
    }
  };

  const onViewDetails = () => {
    onViewDetailsClick && onViewDetailsClick();
    if (user?.impersonate_name) {
      onLogout();
    } else {
      navigate(`/me/booking/${appointment?.id}`);
    }
  };

  const BottomControls = () => {
    return (
      <div className={bem("bottom-controls")}>
        <WavyLine />
        <div className={bem("bottom-controls-buttons")}>
          <Button
            color={COLORS.PRIMARY}
            variant={VARIANTS.DEFAULT}
            text="View details"
            iconRightName="arrow_right"
            cyId="view-details-button"
            onClick={onViewDetails}
            className="flexible w-100%"
          />
          {/*TODO: implement this*/}
          <Button
            color={COLORS.SECONDARY}
            variant={VARIANTS.FILLED}
            text="Add to calendar"
            onClick={onAddToCalendarClick}
            className="flexible w-100%"
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={_show}
      onHide={onHide}
      title={isMobile ? "Confirmation" : undefined}
      BottomControls={BottomControls}
      className={joinClassNames(baseClassName, className)}
    >
      <div className={bem("wrapper")}>
        <div className={bem("checkmark")}>
          <SvgIcon name="checkmark" className={bem("checkmark-icon")} />
        </div>
        <div className={bem("text")}>Pointment booked</div>
        <Card value={_appointment} className="w-100%" onClick={onViewDetails} />
      </div>
    </Modal>
  );
};

export default BookingConfirmationModal;

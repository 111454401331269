import { useEffect, useRef, useState } from "react";

interface IUseTimeoutHandler {
  start: () => void;
  clear: () => void;
  stop: () => void;
  isActive: boolean;
}

/* eslint-disable react-hooks/exhaustive-deps */
export const useTimeout = (cb: () => void, delay = 0): IUseTimeoutHandler => {
  const [isActive, setActive] = useState(false);
  const callbackRef = useRef<() => unknown>();

  useEffect(() => {
    callbackRef.current = cb;
  }, [cb]);

  function callback(): void {
    callbackRef.current && callbackRef.current();
    clear();
  }

  function clear(): void {
    setActive(false);
  }
  function start(): void {
    setActive(true);
  }

  useEffect(() => {
    if (isActive) {
      const timeout = window.setTimeout(callback, delay);

      return () => window.clearTimeout(timeout);
    }
  }, [isActive]);

  return {
    clear,
    start,
    stop: clear,
    isActive: isActive
  };
};

/* eslint-disable max-len */
import ToastError from "./ToastError";

interface AuthLayoutProps {
  children: JSX.Element;
  title?: string;
  error?: string;
  cyId?: string;
  setError?: (e: string) => void;
}

const AuthLayout = ({
  children,
  title,
  error,
  setError,
  cyId
}: AuthLayoutProps) => {
  return (
    <div
      className="flex w-full justify-center mt-8 pb-20 flex-1"
      data-testid="login-container"
    >
      <div
        className="relative flex flex-col self-baseline lg:w-[440px] sm:w-[440px] lg:p-12 sm:p-12 w-full lg:bg-[#F6F4F1] sm:bg-[#F6F4F1] bg-mono-tertiary
            rounded-2xl p-4 lg:shadow-[0px_1px_2px_rgba(48,47,46,0.3),_0px_2px_6px_2px_rgba(48,47,46,0.15)]
            sm:shadow-[0px_1px_2px_rgba(48,47,46,0.3),_0px_2px_6px_2px_rgba(48,47,46,0.15)] shadow-none"
      >
        {error && setError && (
          <ToastError
            error={error}
            cyId={`${cyId}-error`}
            setError={setError}
          />
        )}
        {title && (
          <h2 className="mb-6 lg:text-2xl text-xl font-medium font-['Optima']">
            {title}
          </h2>
        )}
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;

import "./Menu.scss";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ListItem } from "../../list-item/ListItem";
import { SvgIcon } from "../../../elements/svg-icon/svg-icon";
// import { joinClassNames } from "../../../utils/join-class-names";
import { bemElement, bemModifier } from "../../../utils/bem-class-names";
import AccountDetailsPageSections from "../AccountDetailsPageSections";
import { Desktop, Mobile } from "../../responsive/Responsive";

const baseClassName = "menu";
const bem = bemElement(baseClassName);

export const menu = [
  {
    label: "Edit account",
    icon: "edit",
    cyId: "edit-account-button",
    section: AccountDetailsPageSections.EDIT_ACCOUNT
  },
  {
    label: "Notifications",
    icon: "notifications",
    section: AccountDetailsPageSections.NOTIFICATIONS
  },
  {
    label: "Payment methods",
    icon: "payment",
    cyId: "payment-methods-button",
    section: AccountDetailsPageSections.PAYMENT_METHODS
  },
  {
    label: "Credits",
    icon: "credits",
    cyId: "credits-button",
    section: AccountDetailsPageSections.CREDITS
  },
  {
    label: "Delete account",
    icon: "delete",
    section: AccountDetailsPageSections.DELETE_ACCOUNT
  }
];

const Menu = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();

  return (
    <div className={baseClassName}>
      <div className={bem("first-section")}>
        {menu.map((item) => (
          <React.Fragment key={item.section}>
            {item.section !== AccountDetailsPageSections.DELETE_ACCOUNT && (
              <>
                <Mobile>
                  <ListItem
                    className={bem("list-item")}
                    cyId={item.cyId}
                    onClick={() => navigate(item.section)}
                  >
                    <div className={bem("item")}>
                      <SvgIcon name={item.icon} />
                      <div>{item.label}</div>
                    </div>
                  </ListItem>
                </Mobile>
                <Desktop>
                  <div
                    aria-hidden
                    className={bemModifier(bem("list-item"), {
                      active: item.section === hash
                    })}
                    onClick={() => navigate(item.section)}
                    data-cy={item.cyId}
                  >
                    <div
                      className={bemModifier(bem("item"), {
                        active: item.section === hash
                      })}
                    >
                      <SvgIcon name={item.icon} />
                      <div>{item.label}</div>
                    </div>
                  </div>
                </Desktop>
              </>
            )}
          </React.Fragment>
        ))}
      </div>
      {/* <div className={bem("second-section")}>
        <Mobile>
          <ListItem
            className={joinClassNames(
              bem("list-item"),
              bem("delete-list-item")
            )}
            onClick={() => navigate(menu[menu.length - 1].section)}
          >
            <div className={joinClassNames(bem("item"), bem("delete-item"))}>
              <SvgIcon name={menu[menu.length - 1].icon} />
              <div>{menu[menu.length - 1].label}</div>
            </div>
          </ListItem>
        </Mobile>
        <Desktop>
          <hr />
          <div
            aria-hidden
            className={joinClassNames(
              bemModifier(bem("list-item"), {
                active: hash === AccountDetailsPageSections.DELETE_ACCOUNT
              }),
              bem("delete-list-item")
            )}
            onClick={() => navigate(menu[menu.length - 1].section)}
          >
            <div className={joinClassNames(bem("item"), bem("delete-item"))}>
              <SvgIcon name={menu[menu.length - 1].icon} />
              <div>{menu[menu.length - 1].label}</div>
            </div>
          </div>
        </Desktop>
      </div> */}
    </div>
  );
};

export default Menu;

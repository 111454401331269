import { AmenityItem } from "./amenityItem/AmenityItem";

interface AmenitiesProps {
  amenitiesData: string[];
  className?: string;
}

export const Amenities = ({
  amenitiesData,
  className = ""
}: AmenitiesProps): JSX.Element | null => {
  return (
    <div className={className}>
      <h4 className="text-[24px] font-[600] font-['Optima'] text-[#302F2E] mt-4 -mb-2">
        Amenities
      </h4>
      <div className="w-full flex gap-2 items-center flex-wrap">
        {amenitiesData?.map((amenity: string, i: number) => (
          <AmenityItem key={i} item={amenity} />
        ))}
      </div>
    </div>
  );
};

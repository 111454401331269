/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHeader } from "providers/header-provider";
import { useNavigate } from "react-router-dom";
import { LoginForm } from "forms/login-form/LoginForm";
import { APP_ROUTES } from "routes";
import { Toast } from "elements/toast/Toast";

const baseClassName = "login-page";

export const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const { reset } = useHeader();
  const [formErrorMessage, setFormErrorMessage] = useState<string>("");

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRedirectToSignUpHandler = () => {
    navigate(APP_ROUTES.SIGN_UP);
  };

  const onLoggedInHandler = () => {
    navigate(APP_ROUTES.HOME);
  };

  const onRedirectToResetPasswordHandler = () => {
    navigate(APP_ROUTES.RESET_PASSWORD);
  };

  return (
    <>
      <Helmet>
        <title>Pointment | Log in</title>
        <meta property="og:title" content="Pointment | Log in" />
      </Helmet>
      <div className={baseClassName}>
        <div className="form-wrapper">
          {formErrorMessage && (
            <Toast
              msg={formErrorMessage}
              cyId="sign-in-error"
              type="error"
              showIcon={true}
              onClose={() => setFormErrorMessage("")}
            />
          )}
          <LoginForm
            className="p-16px md:p-48px"
            onLoggedIn={onLoggedInHandler}
            onRedirectToSignUp={onRedirectToSignUpHandler}
            onRedirectToResetPassword={onRedirectToResetPasswordHandler}
            onErrorMessage={setFormErrorMessage}
          />
        </div>
      </div>
    </>
  );
};

import { SvgIcon } from "elements/svg-icon/svg-icon";
import {
  amenitiesNameHelper,
  amenitiesWithAlternativeIcons
} from "utils/constants";
import "./AmenityItem.scss";

interface IAmenityItemProps {
  item: string;
  className?: string;
}

export const AmenityItem = ({ item }: IAmenityItemProps): JSX.Element => {
  return (
    <div className="amenityItem">
      <SvgIcon
        name={
          item === "Free_Wifi"
            ? "amenities_free_wi_fi"
            : amenitiesWithAlternativeIcons.includes(item)
            ? "amenities_alternate_version"
            : `amenities_${item?.toLocaleLowerCase()}`
        }
      />
      <span className="text-[14px] font-[400] font-['Inter'] text-[#302F2E]">
        {amenitiesNameHelper[item as keyof typeof amenitiesNameHelper]}
      </span>
    </div>
  );
};

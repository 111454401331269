/* eslint-disable max-len */
import * as Axios from "../../../providers/axios";
import { LogoutIcon } from "../../../assets/Icons";
import { useUser } from "../../../providers/user";

const ImpersonateHeader = () => {
  const { api } = Axios.useAxios();
  const { user } = useUser();

  const onLogout = async () => {
    try {
      await api.post("v1/users/impersonate-logout");

      const adminUrl = process.env.REACT_APP_ADMIN_URL;
      window.location.href = `${adminUrl}/dashboard/Customer/${user?.impersonate_id}/show`;
    } catch (error) {
      throw new Error("Error logging out: " + error.message);
    }
  };

  return (
    <div className="fixed bg-[#F8D1C0] w-full left-0 z-[11]">
      <div className="max-w-[1200px] w-full flex mx-auto justify-between items-center px-[24px] py-[8px]">
        <div className="text-[14px] text-[#752D0D] truncate">
          You are logged in as the customer: {user?.impersonate_name}
        </div>
        <div
          className={
            "flex w-[80px] items-center gap-[14px] group cursor-pointer"
          }
          onClick={onLogout}
          aria-hidden
        >
          <LogoutIcon fill="#752D0D" />
          <span
            className="text-[13px] font-normal font-['Inter'] whitespace-nowrap group-hover:!text-mono-black"
            style={{ color: "#752D0D" }}
          >
            Log out
          </span>
        </div>
      </div>
    </div>
  );
};

export default ImpersonateHeader;

import "./Slider.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { smoothScrollX } from "../../utils/smoothScrollX";
import Navigation from "../navigation/Navigation";
import { ISliderData } from "./ISlider";

const defaultNavigationConfig = {
  position: "top-right"
};

const defaultScrollConfig = {
  speed: 15,
  distance: 100,
  step: 10
};

const Slider = ({
  children,
  title,
  offFadeOut,
  navigationConfig,
  scrollConfig,
  className
}: ISliderData) => {
  const ref = useRef<HTMLDivElement>(null);
  const [maxScrollPosition, setMaxScrollPosition] = useState<number>(0);
  const [currentScrollPosition, setCurrentScrollPosition] = useState<number>(0);

  const navigationPosition = useMemo(
    () =>
      navigationConfig?.position
        ? navigationConfig?.position
        : defaultNavigationConfig.position,
    [navigationConfig?.position]
  );

  const displayNavigationTop = useMemo(
    () =>
      !navigationConfig?.hidden &&
      navigationPosition.startsWith("top") &&
      maxScrollPosition !== 0,
    [navigationConfig?.hidden, navigationPosition, maxScrollPosition]
  );
  const displayNavigationBottom = useMemo(
    () =>
      !navigationConfig?.hidden &&
      navigationPosition.startsWith("bottom") &&
      maxScrollPosition !== 0,
    [navigationConfig?.hidden, navigationPosition, maxScrollPosition]
  );

  const displayLeftFadeOut = useMemo(
    () => !offFadeOut && currentScrollPosition > 0,
    [offFadeOut, currentScrollPosition]
  );
  const displayRightFadeOut = useMemo(
    () => !offFadeOut && currentScrollPosition < maxScrollPosition,
    [offFadeOut, currentScrollPosition, maxScrollPosition]
  );

  const leftButtonConfig = useMemo(
    () => ({
      ...navigationConfig?.leftButtonConfig,
      disabled: currentScrollPosition === 0
    }),
    [currentScrollPosition, navigationConfig?.leftButtonConfig]
  );
  const rightButtonConfig = useMemo(
    () => ({
      ...navigationConfig?.rightButtonConfig,
      disabled: currentScrollPosition === maxScrollPosition
    }),
    [
      currentScrollPosition,
      maxScrollPosition,
      navigationConfig?.rightButtonConfig
    ]
  );

  useEffect(() => {
    if (ref.current) {
      setMaxScrollPosition(ref.current.scrollWidth - ref.current.clientWidth);
    } else {
      setMaxScrollPosition(1001);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, children]);

  const scrollX = (direction: "left" | "right") => {
    if (ref.current) {
      const step = scrollConfig?.step
        ? scrollConfig?.step
        : defaultScrollConfig.step;
      smoothScrollX(
        ref.current,
        scrollConfig?.speed ? scrollConfig?.speed : defaultScrollConfig.speed,
        scrollConfig?.distance
          ? scrollConfig?.distance
          : defaultScrollConfig.distance,
        direction === "left" ? -step : step,
        () => {
          if (ref.current) {
            setCurrentScrollPosition(ref.current.scrollLeft);
          }
        }
      );
      if (direction === "left" && leftButtonConfig?.onClick) {
        leftButtonConfig?.onClick();
      }
      if (direction === "right" && rightButtonConfig?.onClick) {
        rightButtonConfig?.onClick();
      }
    }
  };

  return (
    <div className={`slider${className ? " " + className : ""}`}>
      <div className={`slider-header`}>
        {title && <div className="slider-header-title">{title}</div>}
        {displayNavigationTop && (
          <div className={`slider-navigation-${navigationPosition}`}>
            <Navigation
              text={navigationConfig?.text}
              leftButtonConfig={{
                ...leftButtonConfig,
                onClick: () => scrollX("left")
              }}
              rightButtonConfig={{
                ...rightButtonConfig,
                onClick: () => scrollX("right")
              }}
              className={navigationConfig?.className}
            />
          </div>
        )}
      </div>
      <div className="elements-list-wrapper">
        <div className="elements-list" ref={ref}>
          {children}
        </div>
        {displayLeftFadeOut && <div className="fade-out-left" />}
        {displayRightFadeOut && <div className="fade-out-right" />}
      </div>
      {displayNavigationBottom && (
        <div className={`slider-navigation-${navigationPosition}`}>
          <Navigation
            text={navigationConfig?.text}
            leftButtonConfig={{
              ...leftButtonConfig,
              onClick: () => scrollX("left")
            }}
            rightButtonConfig={{
              ...rightButtonConfig,
              onClick: () => scrollX("right")
            }}
            className={
              navigationConfig?.className + " slider-navigation-bottom"
            }
          />
        </div>
      )}
    </div>
  );
};

export default Slider;

/* eslint-disable max-len */
import { Suspense } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  Navigate
} from "react-router-dom";

import { AuthenticateResponse } from "./models/auth";

import Company from "./routes/company/Company";
import { Location } from "./routes/location/Location";
import { Login } from "./routes/login/Login";
import { SignUp } from "routes/sign-up/SignUp";
import { Reservation } from "routes/reservation/Reservation";
import NotFound from "./routes/NotFound";
import ResetPassword from "./routes/reset-password/ResetPassword";
import ResetPasswordVerify from "./routes/reset-password/VerifyResetPassword";
import Homepage from "./routes/homepage/Homepage";
import Artist from "./routes/artist/Artist";
import Verify from "./routes/Verify";
import BookingDetails from "./routes/booking-details/BookingDetails";
import * as User from "./providers/user";
import * as Cognito from "./providers/cognito";
import Me from "./routes/me/Me";
import AccountDetails from "./routes/account-details/AccountDetails";
import { useCart } from "providers/cart-provider";
import { Header } from "components/header/Header";
import { APP_ROUTES, getVerifyUserRoute } from "routes";
import ImpersonateHeader from "./components/header/impersonate-header/ImpersonateHeader";
import TermsAndConditions from "routes/terms-and-conditions/TermsAndConditions";
import PrivacyPolicy from "routes/privacy-policy/PrivacyPolicy";

export default function Router() {
  const { user, loading: loadingUser } = User.useUser();
  const { loading } = Cognito.useCognito();
  const { cart } = useCart();

  const loadingPage = loading || loadingUser;

  return (
    <BrowserRouter>
      {user?.impersonate_name ? <ImpersonateHeader /> : <Header />}
      <main className="max-w-1200px m-auto">
        <Suspense fallback={null}>
          <Routes>
            <Route
              path="/company/:companySlug/location/:locationSlug"
              element={
                <RequireFinishSignUp user={user}>
                  <Location />
                </RequireFinishSignUp>
              }
            />
            <Route path="/location/:locationSlug" element={<Location />} />
            <Route path="/company/:slug" element={<Company />} />
            <Route
              path={APP_ROUTES.RESERVATION}
              element={
                <RequireFinishSignUp user={user}>
                  <RequireCart
                    selectedServicesNumber={cart.services.length}
                    selectedStartTime={cart?.start_time}
                  >
                    <Reservation />
                  </RequireCart>
                </RequireFinishSignUp>
              }
            />
            <Route
              path="/me/booking/:id"
              element={
                <RequireFinishSignUp user={user} loading={loadingPage}>
                  <BookingDetails />
                </RequireFinishSignUp>
              }
            />
            {!user?.impersonate_name && (
              <>
                <Route
                  path={APP_ROUTES.HOME}
                  element={
                    <RequireFinishSignUp user={user}>
                      <Homepage />
                    </RequireFinishSignUp>
                  }
                />
                <Route
                  path={APP_ROUTES.SIGN_UP}
                  element={
                    <NoAuth user={user}>
                      <SignUp />
                    </NoAuth>
                  }
                />
                <Route
                  path={APP_ROUTES.VERIFY_USER}
                  element={
                    <RequireFinishSignUp user={user}>
                      <NoAuth user={user}>
                        <Verify />
                      </NoAuth>
                    </RequireFinishSignUp>
                  }
                />
                <Route
                  path={APP_ROUTES.LOGIN}
                  element={
                    <RequireFinishSignUp user={user}>
                      <NoAuth user={user}>
                        <Login />
                      </NoAuth>
                    </RequireFinishSignUp>
                  }
                />
                <Route
                  path={APP_ROUTES.RESET_PASSWORD}
                  element={
                    <RequireFinishSignUp user={user}>
                      <NoAuth user={user}>
                        <ResetPassword />
                      </NoAuth>
                    </RequireFinishSignUp>
                  }
                />
                <Route path="/verify-reset" element={<ResetPasswordVerify />} />
                <Route path="/artists/:id" element={<Artist />} />
                <Route
                  path={APP_ROUTES.ME}
                  element={
                    <RequireFinishSignUp user={user} loading={loadingPage}>
                      <RequireAuth user={user} loading={loadingPage}>
                        <Me />
                      </RequireAuth>
                    </RequireFinishSignUp>
                  }
                />
                <Route
                  path={APP_ROUTES.ACCOUNT_DETAILS}
                  element={
                    <RequireFinishSignUp user={user} loading={loadingPage}>
                      <AccountDetails />
                    </RequireFinishSignUp>
                  }
                />
              </>
            )}
            <Route path="/toc" element={<TermsAndConditions />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </main>
    </BrowserRouter>
  );
}

interface Props {
  children: JSX.Element;
  user?: (AuthenticateResponse & { isVerified: boolean }) | null;
  selectedServicesNumber?: number;
  loading?: boolean;
  selectedStartTime?: string | undefined;
}

export function RequireAuth(props: Props) {
  const { user, loading } = props;
  const location = useLocation();

  if (loading) {
    return null;
  }

  if (!user?.id) {
    return (
      <Navigate to={APP_ROUTES.LOGIN} state={{ from: location }} replace />
    );
  }

  if (!user?.isVerified) {
    return (
      <Navigate
        to={getVerifyUserRoute(user.email || "")}
        state={{ from: location }}
        replace
      />
    );
  }

  return props.children;
}

export function NoAuth(props: Props) {
  const { user, loading } = props;
  const location = useLocation();

  if (loading) {
    return null;
  }

  if (user?.id && user?.isVerified && user?.first_name) {
    return <Navigate to={APP_ROUTES.HOME} state={{ from: location }} replace />;
  }

  return props.children;
}

export function RequireCart(props: Props) {
  const { selectedServicesNumber, loading, selectedStartTime } = props;
  const location = useLocation();

  if (loading) {
    return null;
  }

  if (!selectedServicesNumber || !selectedStartTime) {
    return <Navigate to={APP_ROUTES.HOME} state={{ from: location }} replace />;
  }

  return props.children;
}

export function RequireFinishSignUp(props: Props) {
  const { user, loading } = props;

  if (loading) {
    return null;
  }

  if (user && !user.first_name) {
    return <Navigate to={APP_ROUTES.SIGN_UP} state={{ user }} replace />;
  }

  return props.children;
}

import "./Card.scss";
import { ImageElement } from "elements/image/Image";
import {
  SuggestionChip,
  TSuggestionChipType
} from "elements/suggestion-chip/Suggestion-chip";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { bemElement } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";

export interface ILocationRating {
  value: string;
  reviewsCount: number;
}

export interface ILocationSlot {
  time: string;
  available: boolean;
  locationId?: string;
}

export interface ICard {
  cancelled?: boolean;
  id?: string;
  preview: string;
  title: string;
  rating?: ILocationRating;
  distance?: number;
  currency?: string;
  peopleCount?: number;
  servicesCount?: number;
  address: string;
  slots: ILocationSlot[];
  slotType?: TSuggestionChipType;
  startTime?: string;
}

export interface ICardProps {
  value: ICard;
  onClick?: () => void;
  className?: string;
}

const baseClassName = "card";
const bem = bemElement(baseClassName);

export const Card = ({
  value,
  onClick,
  className = ""
}: ICardProps): JSX.Element => {
  return (
    <div
      aria-hidden
      className={joinClassNames(baseClassName, className)}
      onClick={onClick}
      data-cy={`card-${value.title.toLowerCase().replace(/\s+/g, "-")}`}
    >
      <div className={bem("left-side")}>
        <ImageElement
          className={bem("card-image")}
          src={value.preview}
          alt={value.title}
          aspectRatio="100:140"
        />
      </div>
      <div className={bem("right-side")}>
        <div className={bem("row")}>
          <div className={bem("title")}>{value.title}</div>
          <address className={bem("address")}>{value.address}</address>
          {value?.peopleCount !== undefined &&
            value.peopleCount > 0 &&
            value?.servicesCount !== undefined &&
            value.servicesCount > 0 && (
              <div className={bem("info")}>
                <SvgIcon
                  name="amenities_good_for_groups"
                  className={bem("group-icon")}
                />
                {value.peopleCount}{" "}
                {value.peopleCount > 1 ? "people" : "person"} •{" "}
                {value.servicesCount} service
                {value.servicesCount > 1 ? "s" : ""}
              </div>
            )}
        </div>
        <div className={bem("row")}>
          <div className={bem("time-slots")}>
            {!value.slots && (
              <SuggestionChip
                className={bem("time-slot")}
                value="Loading..."
                type="low"
              />
            )}
            {value.slots?.map((slot: ILocationSlot, i: number) => {
              if (slot.time?.startsWith("Available") && i > 0) return null;

              return (
                <SuggestionChip
                  //in console there was errors because of multiple NaN as key
                  key={slot.time + `${i}`}
                  className={bem("time-slot")}
                  value={slot.available ? slot.time : ""}
                  type={
                    value.slotType
                      ? value.slotType
                      : slot.available
                      ? "high"
                      : "low"
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

/* eslint-disable max-len */
import { useState } from "react";
import { IconButtonWithTooltip } from "react-admin";
import eyeHidden from "assets/eye_hidden.svg";
import eyeVisible from "assets/eye_visible.svg";

export default function AuthInput(props: any) {
  const {
    autocapitalize = "none",
    autocomplete = "",
    className = "",
    disabled = false,
    label,
    onBlur,
    onChange,
    onFocus,
    name,
    value,
    required = false,
    type,
    errors,
    touched,
    cyId = ""
  } = props;

  const [inputTypeState, setInputTypeState] = useState<string>("");
  const toggleInputType = () => {
    setInputTypeState((prev) =>
      !prev || prev === "password" ? "text" : "password"
    );
  };

  const error = touched && errors && touched[name] && errors[name];

  return (
    <>
      <div className={`${className} relative authInputContainer`}>
        <input
          autoCapitalize={autocapitalize}
          className={`w-full !h-[56px] !rounded-[8px] peer !outline-none text-base !font-['Inter'] font-normal !px-3 ${
            type === "password" ? "!pr-9" : "!pr-3"
          } !pt-[16px] !pb-0 bg-[#FAF9F7] !border ${
            error
              ? "!border-error"
              : "!border-[#CCC7C0] focus:!border-mono-primary"
          } hover:bg-[rgba(48,47,46,0.1)] transition-all ease-in-out duration-150`}
          name={name}
          type={inputTypeState || type}
          placeholder={""}
          onBlur={onBlur}
          data-cy={cyId}
          onChange={onChange}
          onFocus={onFocus}
          value={value}
          disabled={disabled}
          autoComplete={autocomplete}
        />
        {type === "password" && (
          <div className="absolute right-1 top-1/2 -translate-y-1/2">
            <IconButtonWithTooltip
              tabIndex={-1}
              onClick={toggleInputType}
              label=""
            >
              {inputTypeState === "text" ? (
                <img src={eyeVisible} alt="eye visible" />
              ) : (
                <img src={eyeHidden} alt="eye hidden" />
              )}
            </IconButtonWithTooltip>
          </div>
        )}
        {label && (
          <label
            className={
              value
                ? "absolute top-[5px] left-[14px] text-[11px] font-['Inter']"
                : "pointer-events-none absolute top-[16px] left-[14px] font-['Inter'] transition-all ease-in-out duration-150 caption text-[15px] peer-focus:text-[11px] peer-focus:top-[5px]"
            }
          >
            {label}
            {required && <span>&nbsp;*</span>}
          </label>
        )}
      </div>
      <p
        className={`${
          error ? "opacity-1" : "opacity-0"
        } text-[12px] text-error font-['Inter'] ml-4 mt-[4px] mb-[12px] min-h-[16px] !leading-4`}
        data-cy={`${cyId}-error`}
      >
        {errors && errors[name]}
      </p>
    </>
  );
}

import { MutableRefObject, useEffect, useState } from "react";

interface IUseScroll {
  scrollX: number;
  scrollY: number;
}

/**
 * Get scroll position.
 * @param elementRef use element otherwise window
 * @returns scrollX, scrollY positions
 */
export const useScroll = <T extends HTMLElement>(
  elementRef?: MutableRefObject<T | null>
): IUseScroll => {
  const [scrollX, setScrollX] = useState(0);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const element: T | Window = elementRef?.current || window;

    const updateScrollPosition = (): void => {
      setScrollX(
        elementRef && elementRef.current
          ? elementRef.current.scrollLeft
          : window.pageXOffset
      );
      setScrollY(
        elementRef && elementRef.current
          ? elementRef.current.scrollTop
          : window.pageYOffset
      );
    };

    element.addEventListener("scroll", updateScrollPosition);
    updateScrollPosition();
    return () => element.removeEventListener("scroll", updateScrollPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    scrollX,
    scrollY
  };
};

export default useScroll;

import "./CardInput.scss";
import { joinClassNames } from "utils/join-class-names";
import { ChangeEvent, useEffect, useState } from "react";
import { bemElement, bemModifier } from "utils/bem-class-names";

export type TCardInputValueName = "card" | "date" | "CVC";

export interface ICard {
  cardNumber: string;
  date: string;
  CVC: string;
}

interface ICardInputProps {
  cardValue: string;
  dateValue: string;
  CVCValue: string;
  className?: string;
  disabled?: boolean;
  errorMsg?: string;
  onBlur?: (name: TCardInputValueName) => void;
  onChange?: (value: string, name: TCardInputValueName) => void;
}

const baseClassName = "card-input";
const bem = bemElement(baseClassName);

const getCardNumber = (value: string): string => {
  const cardValue = value.replace(/\D/gm, "");
  let card = "";
  cardValue.split("").forEach((s: string, i: number) => {
    switch (i) {
      case 4:
      case 8:
      case 12:
        card = `${card} ${s}`;
        break;
      default:
        card = card + s;
        break;
    }
  });

  card = card.trim();
  return card.substring(0, 19);
};

const getDate = (value: string): string => {
  const dateValue = value.replace(/\D/gm, "");
  let date = "";
  dateValue.split("").forEach((s: string, i: number) => {
    switch (i) {
      case 2:
        date = `${date}/${s}`;
        break;
      default:
        date = date + s;
        break;
    }
  });

  date = date.trim();
  return date.substring(0, 5);
};

const getCVC = (value: string): string => {
  let CVCValue = value.replace(/\D/gm, "");
  CVCValue = CVCValue.trim();

  return CVCValue.substring(0, 3);
};

export const CardInput = ({
  cardValue = "",
  dateValue = "",
  CVCValue = "",
  className = "",
  disabled = false,
  errorMsg = "",
  onBlur,
  onChange
}: ICardInputProps): JSX.Element => {
  const [_cardNumberValue, set_cardNumberValue] = useState<string>(
    getCardNumber(cardValue)
  );
  const [_dateValue, set_dateValue] = useState<string>(getDate(dateValue));
  const [_CVCValue, set_CVCValue] = useState<string>(getCVC(CVCValue));
  const [_cardNumberFocused, set_cardNumberFocused] = useState(false);
  const [_dateFocused, set_dateFocused] = useState(false);
  const [_CVCFocused, set_CVCFocused] = useState(false);

  const onChangeCardNumberHandler = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    const newValue = getCardNumber(e.target.value);
    set_cardNumberValue(newValue);
    onChange && onChange(newValue, "card");
  };

  const onChangeDateHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = getDate(e.target.value);
    set_dateValue(newValue);
    onChange && onChange(newValue, "date");
  };

  const onChangeCVCHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = getCVC(e.target.value);
    set_CVCValue(newValue);
    onChange && onChange(newValue, "CVC");
  };

  const onCardNumberBlurHandler = (): void => {
    set_cardNumberFocused(false);
    onBlur && onBlur("card");
  };

  const onDateBlurHandler = (): void => {
    set_dateFocused(false);
    onBlur && onBlur("date");
  };

  const onCVCBlurHandler = (): void => {
    set_CVCFocused(false);
    onBlur && onBlur("CVC");
  };

  useEffect(() => {
    set_cardNumberValue(getCardNumber(cardValue));
  }, [cardValue]);

  useEffect(() => {
    set_dateValue(getDate(dateValue));
  }, [dateValue]);

  useEffect(() => {
    set_CVCValue(getCVC(CVCValue));
  }, [CVCValue]);

  return (
    <div
      className={joinClassNames(
        bemModifier(baseClassName, {
          disabled: disabled,
          focused: _cardNumberFocused || _dateFocused || _CVCFocused,
          error: !!errorMsg
        }),
        className
      )}
    >
      <div className={bem("inputs-wrapper")}>
        <div className={bem("inputs")}>
          <input
            className={bem("card-input")}
            value={_cardNumberValue}
            type="text"
            disabled={disabled}
            placeholder="Card number"
            onBlur={onCardNumberBlurHandler}
            onFocus={() => set_cardNumberFocused(true)}
            onChange={onChangeCardNumberHandler}
          />
          <input
            className={bem("date-input")}
            value={_dateValue}
            type="text"
            disabled={disabled}
            placeholder="MM/YY"
            onBlur={onDateBlurHandler}
            onFocus={() => set_dateFocused(true)}
            onChange={onChangeDateHandler}
          />
          <span className={bem("dot")}>•</span>
          <input
            className={bem("cvc-input")}
            value={_CVCValue}
            type="text"
            disabled={disabled}
            placeholder="CVC"
            onBlur={onCVCBlurHandler}
            onFocus={() => set_CVCFocused(true)}
            onChange={onChangeCVCHandler}
          />
        </div>
      </div>
      <div className={bem("error-msg-container")}>
        {errorMsg && <span className={bem("error-msg")}>{errorMsg}</span>}
      </div>
    </div>
  );
};

import "./Breadcrumbs.scss";
import { Link, useNavigate } from "react-router-dom";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { handleGoBack } from "utils/handleGoBack";
import { bemElement } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";

export interface IBreadcrumb {
  title: string;
  url: string;
}

export interface IBreadcrumbs {
  breadcrumbs: IBreadcrumb[];
  className?: string;
  rootCrumbName?: string;
  rootCrumbUrl?: string;
}

const baseClassName = "breadcrumbs";
const bem = bemElement(baseClassName);

export const Breadcrumbs = ({
  breadcrumbs,
  className = "",
  rootCrumbName,
  rootCrumbUrl
}: IBreadcrumbs): JSX.Element => {
  const navigate = useNavigate();

  const handleRootCrumbClick = () => {
    if (rootCrumbUrl) {
      navigate(rootCrumbUrl);
    }
  };

  const handleBackClick = () => {
    if (rootCrumbUrl) {
      navigate(rootCrumbUrl);
    } else {
      handleGoBack(navigate);
    }
  };

  return (
    <div className={joinClassNames(baseClassName, className)}>
      {!!rootCrumbName ? (
        <div className={bem("root")} onClick={handleRootCrumbClick} aria-hidden>
          {rootCrumbName}
        </div>
      ) : (
        <div
          className="flex items-center cursor-pointer "
          onClick={handleBackClick}
          aria-hidden="true"
        >
          <SvgIcon name="arrow_left" className="scale-75 mb-[-2px]" />
          <div className={bem("root")}>Back</div>
        </div>
      )}
      {breadcrumbs.length > 0 && (
        <SvgIcon name="slash" className={bem("path")} />
      )}
      {breadcrumbs.map((breadcrumb: IBreadcrumb, i: number) => (
        <div className={bem("links")} key={breadcrumb.title + i}>
          {breadcrumb.url ? (
            <Link to={breadcrumb.url} className={bem("link")}>
              {breadcrumb.title}
            </Link>
          ) : (
            <span className={joinClassNames(bem("link"), "cursor-default")}>
              {breadcrumb.title}
            </span>
          )}
          {i < breadcrumbs.length - 1 && (
            <SvgIcon name="slash" className={bem("path")} />
          )}
        </div>
      ))}
    </div>
  );
};

export default Breadcrumbs;

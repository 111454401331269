enum AccountDetailsPageSections {
  DEFAULT = "",
  EDIT_ACCOUNT = "#edit-account",
  PAYMENT_METHODS = "#payment-methods",
  NOTIFICATIONS = "#notifications",
  CHANGE_PASSWORD = "#change-password",
  DELETE_ACCOUNT = "#delete-account",
  CREDITS = "#credits"
}

export default AccountDetailsPageSections;

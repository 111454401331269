import "./SlidingModal.scss";
import React, { useEffect, useRef, useState } from "react";
import { IconButton } from "../../../elements/icon-button/IconButton";
import { COLORS } from "../../../models/colors";
import { motion, useAnimation } from "framer-motion";
import { bemElement, bemModifier } from "../../../utils/bem-class-names";
import { joinClassNames } from "../../../utils/join-class-names";
import { Button, IButtonProps } from "../../../elements/button/Button";
import { VARIANTS } from "models/variants";
import useWindowSize from "../../../hooks/useWindowSize";

const baseClassName = "sliding-modal";
const bem = bemElement(baseClassName);

export type TSlidingModalAlign = "none" | "bottom";

export interface ISlidingModalData {
  title?: string;
  show: boolean;
  slideTo?: number;
  onHide: () => void;
  disableHeader?: boolean;
  disableBackground?: boolean;
  align?: TSlidingModalAlign;
  children: React.ReactNode;
  BottomControls?: React.FC;
  rightButton?: IButtonProps;
  className?: string;
  backgroundClassName?: string;
  transparentHeader?: boolean;
  enableScroll?: boolean;
}

const SlidingModal = ({
  title,
  show,
  slideTo = 32,
  onHide,
  disableHeader,
  disableBackground,
  align = "none",
  children,
  BottomControls,
  rightButton,
  className = "",
  backgroundClassName = "",
  transparentHeader,
  enableScroll
}: ISlidingModalData) => {
  const { height } = useWindowSize();
  const backgroundAnimation = useAnimation();
  const modalAnimation = useAnimation();
  const [slideToY, setSlideToY] = useState<number>(slideTo);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const controlsRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show) {
      backgroundAnimation.start({ opacity: 1, display: "block" }).catch();
      modalAnimation
        .start(
          { top: slideToY + "px", display: "block" },
          { bounce: undefined }
        )
        .catch();
    } else {
      backgroundAnimation
        .start({ opacity: 0 })
        .then(() => backgroundAnimation.start({ display: "none" }))
        .catch();
      modalAnimation
        .start({ top: `${height}px` }, { bounce: undefined })
        .then(() => modalAnimation.start({ display: "none" }))
        .catch();
      document.body.style.overflow = "auto";
    }
  }, [align, backgroundAnimation, height, modalAnimation, show, slideToY]);

  useEffect(() => {
    if (align === "none") {
      setSlideToY(slideTo);
      return;
    }

    const target = contentRef?.current?.children[0];
    const getSlideTo = () => {
      if (target && target.clientHeight > 0 && backgroundRef.current) {
        let contentHeight = target.clientHeight;
        if (!disableHeader) {
          contentHeight += 64;
        }
        if (controlsRef.current && controlsRef.current.offsetHeight > 0) {
          contentHeight += controlsRef.current.offsetHeight;
        }
        setSlideToY(backgroundRef.current.offsetHeight - contentHeight);
      }
    };
    const resizeObserver = new ResizeObserver(getSlideTo);

    if (target) {
      if (align === "bottom") {
        resizeObserver.observe(target);
      } else {
        resizeObserver.unobserve(target);
      }
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [align, disableHeader, slideTo]);

  return (
    <>
      {!disableBackground && (
        <motion.div
          className={joinClassNames(
            "sliding-modal-background",
            backgroundClassName
          )}
          animate={backgroundAnimation}
          initial={{ opacity: 0 }}
          ref={backgroundRef}
          onClick={onHide}
        />
      )}
      <motion.div
        className={joinClassNames(
          baseClassName,
          className,
          bemModifier(baseClassName, {
            "transparent-header": transparentHeader
          })
        )}
        animate={modalAnimation}
        initial={{ top: "10000px" }}
      >
        {!disableHeader && (
          <div
            className={bemModifier(
              bem("header"),
              { rounded: slideToY !== 0 },
              { transparent: transparentHeader }
            )}
          >
            <IconButton
              color={
                transparentHeader ? COLORS.SECONDARY_CONTAINER : COLORS.PRIMARY
              }
              variant={transparentHeader ? VARIANTS.FILLED : VARIANTS.DEFAULT}
              iconName="close"
              onClick={onHide}
            />
            <div className={bem("header-title")}>{title}</div>
            {rightButton && (
              <Button
                color={rightButton.color}
                variant={rightButton.variant}
                text={rightButton.text}
                type={rightButton.type}
                disabled={rightButton.disabled}
                size={rightButton.size}
                iconLeftName={rightButton.iconLeftName}
                iconRightName={rightButton.iconRightName}
                stretch={rightButton.stretch}
                submitting={rightButton.submitting}
                onClick={rightButton.onClick}
                className={rightButton.className}
              />
            )}
          </div>
        )}
        <div
          className={bemModifier(bem("content-wrapper"), {
            "with-header": !disableHeader && !transparentHeader
          })}
        >
          <div
            className={joinClassNames(
              bemModifier(bem("content"), { scrollable: enableScroll }),
              "hide-scroll-bar"
            )}
            ref={contentRef}
          >
            {children}
          </div>
          {BottomControls && (
            <div className={bem("bottom-controls")} ref={controlsRef}>
              <BottomControls />
            </div>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default SlidingModal;

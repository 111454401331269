/* eslint-disable max-len */
import "./PointmentDetailsModal.scss";
import SlidingModal from "../sliding-modal/SlidingModal";
import { TAppointment, TCalculateAppointment } from "../../../types";
import PointmentDetails from "../../pointment-details/PointmentDetails";
// import { Button } from "../../../elements/button/Button";
// import { COLORS } from "../../../models/colors";
// import { VARIANTS } from "../../../models/variants";
// import { bemElement } from "../../../utils/bem-class-names";
import { joinClassNames } from "../../../utils/join-class-names";

const baseClassName = "pointment-details-modal";
// const bem = bemElement(baseClassName);

interface IPointmentDetailsModalData {
  appointment: TAppointment;
  calculateAppointment?: TCalculateAppointment;
  show: boolean;
  onHide: () => void;
  className?: string;
}

const PointmentDetailsModal = ({
  appointment,
  calculateAppointment,
  show,
  onHide,
  className = ""
}: IPointmentDetailsModalData) => {
  // const BottomButton = () => {
  //   return (
  //     <div className={bem("bottom-button-wrapper")}>
  //       {/*TODO: add onClick*/}
  //       <Button
  //         color={COLORS.BACKGROUND}
  //         variant={VARIANTS.FILLED}
  //         iconRightName="download"
  //         text="Get receipt"
  //         className="!bg-surface flexible w-100%"
  //       />
  //     </div>
  //   );
  // };

  return (
    <SlidingModal
      show={show}
      onHide={onHide}
      title="Pointment details"
      // BottomControls={BottomButton}
      className={joinClassNames(baseClassName, className)}
    >
      <PointmentDetails
        appointment={appointment}
        calculateAppointment={calculateAppointment}
      />
    </SlidingModal>
  );
};

export default PointmentDetailsModal;

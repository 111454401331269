import "./Checkbox.scss";
import { ForwardedRef, forwardRef, useState } from "react";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { COLORS } from "models/colors";
import { joinClassNames } from "utils/join-class-names";

export enum MARK_TYPE {
  CHECKMARK = "checkmark",
  DASH = "dash"
}

interface ICheckboxProps {
  color: COLORS;
  checked: boolean;
  id: string;
  className?: string;
  cyId?: string;
  disabled?: boolean;
  markType?: MARK_TYPE;
  onChange: (value: boolean) => void;
}

export const Checkbox = forwardRef<HTMLSpanElement, ICheckboxProps>(
  (props: ICheckboxProps, ref: ForwardedRef<HTMLSpanElement>): JSX.Element => {
    const {
      className = "",
      disabled,
      color,
      checked,
      onChange,
      markType,
      id,
      cyId = ""
    } = props;
    const [value, setValue] = useState<boolean>(checked);

    const onChangeHandler = (): void => {
      onChange(!value);
      setValue(!value);
    };

    return (
      <span
        className={joinClassNames(
          "checkbox",
          color,
          value ? "checked" : "",
          disabled ? "disabled" : "",
          className
        )}
        ref={ref}
      >
        <label htmlFor={id} className="state-layer">
          <div className="checkbox__container" data-cy={cyId}>
            <SvgIcon
              name={
                markType === MARK_TYPE.DASH ? "minus_small" : "checkmark_small"
              }
            />
          </div>
          <input
            id={id}
            type="checkbox"
            checked={value}
            disabled={disabled}
            onChange={onChangeHandler}
          />
        </label>
      </span>
    );
  }
);

export type ValidateFn = (
  value: unknown,
  actualValues?: { [k: string]: unknown }
) => string | null;

export function validateValues(
  values: { [k: string]: unknown },
  validators: Record<string, ValidateFn[]>
): Record<string, string> {
  const errors: Record<string, string> = {};

  for (const propName in validators) {
    if (!validators.hasOwnProperty(propName)) {
      continue;
    }

    const value = values[propName];
    const propValidators = validators[propName];

    if (!propValidators.length) {
      continue;
    }

    for (const validateFn of propValidators) {
      const error = validateFn(value, values);

      if (error) {
        errors[propName] = error;
        break;
      }
    }
  }

  return errors;
}

import "./IconButton.scss";
import React, { ForwardedRef, forwardRef } from "react";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { COLORS } from "models/colors";
import { VARIANTS } from "models/variants";
import { joinClassNames } from "utils/join-class-names";

export type TIconButtonSize = "sm" | "lg";
export type TButtonType = "button" | "submit" | "reset";

interface IconButtonProps {
  color: COLORS;
  iconName: string;
  className?: string;
  cyId?: string;
  disabled?: boolean;
  size?: TIconButtonSize;
  submitting?: boolean;
  type?: TButtonType;
  variant?: VARIANTS;
  onClick?: React.MouseEventHandler;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    props: IconButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ): JSX.Element => {
    const {
      color,
      iconName,
      className = "",
      cyId = "",
      disabled,
      size = "sm",
      type = "button",
      variant = VARIANTS.DEFAULT,
      onClick
    } = props;

    return (
      <button
        className={joinClassNames(
          "icon-button",
          color,
          size,
          variant,
          className
        )}
        ref={ref}
        onClick={onClick}
        data-cy={cyId}
        type={type}
        disabled={disabled}
      >
        <div className="state-layer">
          <span className="content">
            <SvgIcon name={iconName} />
          </span>
        </div>
      </button>
    );
  }
);

/* eslint-disable max-len */
import "./LocationWorkingHours.scss";
import Accordion from "components/accordion/Accordion";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { LocationPageLocationFragment } from "routes/location/Location.generated";
import { bemElement, bemModifier } from "utils/bem-class-names";
import { isBefore, isAfter } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

interface LocationWorkingHoursProps {
  location: LocationPageLocationFragment;
  weekDays: IWeekDay[];
  mobileVersion?: boolean;
}

export interface IWeekDay {
  startTime: string | null;
  endTime: string | null;
  startDate: any;
  endDate: any;
  weekDayName: string;
  today: boolean;
}

const baseClassName = "location-working-hours";
const bem = bemElement(baseClassName);

const weekDayNames: { [key: string]: string } = {
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  closed: "Closed"
};

const getSchedule = (weekDays: IWeekDay[]): JSX.Element => {
  return (
    <>
      {weekDays.map((weekDay: IWeekDay, i: number) => {
        return (
          <div
            key={i}
            className={bemModifier(bem("working-hours-item"), {
              active: weekDay.today
            })}
          >
            <span className="flex">
              <span className={bem("working-hours-item-marker")} />
              {weekDayNames[weekDay.weekDayName]}
            </span>
            {!!weekDay.startTime ? (
              <span>
                {weekDay.startTime} - {weekDay.endTime}
              </span>
            ) : (
              <span>{weekDayNames.closed}</span>
            )}
          </div>
        );
      })}
    </>
  );
};

const handleNearestDayCopy = (weekDays: IWeekDay[], todayIndex: number) => {
  // with this sort I will have today at the end so I can start searching nearest day from 0 index
  const customSortedWeek = [
    ...weekDays.filter((item, index) => index > todayIndex),
    ...weekDays.filter((item, index) => index <= todayIndex)
  ];
  const nearestOpenIndex = customSortedWeek?.findIndex(
    (item) => item?.startTime
  );
  if (nearestOpenIndex === 0) {
    return `Opens tomorrow at ${customSortedWeek[nearestOpenIndex].startTime}`;
  }
  if (nearestOpenIndex > 0) {
    return `Opens on ${customSortedWeek[nearestOpenIndex].weekDayName} at ${customSortedWeek[nearestOpenIndex].startTime}`;
  }
  return "The date of opening is currently unknown";
};

const workHours = (
  todayWorkingHours?: IWeekDay,
  timezone?: string | null
): {
  isOpen: boolean;
  isBefore: boolean;
} => {
  if (timezone) {
    const now = utcToZonedTime(new Date(), timezone);

    return {
      isOpen:
        isAfter(now, new Date(todayWorkingHours?.startDate)) &&
        isBefore(now, new Date(todayWorkingHours?.endDate)),
      isBefore: isBefore(now, new Date(todayWorkingHours?.startDate))
    };
  }
  return {
    isOpen:
      isAfter(new Date(), new Date(todayWorkingHours?.startDate)) &&
      isBefore(new Date(), new Date(todayWorkingHours?.endDate)),
    isBefore: isBefore(new Date(), new Date(todayWorkingHours?.startDate))
  };
};

export const LocationWorkingHours = ({
  location,
  weekDays,
  mobileVersion
}: LocationWorkingHoursProps): JSX.Element => {
  const todayIndex = weekDays?.findIndex((item) => item?.today);
  const todayWorkingHours = weekDays[todayIndex];
  const { isOpen, isBefore } = workHours(todayWorkingHours, location.timezone);

  const handleOpenAndClosedCopy = () => {
    if (isBefore) {
      return <span>Opens today at {todayWorkingHours?.startTime}</span>;
    }
    if (isOpen) {
      return <span>Closes at {todayWorkingHours?.endTime}</span>;
    }
    return <span>{handleNearestDayCopy(weekDays, todayIndex)}</span>;
  };
  return (
    <>
      {mobileVersion ? (
        <div className={bem("working-hours-header")}>
          <span
            className={`flex items-center mr-4px font-semibold whitespace-nowrap ${
              location.status && location.status === "open"
                ? "text-success fill-success"
                : ""
            }`}
          >
            <SvgIcon name="time" className="mr-6px" />
            {`${isOpen ? "Open" : "Closed"} •`}
          </span>
          {handleOpenAndClosedCopy()}
        </div>
      ) : (
        <Accordion>
          <div className={bem("working-hours-header")}>
            <span
              className={`flex items-center mr-4px font-semibold ${
                location.status && location.status === "open"
                  ? "text-success fill-success"
                  : ""
              }`}
            >
              <SvgIcon name="time" className="mr-6px" />
              {`${isOpen ? "Open" : "Closed"} •`}
            </span>
            {handleOpenAndClosedCopy()}
          </div>
          <ul className={bem("working-hours-list")}>{getSchedule(weekDays)}</ul>
        </Accordion>
      )}
    </>
  );
};

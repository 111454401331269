import "./NotificationsSection.scss";
import React, { useEffect, useState } from "react";
import Switch from "../../../elements/switch/Switch";
import { COLORS } from "../../../models/colors";
import { bemElement } from "../../../utils/bem-class-names";
import { ListItem } from "../../list-item/ListItem";
import {
  ReminderSettingFragment,
  useReminderSettingsQuery,
  useUpdateReminderSettingMutation
} from "./NotificationsSection.generated";
import { useUser } from "../../../providers/user";
import { SortOrder } from "../../../types.generated";

const baseClassName = "notifications-section";
const bem = bemElement(baseClassName);

interface IContact {
  contact: ReminderSettingFragment;
  onChange: (id: string, enabled: boolean) => void;
}

const Contact = ({ contact, onChange }: IContact) => {
  const [enabled, setEnabled] = useState<boolean>(contact.enabled);

  const handleChange = () => {
    setEnabled((prevState) => !prevState);
    onChange(contact.id, !enabled);
  };

  const control = (
    <Switch color={COLORS.PRIMARY} checked={enabled} onChange={handleChange} />
  );

  useEffect(() => {
    setEnabled(contact.enabled);
  }, [contact.enabled]);

  return (
    <ListItem control={control} disableRightArrow className={bem("contact")}>
      <div className={bem("contact-value")}>{contact.value}</div>
    </ListItem>
  );
};

const NotificationsSection = () => {
  const { user } = useUser();
  const { data } = useReminderSettingsQuery({
    variables: {
      where: {
        user_id: {
          equals: user.id
        }
      },
      orderBy: {
        type: SortOrder.Desc
      }
    },
    skip: !user.id
  });
  const [updateReminderSetting] = useUpdateReminderSettingMutation();
  const contacts = data?.reminderSettings;

  const updateReminderSettingHandler = (id: string, enabled: boolean) => {
    updateReminderSetting({
      variables: {
        data: {
          enabled: {
            set: enabled
          }
        },
        where: {
          id
        }
      }
    });
  };

  if (!contacts) {
    return null;
  }

  return (
    <div className={baseClassName}>
      <div className={bem("title")}>Contact Info:</div>
      <div className={bem("contacts")}>
        {contacts.map((contact: ReminderSettingFragment) => (
          <Contact
            key={contact.id}
            contact={contact}
            onChange={(id: string, enabled: boolean) =>
              updateReminderSettingHandler(id, enabled)
            }
          />
        ))}
      </div>
    </div>
  );
};

export default NotificationsSection;

import "./Input.scss";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { IconButton } from "elements/icon-button/IconButton";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { COLORS } from "models/colors";
import { bemElement, bemModifier } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";

export type TInputType = "text" | "email" | "password" | "number" | "tel";

export interface IInputProps {
  value?: string | number;
  className?: string;
  cyId?: string;
  clearable?: boolean;
  disabled?: boolean;
  errorMsg?: string;
  showErrors?: boolean;
  iconName?: string;
  label?: string;
  placeholder?: string;
  type?: TInputType;
  autocomplete?: string;
  hint?: string;
  onBlur?: () => void;
  onChange?: (value: string | number | undefined) => void;
}

const baseClassName = "input";
const bem = bemElement(baseClassName);

const getPhone = (value: string): string => {
  const phoneValue = value.replace(/\D/gm, "");
  let phone = "";
  phoneValue.split("").forEach((s: string, i: number) => {
    switch (i) {
      case 0:
        phone = `+${phone}${s}`;
        break;
      case 1:
        phone = `${phone} (${s}`;
        break;
      case 4:
        phone = `${phone}) ${s}`;
        break;
      case 7:
        phone = ` ${phone} ${s}`;
        break;
      default:
        phone = phone + s;
        break;
    }
  });

  phone = phone.trim();
  return phone.substring(0, 17);
};

export const Input = ({
  value = "",
  className = "",
  cyId = "",
  clearable = false,
  disabled = false,
  errorMsg = "",
  showErrors = true,
  iconName,
  label,
  placeholder,
  type = "text",
  autocomplete = "",
  hint,
  onBlur,
  onChange
}: IInputProps): JSX.Element => {
  const [_value, set_value] = useState<string | number | undefined>(
    type === "tel" ? getPhone("" + value) : value
  );
  const [_focused, set_focused] = useState(false);
  const [_type, set_type] = useState(type);

  const isClearButtonVisible = useMemo(
    () => !!_value && clearable,
    [_value, clearable]
  );

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = type === "tel" ? getPhone(e.target.value) : e.target.value;
    set_value(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const onBlurHandler = (): void => {
    set_focused(false);
    onBlur && onBlur();
  };

  const onClearHandler = (): void => {
    set_value("");
    onChange && onChange("");
  };

  useEffect(() => {
    set_value(type === "tel" ? getPhone("" + value) : value);
  }, [type, value]);

  return (
    <div
      className={joinClassNames(
        bemModifier(baseClassName, {
          "clear-button-visible": isClearButtonVisible,
          disabled: disabled,
          focused: _focused,
          "small-label": _focused || !!_value || !!placeholder,
          "label-visible": !!label,
          "icon-visible": !!iconName,
          error: !!errorMsg && showErrors
        }),
        className
      )}
    >
      {iconName && (
        <div className={bem("icon-container")}>
          <SvgIcon className={bem("icon")} name={iconName} />
        </div>
      )}
      {label && (
        <div className={bem("label-container")}>
          <span className={bem("label")}>{label}</span>
        </div>
      )}
      <input
        className={bem("input-element")}
        type={_type}
        value={_value}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        onFocus={() => set_focused(true)}
        placeholder={placeholder}
        autoComplete={autocomplete}
        data-cy={cyId}
        disabled={disabled}
      />
      {(isClearButtonVisible || type === "password") && (
        <div className={bem("button-container")}>
          {isClearButtonVisible && (
            <IconButton
              color={!!errorMsg ? COLORS.ERROR : COLORS.SECONDARY}
              iconName="close_circle"
              className={bem("clear-button")}
              onClick={onClearHandler}
              disabled={disabled}
            />
          )}
          {type === "password" && (
            <IconButton
              color={COLORS.SECONDARY}
              iconName={_type === "password" ? "eye_hidden" : "eye_visible"}
              className={bem("clear-button")}
              onClick={() =>
                set_type(_type === "password" ? "text" : "password")
              }
              disabled={disabled}
            />
          )}
        </div>
      )}
      <div className={bem("msg-container")}>
        {showErrors && errorMsg && (
          <span className={bem("error-msg")} data-cy={`${cyId}-error`}>
            {errorMsg}
          </span>
        )}
        {hint && _focused && <span className={bem("hint-msg")}>{hint}</span>}
      </div>
    </div>
  );
};

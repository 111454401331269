import { bemElement } from "utils/bem-class-names";
import "./TermsAndConditions.scss";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import TocPrivacyPolicyFooter from "components/toc-privacypolicy-footer/TocPrivacyPolicyFooter";

const baseClassName = "terms-and-conditions";
const bem = bemElement(baseClassName);

const TermsAndConditions = () => {
  return (
    <div className={baseClassName}>
      <div className={bem("title-container")}>
        <h3>Terms and Conditions</h3>
        <div className={bem("last-updated")}>
          Last updated: January 28, 2024{" "}
          <SvgIcon name="arrow_bottom" className={bem("arrow-icon")} />
        </div>
      </div>
      <section className={bem("contents")}>
        <article className={bem("content")}>
          <h5>Introduction</h5>
          <p>
            Welcome to www.pointmentapp.com (the “Website”). The Website and the
            mobile application (the “App”) are owned and operated by Pointment,
            Inc “us,” “we,” or “our”).
            <br />
            <br />
            By using the Website and/or the App, you signify your agreement to
            the terms and conditions set forth below (the “Terms and
            Conditions”) and to our privacy policy set forth at
            www.pointmentapp.com/privacypolicy (the “Privacy Policy” and,
            together with the Terms and Conditions, the “Terms of Use”). Use of
            the Website, App, and any platform offered herein is contingent upon
            compliance with the Terms & Conditions.
            <br />
            <br />
            In addition, when you use any of our current or future content,
            features, and services (the “Services”), you will be subject to our
            guidelines, terms, conditions, and agreements applicable to those
            services. If these Terms of Use are inconsistent with the
            guidelines, terms, and agreements applicable to those services,
            these Terms of Use will control. You agree that these Terms of Use
            are supported by reasonable and valuable consideration, the receipt
            and adequacy of which you hereby acknowledge, including, without
            limitation, your access to and use of the Website and App.
          </p>
        </article>
        <article className={bem("content")}>
          <h5>1. License to Use Website and App</h5>
          <p>
            All content contained on the Website and App (collectively,
            “Content”), such as text, graphics, logos, icons, images, audio and
            video clips, digital downloads, and data compilations are our
            property or the property of our licensors or licensees, and the
            compilation of the Content on the Website and App is our exclusive
            property. Any trademarks, service marks, graphics, logos, page
            headers, icons, scripts, and trade names (each, a “Mark”) contained
            on the Website and App are proprietary to us or our licensors or
            licensees. Unless otherwise stated, Pointment and/or its licensors
            own the intellectual property rights in the Website and App,
            including, but not limited to, the Content and the Marks. Subject to
            the license below, all of the aforementioned intellectual property
            rights are reserved.
            <br />
            <br />
            You may view, forward links, and print pages from the Website and
            App for your own personal use, subject to the restrictions set out
            below and elsewhere in these Terms of Use. You must not (a) sell,
            rent or sub-license material from the Website and App; (b)
            reproduce, duplicate, copy or otherwise exploit material on this
            Website and App for a commercial purpose; or (c) edit or otherwise
            modify any material on the Website and App.
          </p>
        </article>
        <article className={bem("content")}>
          <h5>2. Acceptable & Prohibited Use</h5>
          <p>
            <b>(a) Acceptable Use. </b>{" "}
            <span className={bem("warning-text")}>
              You may use the Website and App: (a) to browse participating nail
              salons, (b) book appointments for services offered participating
              nail salons and (c) manage existing reservations made through the
              platform.
            </span>
            <br />
            <br />
            <b>(b) Prohibited Use.</b> You must not: (a) use this Website and/or
            the App in any way that causes, or may cause, damage to the Website
            and/or App or impairment of the availability or accessibility of the
            Website and/or App; or in any way which is unlawful, illegal,
            fraudulent or harmful, or in connection with any unlawful, illegal,
            fraudulent or harmful purpose or activity; (b) you must not use this
            Website and/or App to copy, store, host, transmit, send, use,
            publish or distribute any material which consists of (or is linked
            to) any spyware, computer virus, Trojan horse, worm, keystroke
            logger, rootkit, or other malicious computer software; (c) you must
            not conduct any systematic or automated data collection activities
            (including without limitation scraping, data mining, data
            extraction, and data harvesting) on or in relation to this Website
            and/or the App without our express written consent; (d) you must not
            use this Website and/or the App to transmit or send unsolicited
            commercial communications; and (e) you must not use this Website
            and/or the App for any purposes related to marketing without our
            express written consent.
          </p>
        </article>
        <article className={bem("content")}>
          <h5>3. User Content</h5>
          <p>
            In these Terms and Conditions, “your user content” means material
            (including without limitation text, images, audio material, video
            material, and audio-visual material) that you submit to this
            Website, the App, and Pointment’s social media channels for whatever
            purpose. You grant to Pointment a worldwide, irrevocable,
            non-exclusive, royalty-free license to use, reproduce, adapt,
            publish, translate and distribute your user content in any existing
            or future media. You also grant to Pointment the right to
            sub-license these rights, and the right to bring an action for
            infringement of these rights. Your user content must not be illegal
            or unlawful, must not infringe any third party’s legal rights, and
            must not be capable of giving rise to legal action whether against
            you or Pointment or a third party (in each case under any applicable
            law). You must not submit any user content to the Website, the App
            or Pointment’s social media channels that is or has ever been the
            subject of any threatened or actual legal proceedings or other
            similar complaint. We reserve the right to edit or remove any
            material submitted, hosted, or published to this Website, the App,
            our social media channels, or material that is stored on our
            servers.
          </p>
        </article>
        <article className={bem("content")}>
          <h5>4. Account Creation</h5>
          <p>
            <span className={bem("warning-text")}>
              To browse participating salons, search for appointment
              availability, and book appointments, you will be required to
              create an account. To create an account, you must provide the
              following information: (a) unique username, (b) email address, and
              (c) password. Users must provide accurate and up-to-date
              information when creating an account, and are responsible for
              maintaining the confidentiality of their account details.
            </span>
          </p>
        </article>
        <article className={bem("content")}>
          <h5>5. Booking and Payment Process</h5>
          <p>
            <b>(a) Selection.</b> Users may browse participating salons, select
            a date and time, and request a booking using the Pointment Platform.
            Confirmation of reservations is subject to the salon’s availability.
            <br />
            <br />
            <b>(b) Attendance and Cancellations.</b>
            <span className={bem("warning-text")}>
              Users agree to honor their reservations and notify the salon of
              any changes or cancellations in a timely manner. Cancellation
              policies are at the discretion of the respective salon, and are
              displayed at the time of booking. Failure to notify the salon
              within the requested cancellation period may result in a no-show
              or late cancellation fee. Users agree to the terms of each
              respective salon.
            </span>
            <br />
            <br />
            <b>(c) Payment.</b>
            <span className={bem("warning-text")}>
              Upon booking, you will be asked to provide credit card billing
              information, which will be stored by Stripe. Collection and
              processing of the payment will take place at the salon’s
              discretion. Some salons may require payment up-front, while others
              will complete payment processing in store.
            </span>
            You are agreeing to the Terms and Conditions when you complete a
            purchase or booking on our site.
          </p>
        </article>
        <article className={bem("content")}>
          <h5>6. Limitation of Liability</h5>
          <p>
            Pointment is not liable for any disputes, injuries, damages or
            losses arising from the use of our services. We are not responsible
            for the actions or omissions of our salon partners. Each salon is
            responsible for their customers’ experiences, and Pointment will not
            take accountability for a negative customer service experience at
            its participating salons.
          </p>
        </article>
        <article className={bem("content")}>
          <h5>7. Termination</h5>
          <p>
            <span className={bem("warning-text")}>
              You may terminate your Pointment account at any time by contacting{" "}
              <a className={bem("link")} href="mailto:info@pointment.com">
                info@pointment.com
              </a>
              . Please be advised that refunds will not be provided for any
              purchased services that remain unused at the time of account
              cancellation.
            </span>
          </p>
        </article>
        <article className={bem("content")}>
          <h5>8. Ownership Rights</h5>
          <p>
            Pointment represents and warrants that it is the owner of the
            Website, App, and of each and every component thereof, or the
            recipient of a valid license thereto, and that it has and will
            maintain the full power and authority to grant the intellectual
            property rights to the Website and App set forth in thisAgreement
            without the further consent of any third party.
          </p>
        </article>
        <article className={bem("content")}>
          <h5>9. Intellectual Property</h5>
          <p>
            The features, information, and materials provided and depicted
            through the Services are protected by copyright, trademark, patent,
            and other intellectual property laws. All text, graphical content,
            video, data, and other content made available through the Services
            (collectively, the “Pointment Content”) are provided to you by
            Pointment or our partners or licensors solely to support your
            permitted use of the Services.
            <br />
            <br />
            The Pointment Content may be modified from time to time by Pointment
            in its sole discretion. Except as expressly set forth herein, no
            license is granted to you for any other purpose, and any other use
            of the Services or the Pointment Content shall constitute a material
            breach of this Agreement. Pointment and its partners retail all
            rights in the Services and Pointment Content and any associated
            patents, trademarks, copyrights, mask work rights, trade secrets, or
            other intellectual property rights. No license, right or interest in
            any trademarks of Pointment or any third party is granted under this
            Agreement.
          </p>
        </article>
        <article className={bem("content")}>
          <h5>10. Indemnifications</h5>
          <p>
            <b>(a) Indemniﬁcation by You.</b> You shall defend and indemnify
            Pointment and its directors, oﬃcers, employees,contractors, agents,
            assigns, and successors against any third party claim, suit, or
            proceeding arising out of or related to your alleged or actual use
            of, misuse of, or failure to use the Website or the App, and your
            failure to disclose any medical conditions or allergies resulting in
            injury arising out of a service provided by a Service Provider at a
            Service Provider’s location. Indemniﬁed Claims include, without
            limitation, claims arising out of or related to Pointment’s
            negligence. Indemnitor’s obligations in the preceding sentence and
            include retention and payment of attorneys and payment of court
            costs, as well as settlement atIndemnitor’s expense and payment of
            judgments.
            <br />
            <br />
            <b>(b) Mutual Indemniﬁcation.</b>Each party (“Indemnitor”) shall
            defend and indemnify the other party (“IndemniﬁedParty”) and its
            directors, oﬃcers, employees, contractors, agents, assigns, and
            successors, if applicable,against any third party claim, suit, or
            proceeding arising out of, related to, or alleging any of the
            following(any “Indemniﬁed Claim”): (i) unauthorized disclosure or
            exposure of personally identiﬁable information resulting from
            Indemnitor’s acts or omissions or from those of its contractors;
            (ii) injury to or death of any individual, or any loss of or damage
            to real or tangible personal property, resulting from Indemnitor’s
            acts or omissions or from those of its contractors; or (iii)
            intellectual property infringement by software or contentIndemnitor
            contributed to the Website.
            <br />
            <br />
            <b>(c) Litigation.</b>Indemnitor’s obligations set forth in
            Subsections (a) and (b) above will be excused to the extent that the
            Indemniﬁed Party’s actions or the actions of any director, oﬃcer,
            employee, contractor, agent, assign, or successor of the Indemniﬁed
            Party, if applicable, materially prejudice the defense, due to
            failure to provide prompt notice of the Indemniﬁed Claim or failure
            to reasonably to cooperate. Indemnitor will control the defense of
            any Indemniﬁed Claim, including appeals, negotiations, and any
            settlement or compromise thereof;provided the Indemniﬁed Party will
            have the right, not to be exercised unreasonably, to reject any
            settlement or compromise that requires that it admit wrongdoing or
            liability or subjects one of them to any ongoing affirmative
            obligations.
          </p>
        </article>
        <article className={bem("content")}>
          <h5>11. Miscellaneous.</h5>
          <p>
            <b>(a) Severability. </b> To the extent permitted by applicable law,
            you and Pointment hereby waive any provision of law that would
            render any clause of this Agreement invalid or otherwise
            unenforceable in any respect. In the event that a provision of this
            Agreement is held to be invalid or otherwise unenforceable, such
            provision will be interpreted to fulfill its intended purpose to the
            maximum extent permitted by applicable law, and the remaining
            provisions of this Agreement will continue in full force and effect.
            <br />
            <br />
            <b>(b) Assignment.</b>Pointment may transfer, sub-contract, or
            otherwise deal with Pointment’s rights and/or obligations under
            these Terms of Use without notifying you or obtaining your consent.
            An assignment authorized pursuant to the preceding sentence shall
            not become effective unless and until the assignee agrees in writing
            to be bound by all the assigning party’s rights and obligations set
            forth in these Terms and Conditions. You may not transfer,
            sub-contract, or otherwise deal with your rights and/or obligations
            under the Terms of Use.
            <br />
            <br />
            <b>(c) Entire Agreement.</b>This Agreement sets forth the entire
            agreement of the parties and supersedes all prior or contemporaneous
            writings, negotiations, and discussions with respect to its subject
            matter. Neither you nor Pointement has relied upon any such prior or
            contemporaneous communications. (d) Amendment. Pointment reserves
            the right to modify these terms and conditions at any time. Users
            will be notified of significant changes.
            <br />
            <br />
            <b>(d) Amendment.</b>Pointment reserves the right to modify these
            terms and conditions at any time. Users will be notified of
            significant changes.
            <br />
            <br />
            <b>(e) Waiver.</b>Neither you nor Pointment will be deemed to have
            waived any of its rights under this Agreement by lapse of time or by
            any statement or representation other than by an authorized
            representative in an explicit written waiver. No waiver of a breach
            of this Agreement will constitute a waiver of any other breach of
            this Agreement.
            <br />
            <br />
            <b>(f) Governing Law.</b>This Agreement will be governed solely by
            the internal laws of the State of California, without reference to
            any conflicts of law principle that would apply the substantive laws
            of another jurisdiction to either your or Pointment’s rights or
            duties. You and Pointment consent to the personal and exclusive
            jurisdiction of the federal and state courts of San Francisco,
            California.
          </p>
        </article>
      </section>
      <TocPrivacyPolicyFooter />
    </div>
  );
};

export default TermsAndConditions;

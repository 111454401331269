import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UserQueryVariables = Types.Exact<{
  where: Types.UserWhereUniqueInput;
}>;

export type UserQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: string;
    cognito_id?: string | null;
    birthday?: any | null;
    email: string;
    first_name?: string | null;
    last_name?: string | null;
    last_viewed_notifications?: any | null;
    phone_number?: string | null;
    libphonenumber?: string | null;
    photo_url?: string | null;
    pronoun?: string | null;
    provider_id?: string | null;
    provider?: string | null;
    stripe_customer_id?: string | null;
    status: string;
    newsletter: boolean;
    joined_company_id?: string | null;
    impersonate_id?: string | null;
    impersonate_name?: string | null;
    impersonate_company_id?: string | null;
    impersonate_company_name?: string | null;
    reminder_settings: Array<{
      __typename?: "ReminderSetting";
      id: string;
      type: Types.ReminderType;
      value: string;
    }>;
    company?: { __typename?: "Company"; id: string } | null;
    role?: {
      __typename?: "Role";
      id: string;
      status: Types.RoleStatus;
      role: Types.Roles;
    } | null;
  } | null;
};

export const UserDocument = gql`
  query User($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      cognito_id
      birthday
      email
      first_name
      last_name
      last_viewed_notifications
      phone_number
      libphonenumber
      photo_url
      pronoun
      provider_id
      provider
      stripe_customer_id
      status
      newsletter
      joined_company_id
      impersonate_id
      impersonate_name
      impersonate_company_id
      impersonate_company_name
      reminder_settings {
        id
        type
        value
      }
      company {
        id
      }
      role {
        id
        status
        role
      }
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;

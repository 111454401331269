import "./FileInput.scss";
import React, { ChangeEventHandler, ForwardedRef, forwardRef } from "react";
import { joinClassNames } from "../../utils/join-class-names";
import { COLORS } from "../../models/colors";
import { VARIANTS } from "../../models/variants";
import { TButtonSize } from "../button/Button";
import { SvgIcon } from "../svg-icon/svg-icon";

interface IFileInputData {
  accept?: string;
  color?: COLORS;
  text: string;
  className?: string;
  disabled?: boolean;
  cyId?: string;
  size?: TButtonSize;
  submitting?: boolean;
  variant?: VARIANTS;
  onChange?: ChangeEventHandler;
}

const FileInput = forwardRef<HTMLInputElement, IFileInputData>(
  (props: IFileInputData, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {
    const {
      accept,
      color = COLORS.PRIMARY,
      text,
      className = "",
      cyId = "",
      disabled,
      size = "lg",
      submitting,
      variant = VARIANTS.DEFAULT,
      onChange
    } = props;

    return (
      <div
        className={joinClassNames(
          "file-input",
          size,
          color,
          variant,
          disabled ? "disabled" : "",
          className
        )}
      >
        <div className="state-layer">
          <div className="content">
            <input
              type="file"
              accept={accept}
              ref={ref}
              data-cy={cyId}
              disabled={disabled}
              onChange={onChange}
            />
            {text}
            {submitting && <SvgIcon className="submitting" name="loading" />}
          </div>
        </div>
      </div>
    );
  }
);

export default FileInput;

import "./LoadingElement.scss";
import { bemElement } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";

interface ILoadingElementProps {
  className?: string;
  title?: string;
  description?: string;
}

const baseClassName = "loading-element";
const bem = bemElement(baseClassName);

export const LoadingElement = ({
  className = "",
  title,
  description
}: ILoadingElementProps): JSX.Element => {
  return (
    <div className={joinClassNames(baseClassName, className)}>
      <div className={bem("content")}>
        <svg
          className={bem("icon")}
          width="48"
          height="50"
          viewBox="0 0 48 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={
              "M1.27954 32.7361C4.01176 24.8456 9.48003 17.8642 17.2777 13.3622C25.0753 " +
              "8.86022 33.8556 7.6153 42.055 9.19435C34.6634 0.73057 22.0938 -1.61211 11.9976 " +
              "4.21693C1.90145 10.046 -2.35037 22.1005 1.27954 32.7361Z"
            }
            fill="#F8D1C0"
          />
          <path
            d={
              "M17.6915 36.8674C9.74148 32.6391 4.03331 25.8527 1.0258 18.0614C-2.23353 " +
              "28.8113 2.44036 40.7175 12.7338 46.1923C23.0273 51.6671 35.5084 48.8841 42.5971 " +
              "40.169C34.4601 42.029 25.6415 41.0957 17.6915 36.8674Z"
            }
            fill="#D9ECF1"
          />
          <path
            d={
              "M37.4308 25.4707C37.1176 34.4698 33.5166 42.5736 27.8373 48.6904C38.9292 46.909 " +
              "47.5774 37.4874 47.9829 25.8356C48.3883 14.1838 40.4165 4.18715 29.4789 " +
              "1.6283C34.7227 8.12853 37.7457 16.4651 37.4326 25.4642L37.4308 25.4707Z"
            }
            fill="#B8722A"
          />
        </svg>
        {title && <h4 className={bem("title")}>{title}</h4>}
        {description && (
          <span className={bem("description")}>{description}</span>
        )}
      </div>
    </div>
  );
};

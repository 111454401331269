/* eslint-disable max-len */
import React from "react";

import { Map } from "../types";

import appleLogo from "assets/apple.svg";
import googleLogo from "assets/google.svg";
import arrowLeft from "assets/arrow_left.svg";

export enum BUTTON_VARIANTS {
  APPLE = "apple",
  GOOGLE = "google",
  LINK = "link",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary"
}

export enum BUTTON_SIZES {
  DEFAULT = "default",
  MEDIUM = "medium"
}

interface ButtonProps {
  className?: string;
  disabled?: boolean;
  iconLeft?: string;
  onClick?: any;
  size?: BUTTON_SIZES;
  submitting?: boolean;
  text: string;
  type?: "button" | "submit" | "reset" | undefined;
  variant?: BUTTON_VARIANTS;
  backIcon?: boolean;
  disabledOnSubmit?: boolean;
  cyId?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props: ButtonProps, ref) {
    const {
      className,
      disabled = false,
      iconLeft,
      onClick,
      size = BUTTON_SIZES.DEFAULT,
      submitting,
      text,
      type,
      variant = "primary",
      backIcon,
      disabledOnSubmit,
      cyId = ""
    } = props;

    switch (variant) {
      case "apple":
        return (
          <button
            className={`
              secondary logo medium
              ${className}
            `}
            disabled={disabled}
            ref={ref}
            onClick={onClick}
          >
            <img alt="apple logo" src={appleLogo} />
            {text}
          </button>
        );
      case "google":
        return (
          <button
            className={`
              secondary logo medium
              ${className}
            `}
            disabled={disabled}
            ref={ref}
            onClick={onClick}
          >
            <img alt="google logo" src={googleLogo} />
            {text}
          </button>
        );
      case "secondary":
        return (
          <button
            className={`${
              size === BUTTON_SIZES.MEDIUM ? "secondary medium" : "secondary"
            }
               ${className}
              `}
            disabled={disabled}
            ref={ref}
            type={type}
            onClick={onClick}
          >
            {submitting ? (
              <>
                <>{text}</>
                <div
                  style={{ borderTopColor: "transparent" }}
                  className="w-4 h-4 ml-2 border-2 border-mono-black border-solid rounded-full animate-spin"
                />
              </>
            ) : (
              <div className="flex justify-center items-center">
                {iconLeft && (
                  <img
                    alt={iconLeft}
                    src={icons[iconLeft]}
                    width={24}
                    height={24}
                    className="mr-1"
                  />
                )}
                {text}
              </div>
            )}
          </button>
        );
      case "link":
        return (
          <button
            className={className ? `${"link"} ${className}` : "link"}
            disabled={disabled}
            ref={ref}
            type={type}
            onClick={onClick}
          >
            <div className="flex justify-center">{text}</div>
          </button>
        );
      case "tertiary":
        return (
          <button
            className={`${
              size === BUTTON_SIZES.MEDIUM ? "tertiary medium" : "tertiary"
            }
               ${className}
              `}
            disabled={disabled}
            ref={ref}
            type={type}
            onClick={onClick}
          >
            <div className="flex justify-center items-center">
              {submitting ? (
                <>
                  <>{text}</>
                  <div
                    style={{ borderTopColor: "transparent" }}
                    className="w-4 h-4 ml-2 border-2 border-mono-black border-solid rounded-full animate-spin"
                  />
                </>
              ) : (
                <>{text}</>
              )}
            </div>
          </button>
        );
      default:
        return (
          <button
            className={`${
              size === BUTTON_SIZES.MEDIUM ? "primary medium" : "primary"
            }
               ${className}
              `}
            disabled={(disabledOnSubmit && submitting) || disabled}
            ref={ref}
            data-cy={cyId}
            type={type}
            onClick={(e) => {
              if (onClick) {
                onClick(e);
              }
            }}
          >
            <div className="flex justify-center items-center">
              {backIcon && (
                <img className="mr-1" src={arrowLeft} alt="arrow left" />
              )}
              {submitting && !disabledOnSubmit ? (
                <>
                  <>{text}</>
                  <div
                    style={{ borderTopColor: "transparent" }}
                    className="w-4 h-4 ml-2 border-2 border-mono-black border-solid rounded-full animate-spin"
                  />
                </>
              ) : (
                <div>
                  {iconLeft && (
                    <img alt={iconLeft} src={icons[iconLeft] as string} />
                  )}
                  {text}
                </div>
              )}
            </div>
          </button>
        );
    }
  }
);

const icons: Map = {
  plus: require("../assets/plus@2x.png")
};

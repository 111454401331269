import "./PhotoSlider.scss";
import React, { useRef, useState } from "react";

export interface IPhotoSliderData {
  photos: string[];
  width?: number;
  height?: number;
  onSlide?: (e?: any) => void;
  className?: string;
}

const PhotoSlider = ({
  photos,
  width,
  height,
  onSlide,
  className
}: IPhotoSliderData) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [loadedImages, setLoadedImages] = useState<boolean[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    if (
      ref.current &&
      ref.current.scrollLeft === ref.current.clientWidth * currentIndex
    ) {
      if (event.deltaY > 0) {
        ref.current.scrollBy({
          behavior: "smooth",
          left: ref.current.clientWidth
        });
      } else {
        ref.current.scrollBy({
          behavior: "smooth",
          left: -ref.current.clientWidth
        });
      }
    }
  };

  const handleScroll = () => {
    if (ref.current) {
      if (
        ref.current.scrollLeft / ref.current.clientWidth >
        currentIndex + 0.5
      ) {
        setCurrentIndex(
          Math.ceil(ref.current.scrollLeft / ref.current.clientWidth)
        );
        if (onSlide) {
          onSlide(currentIndex + 1);
        }
      } else if (
        ref.current.scrollLeft / ref.current.clientWidth <
        currentIndex - 0.5
      ) {
        setCurrentIndex(
          Math.ceil(ref.current.scrollLeft / ref.current.clientWidth)
        );
        if (onSlide) {
          onSlide(currentIndex - 1);
        }
      }
    }
  };

  const loading = loadedImages?.length !== photos?.length;

  return (
    <div
      className={`${
        loading ? "loading-animation" : ""
      } photo-slider hide-scroll-bar${className ? " " + className : ""}`}
      onWheel={handleWheel}
      onScroll={handleScroll}
      ref={ref}
    >
      {photos.map((photo: string) => (
        <img
          key={photo}
          src={photo}
          alt={""}
          width={width}
          height={height}
          className={`${
            loading ? "opacity-0" : "opacity-1 appear-animation"
          } photo`}
          onLoad={() => setLoadedImages((prev) => [...prev, false])}
          onError={() => setLoadedImages((prev) => [...prev, false])}
        />
      ))}
    </div>
  );
};

export default PhotoSlider;

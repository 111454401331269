import "./Homepage.scss";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "../../components/footer/Footer";
// import { Button } from "../../elements/button/Button";
// import { COLORS } from "../../models/colors";
// import { VARIANTS } from "../../models/variants";
import { Card, ICard, ILocationSlot } from "../../components/card/Card";
import { bemElement } from "utils/bem-class-names";
import { useHeader } from "providers/header-provider";
import { useGeolocation } from "../../providers/geolocation-provider";
import SwiperCore, {
  Navigation as Nav,
  Controller,
  Autoplay,
  Mousewheel
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Navigation from "components/navigation/Navigation";
import useWindowSize from "../../hooks/useWindowSize";
import {
  HomepageLocationItemFragment,
  useLocationsQuery
} from "./Homepage.generated";
import { useAxios } from "../../providers/axios";
import { TPossibleAppointment } from "../../types";
import { getTime, getZonedDate } from "../../utils/date-time";
import { useUser } from "../../providers/user";
import { useCart } from "providers/cart-provider";
import { schedulingBufferMinutes } from "../../utils/constants";
import { clearCache } from "utils/cacheHelper";

const baseClassName = "homepage";
const bem = bemElement(baseClassName);
SwiperCore.use([Nav, Autoplay, Mousewheel]);

const Homepage = () => {
  const { calculateDistance } = useGeolocation();
  const [cards, setCards] =
    useState<Array<HomepageLocationItemFragment & ICard>>();
  const { reset } = useHeader();
  const { user } = useUser();
  const { api } = useAxios();
  // const [shownCardsCount, setShownCardsCount] = useState<number>(5);
  const { width } = useWindowSize();
  const slidesOnScreenCount = useMemo(() => width / 370, [width]);
  const autoplay = 5000;
  const { data } = useLocationsQuery();
  const { resetEverything: resetCart } = useCart();

  useEffect(() => {
    reset();
    resetCart();
    clearCache();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Mock values
    const rating = {
      value: "5.0",
      reviewsCount: 142
    };
    const distance = 1.7;
    const currency = "$$$";

    const fetchAndRenderLocations = async () => {
      if (!data?.locations) {
        return;
      }

      const cards = data?.locations
        ?.filter((location) => location.visible_on_homepage)
        .map(async (location: HomepageLocationItemFragment) => {
          return {
            ...location,
            preview: location.cover_photo_url,
            title: location.name,
            address: location.address_display,
            rating,
            distance:
              location.address_latitude && location.address_longitude
                ? await calculateDistance(
                    location.address_latitude,
                    location.address_longitude
                  )
                : distance,
            currency
          } as HomepageLocationItemFragment & ICard;
        });

      const resolvedCards = await Promise.all(cards);
      setCards(resolvedCards);

      fetchPossibleAppointments(resolvedCards);
    };

    const fetchPossibleAppointments = async (
      locations: (HomepageLocationItemFragment & ICard)[]
    ) => {
      const { data: appointmentsData } = await api.post(
        "/v1/possible-appointments",
        {
          locations: locations.map((card: ICard) => card.id),
          startTime:
            new Date().getTime() +
            schedulingBufferMinutes(!!user?.impersonate_name) * 60 * 1000,
          limit: 3
        }
      );
      const slots: ILocationSlot[][] = appointmentsData.map(
        (possibleAppointment: any) =>
          possibleAppointment.appointments.map(
            (appointment: TPossibleAppointment, i: number) => {
              if (
                getZonedDate(
                  appointment.start_time,
                  possibleAppointment.location.timezone
                ).getDay() ===
                getZonedDate(
                  new Date().toISOString(),
                  possibleAppointment.location.timezone
                ).getDay() +
                  1
              ) {
                if (i === 0) {
                  return {
                    time: "Available tomorrow",
                    available: true,
                    locationId: possibleAppointment.location.id
                  };
                } else {
                  return {
                    available: false,
                    locationId: possibleAppointment.location.id
                  };
                }
              } else {
                const appointmentDay = getZonedDate(
                  appointment.start_time,
                  possibleAppointment.location.timezone
                ).toLocaleDateString("en-US", {
                  weekday: "long"
                });

                if (
                  getZonedDate(
                    new Date().toISOString(),
                    possibleAppointment.location.timezone
                  ).getDay() ===
                  getZonedDate(
                    appointment.start_time,
                    possibleAppointment.location.timezone
                  ).getDay()
                ) {
                  return {
                    time: getTime(
                      appointment.start_time,
                      possibleAppointment.location.timezone
                    ),
                    available: true,
                    locationId: possibleAppointment.location.id
                  };
                } else {
                  return {
                    time: `Available ${appointmentDay}`,
                    available: true,
                    locationId: possibleAppointment.location.id
                  };
                }
              }
            }
          )
      );

      const updatedCards = locations.map((location) => {
        const locationSlots = slots.find(
          (slot) => slot?.[0]?.locationId === location.id
        );

        if (!locationSlots?.length) {
          return {
            ...location,
            slots: [{ time: "Unavailable", available: true }],
            slotType: "low"
          };
        }

        return { ...location, slots: locationSlots };
      });

      setCards(updatedCards as (HomepageLocationItemFragment & ICard)[]);
    };

    fetchAndRenderLocations();
  }, [calculateDistance, data?.locations, api, user?.impersonate_name]);

  useEffect(() => {
    document.getElementsByTagName("main")[0].classList.remove("max-w-1200px");

    return () =>
      document.getElementsByTagName("main")[0].classList.add("max-w-1200px");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!cards) return null;

  return (
    <>
      <Helmet>
        <title>Pointment</title>
        <meta property="og:title" content="Pointment" />
        <meta name="theme-color" content="#F9F4F4" />
      </Helmet>
      <div className={baseClassName}>
        <img
          src={require("assets/Hero_image.png")}
          alt=""
          style={{ paddingTop: user?.impersonate_name ? "30px" : "0px" }}
          className={bem("image")}
        />
        <div className={bem("title")}>Nail Salons</div>
        <div className={bem("caption")}>
          Your best nail experience is in your hands
        </div>
        <div className={bem("companies-list")}>
          {cards.map(
            (companyCard: HomepageLocationItemFragment & ICard, i: number) => (
              <Link
                className={bem("mobile-location")}
                to={`/company/${companyCard.company.slug}/location/${companyCard.slug}`}
                key={i}
              >
                <Card value={companyCard} className="company-card" />
              </Link>
            )
          )}
          <Swiper
            className="companies-slider"
            autoHeight
            autoplay={
              cards.length > slidesOnScreenCount + 3
                ? { delay: autoplay }
                : false
            }
            spaceBetween={16}
            loop={cards.length > slidesOnScreenCount + 3}
            loopedSlides={3}
            slidesPerView={"auto"}
            navigation={{
              nextEl: ".right-navigation-button",
              prevEl: ".left-navigation-button"
            }}
            mousewheel={true}
            modules={[Controller]}
            centerInsufficientSlides={true}
          >
            {cards.map((companyCard, i) => (
              <SwiperSlide key={companyCard.id}>
                <Link
                  className={bem("slider-location")}
                  to={`/company/${companyCard.company.slug}/location/${companyCard.slug}`}
                  key={i}
                >
                  <Card value={companyCard} />
                </Link>
              </SwiperSlide>
            ))}
            <Navigation
              className="companies-navigation"
              text={`Nail Salons (${cards.length})`}
              rightButtonConfig={{ className: "right-navigation-button" }}
              leftButtonConfig={{ className: "left-navigation-button" }}
            />
          </Swiper>
          {/*<Button*/}
          {/*  color={COLORS.SECONDARY}*/}
          {/*  variant={VARIANTS.FILLED}*/}
          {/*  iconRightName='arrow_bottom'*/}
          {/*  text={"Show more"}*/}
          {/*  className="show-more-button"*/}
          {/*  onClick={() => setShownCardsCount((prevState) => prevState + 5)}*/}
          {/*/>*/}
        </div>
        <Footer className="absolute bottom-0" />
      </div>
    </>
  );
};

export default Homepage;

import "./LocationInfoLink.scss";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { bemElement } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";
import { Link } from "react-router-dom";

const baseClassName = "location-info-link";
const bem = bemElement(baseClassName);

export function generateGoogleMapsLink(address: string): string {
  return `https://www.google.com/maps?q=${address}`;
}

export function generatePhoneLink(phoneNumber: string): string {
  return `tel:${phoneNumber.replace(/\D/gm, "")}`;
}

interface LocationInfoLinkProps {
  href: string;
  name: string;
  iconName: string;
  className?: string;
  target?: "_blank" | "_self";
  rel?: "noreferrer";
}

export const LocationInfoLink = ({
  href,
  name,
  iconName,
  className = "",
  target = "_blank",
  rel = "noreferrer"
}: LocationInfoLinkProps): JSX.Element => {
  if (target === "_self") {
    return (
      <Link to={href} className={joinClassNames(baseClassName, className)}>
        <div className={bem("body")}>
          <span className={bem("title")}>
            <SvgIcon name={iconName} className="mr-6px" />
            {name}
          </span>
          <SvgIcon
            name="temp_arrow_redirect"
            className="fill-on-surface-variant"
          />
        </div>
      </Link>
    );
  }

  return (
    <a
      href={href}
      target={target}
      rel={rel}
      className={joinClassNames(baseClassName, className)}
    >
      <div className={bem("body")}>
        <span className={bem("title")}>
          <SvgIcon name={iconName} className="mr-6px" />
          {name}
        </span>
        <SvgIcon
          name="temp_arrow_redirect"
          className="fill-on-surface-variant"
        />
      </div>
    </a>
  );
};

import alertIcon from "assets/alert_icon.svg";
import closeIcon from "assets/close_icon.svg";

interface ToastErrorProps {
  error: string;
  setError: (e: string) => void;
  cyId?: string;
}

const ToastError = ({ error, setError, cyId }: ToastErrorProps) => {
  return (
    <div
      className="absolute flex justify-between items-center w-full min-h-[40px] bg-[#F8D1C0]
              lg:top-0 sm:top-0 -top-8 left-0 lg:rounded-t-2xl sm:rounded-t-2xl rounded-none"
    >
      <div />
      <div className="flex items-center">
        <img className="mx-2" src={alertIcon} alt="alert icon" />
        <p
          className="text-[#752D0D] text-xs	font-normal	font-['Inter']"
          data-cy={cyId}
        >
          {error}
        </p>
      </div>
      <div
        onClick={() => setError("")}
        className="mr-2 cursor-pointer hover:bg-mono-75 transition-colors ease-in-out duration-150"
        aria-hidden="true"
      >
        <img src={closeIcon} alt="close icon" />
      </div>
    </div>
  );
};

export default ToastError;

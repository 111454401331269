import "./InputChip.scss";
import { MouseEvent } from "react";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { COLORS } from "models/colors";
import { joinClassNames } from "utils/join-class-names";
import Tooltip from "@mui/material/Tooltip";

interface IInputChipProps {
  color: COLORS;
  className?: string;
  cyId?: string;
  iconLeftName?: string;
  iconRightName?: string;
  text?: string;
  value?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  category?: string;
}

export const InputChip = ({
  color,
  className = "",
  cyId = "",
  iconLeftName,
  iconRightName,
  text,
  value,
  onClick,
  disabled,
  category
}: IInputChipProps): JSX.Element => {
  const onClickHandler = (e: MouseEvent): void => {
    e.stopPropagation();
    onClick && onClick();
  };

  const onMouseDownHandler = (e: MouseEvent): void => {
    e.stopPropagation();
  };

  const onMouseUpHandler = (e: MouseEvent): void => {
    e.stopPropagation();
  };

  return (
    <Tooltip
      placement="top"
      title={
        disabled
          ? `You can't choose ${category?.toLocaleLowerCase()} twice`
          : ""
      }
    >
      <button
        className={joinClassNames(
          "input-chip",
          color,
          !text ? "rounded-chip" : "",
          value ? "selected" : "",
          disabled ? "opacity-[0.3] !cursor-not-allowed" : "",
          className
        )}
        onClick={onClickHandler}
        onMouseDown={onMouseDownHandler}
        onMouseUp={onMouseUpHandler}
        disabled={disabled}
        data-cy={cyId}
      >
        <div className="state-layer">
          {iconLeftName && (
            <SvgIcon name={iconLeftName} className="icon-left" />
          )}
          {text}
          {iconRightName && (
            <SvgIcon name={iconRightName} className="icon-right" />
          )}
        </div>
      </button>
    </Tooltip>
  );
};

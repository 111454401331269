import { IForm } from "hooks/use-form";
import {
  IFormElement,
  IFormElementProps,
  TFormElementType
} from "./FormBuilder";

export interface IFormElementConfig {
  type: TFormElementType;
  extraProps: IFormElementProps;
  formValueName: string | string[];
}

const getValuesObject = (
  form: IForm,
  names: string[]
): Record<string, unknown> => {
  const values: Record<string, unknown> = {};
  names.forEach((name: string, i: number) => {
    values["value" + i] = form.values[name] || "";
    values["value" + i + "name"] = name;
  });

  return values;
};

const checkErrors = (form: IForm, formValueName: string | string[]): string => {
  const valueNames = Array.isArray(formValueName)
    ? formValueName
    : [formValueName];
  let msg = "";

  valueNames.forEach((name: string) => {
    if ((form.submitted || form.touched[name]) && form.errors[name]) {
      msg = form.errors[name];
      return;
    }
  });

  return msg;
};

const setTouched = (
  form: IForm,
  formValueName: string | string[],
  _formValueName: string
): void => {
  form.setTouched(
    Array.isArray(formValueName) ? _formValueName : formValueName
  );
};

const changeValue = (
  value: unknown,
  form: IForm,
  formValueName: string | string[],
  _formValueName: string
): void => {
  form.changeValue(
    value,
    Array.isArray(formValueName) ? _formValueName : formValueName
  );
};

export const generateFormElementConfigs = (
  form: IForm,
  elements: IFormElementConfig[]
): IFormElement[] => {
  const formElements: IFormElement[] = [];

  elements.forEach((element) => {
    const { type, formValueName, extraProps } = element;
    const isFormValueNameArray = Array.isArray(formValueName);

    formElements.push({
      type,
      name: isFormValueNameArray ? formValueName.join("-") : formValueName,
      props: {
        ...extraProps,
        ...((type === "Input" || "Select") && !isFormValueNameArray
          ? { value: "" + form.values[formValueName] }
          : {}),
        ...(type === "Checkbox" && !isFormValueNameArray
          ? { checked: !!form.values[formValueName] }
          : {}),
        ...(type === "CardInput" && isFormValueNameArray
          ? getValuesObject(form, formValueName)
          : {}),
        errorMsg: checkErrors(form, formValueName),
        onChange: (value: unknown, _formValueName?: string) =>
          changeValue(value, form, formValueName, _formValueName || ""),
        onBlur: (_formValueName?: string) =>
          setTouched(form, formValueName, _formValueName || "")
      }
    });
  });

  return formElements;
};

/* eslint-disable max-len */
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Cognito from "providers/cognito";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Auth } from "@aws-amplify/auth";
import { Button } from "elements/button/Button";
import { COLORS } from "models/colors";
import { VARIANTS } from "models/variants";
import { Input } from "elements/input/Input";
import { Toast } from "elements/toast/Toast";
import { APP_ROUTES } from "routes";

interface IVerifyProps {
  email?: string;
  onSuccess?: () => void;
  onReturnToSignUp?: () => void;
}

export default function Verify({
  email,
  onSuccess,
  onReturnToSignUp
}: IVerifyProps) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const { fetchAuthenticatedUser } = Cognito.useCognito();

  const _email = email ? email : searchParams.get("email") || "";

  const { handleSubmit, values, setFieldValue, touched, errors } = useFormik({
    initialValues: {
      email: _email,
      verification_code: ""
    },
    validationSchema: VerifySchema,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: false,
    onSubmit: async ({ email, verification_code }) => {
      try {
        setError("");
        setSubmitting(true);

        try {
          await Auth.confirmSignUp(email, verification_code);
        } catch (error: any) {
          if (
            error.message ===
            "User cannot be confirmed. Current status is CONFIRMED"
          ) {
            try {
              await Auth.verifyCurrentUserAttributeSubmit(
                "email",
                verification_code
              );
            } catch (verifyError: any) {
              setError("Failed to verify email: " + verifyError.message);
              return;
            }
          } else {
            setError("Confirmation failed: " + error.message);
            return;
          }
        }

        if (location.state?.password) {
          try {
            await Auth.signIn(email, location.state.password);
            fetchAuthenticatedUser();
          } catch (signInError: any) {
            setError("Failed to sign in: " + signInError.message);
            return;
          }
        }

        if (onSuccess) {
          return onSuccess();
        }
        navigate(APP_ROUTES.HOME);
      } catch (error: any) {
        setError("An unexpected error occurred: " + error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  const resendCode = async () => {
    try {
      await Auth.resendSignUp(_email || "");
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleRedirectToSignUp = () => {
    if (onReturnToSignUp) {
      return onReturnToSignUp();
    }
    navigate(APP_ROUTES.SIGN_UP);
  };

  return (
    <>
      <Helmet>
        <title>Pointment | Verify</title>
        <meta property="og:title" content="Pointment | Verify" />
      </Helmet>
      <div className="verify md:flex md:justify-center">
        <div className="relative form-wrapper md:mt-32px">
          {error && (
            <Toast
              className="absolute flex justify-between items-center w-full min-h-[40px] bg-[#F8D1C0]
              top-0 left-0 lg:rounded-t-2xl md:rounded-t-2xl rounded-none"
              msg={error}
              type="error"
              showIcon={true}
              onClose={() => setError("")}
            />
          )}
          <div
            className={`form-container p-16px md:p-48px ${
              error ? "lg:mt-0 md:mt-0 mt-36px" : ""
            }`}
          >
            <div className="form-header">
              <h2>Verification code</h2>
            </div>
            <div className="form-body my-24px">
              <p>
                Verification code has been sent to:{" "}
                <span className="font-bold">{_email}</span>
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <Input
                type="text"
                cyId="verification-code-input"
                label="Verification code"
                onChange={(e) => setFieldValue("verification_code", e)}
                value={values.verification_code}
                errorMsg={
                  touched &&
                  errors &&
                  touched["verification_code"] &&
                  errors["verification_code"]
                    ? errors["verification_code"]
                    : ""
                }
                className="w-full"
              />
              <div className="flex gap-16px">
                <Button
                  onClick={handleRedirectToSignUp}
                  className="flexible flex-1"
                  color={COLORS.PRIMARY}
                  variant={VARIANTS.OUTLINED}
                  text="Return to sign up"
                  iconLeftName="arrow_left"
                />
                <Button
                  color={COLORS.PRIMARY}
                  variant={VARIANTS.FILLED}
                  cyId="submit-verify-button"
                  text="Verify"
                  type="submit"
                  submitting={submitting}
                />
              </div>
              <div className="h-1px w-full mt-24px mb-16px bg-mono-100" />
              <p className="!text-[12px] font-['Inter'] font-normal">
                Didn't receive an email or something went wrong? Check your spam
                folder or{" "}
                <button
                  className="text-[#1C809A] p-0 border-none outline-none font-['Inter'] focus:text-blue"
                  onClick={resendCode}
                  type="button"
                >
                  Resend
                </button>
                , this may take a few minutes to arrive.
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

const VerifySchema = Yup.object().shape({
  email: Yup.string().email().required("Email required"),
  verification_code: Yup.number()
    .typeError("Verification code must be a number")
    .required("This field is required")
});

import "./Badge.scss";
import { COLORS } from "models/colors";
import { bemModifier } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";
import { VARIANTS } from "../../models/variants";

interface IBadgeProps {
  color: COLORS;
  variant?: VARIANTS;
  className?: string;
  value?: string;
}

const baseClassName = "badge";
const getSize = (value: string | undefined): string =>
  !!value ? (value.length > 2 ? "lg" : "sm") : "";
const getVariantAndColor = (variant: VARIANTS, color: COLORS): string =>
  variant === VARIANTS.DEFAULT ? variant : `${variant}--${color}`;

export const Badge = ({
  className = "",
  color,
  variant = VARIANTS.FILLED,
  value
}: IBadgeProps): JSX.Element => {
  return (
    <span
      className={joinClassNames(
        bemModifier(
          baseClassName,
          getVariantAndColor(variant, color),
          getSize(value)
        ),
        className
      )}
    >
      {value}
    </span>
  );
};

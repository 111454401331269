import React from "react";
import { Desktop, Mobile } from "../../responsive/Responsive";
import DesktopModal from "../desktop-modal/DesktopModal";
import SlidingModal, {
  TSlidingModalAlign
} from "../sliding-modal/SlidingModal";
import { IButtonProps } from "../../../elements/button/Button";

export interface IModalData {
  title?: string;
  show: boolean;
  onHide: () => void;
  disableHeader?: boolean;
  disableBackground?: boolean;
  slideTo?: number;
  align?: TSlidingModalAlign;
  rightButton?: IButtonProps;
  children: React.ReactNode;
  BottomControls?: React.FC;
  className?: string;
  backgroundClassName?: string;
}

const Modal = ({
  title,
  show,
  onHide,
  disableHeader,
  disableBackground,
  slideTo,
  align,
  rightButton,
  children,
  BottomControls,
  className,
  backgroundClassName
}: IModalData) => {
  return (
    <>
      <Desktop>
        <DesktopModal
          show={show}
          onHide={onHide}
          title={title}
          disableHeader={disableHeader}
          BottomControls={BottomControls}
          className={className}
        >
          {children}
        </DesktopModal>
      </Desktop>
      <Mobile>
        <SlidingModal
          show={show}
          onHide={onHide}
          title={title}
          disableHeader={disableHeader}
          disableBackground={disableBackground}
          slideTo={slideTo}
          align={align}
          rightButton={rightButton}
          BottomControls={BottomControls}
          className={className}
          backgroundClassName={backgroundClassName}
        >
          {children}
        </SlidingModal>
      </Mobile>
    </>
  );
};

export default Modal;

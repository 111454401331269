import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

export const createApolloClient = (token?: string) => {
  const links = [
    onErrorLink,
    new HttpLink({
      uri: `${process.env.REACT_APP_API_CORE_URL}/v1/graphql`,
      headers: {
        authorization: token ? `${token}` : ""
      }
    })
  ];

  return new ApolloClient({
    link: ApolloLink.from(links),
    cache: new InMemoryCache()
  });
};

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (process.env.NODE_ENV === "development") {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        // eslint-disable-next-line no-console
        console.log(
          `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`,
          err
        );
      }
    }

    if (networkError) {
      // eslint-disable-next-line no-console
      console.log(`[Network error]: ${JSON.stringify(networkError.message)}`);
    }
  }
});

import "./ListItem.scss";
import { MouseEvent, ReactElement, ReactNode, useRef } from "react";
import { SvgIcon } from "elements/svg-icon/svg-icon";
import { bemElement } from "utils/bem-class-names";
import { joinClassNames } from "utils/join-class-names";

export interface IListItemProps {
  children: ReactNode;
  className?: string;
  cyId?: string;
  control?: ReactElement;
  onClick?: (e: MouseEvent) => void;
  disableRightArrow?: boolean;
  enableBottomArrow?: boolean;
  showDescription?: boolean;
  addOnDescription?: string | null;
}

const baseClassName = "list-item-component";
const bem = bemElement(baseClassName);
const activeModifier = baseClassName + "--active";

export const ListItem = ({
  children,
  className = "",
  cyId = "",
  control,
  onClick,
  disableRightArrow,
  enableBottomArrow,
  showDescription,
  addOnDescription
}: IListItemProps): JSX.Element => {
  const rootElementRef = useRef<HTMLDivElement | null>(null);

  const onMouseDownHandler = (e: MouseEvent): void => {
    rootElementRef.current?.classList.add(activeModifier);
  };

  const onMouseUpHandler = (e: MouseEvent): void => {
    rootElementRef.current?.classList.remove(activeModifier);
  };

  const onClickHandler = (e: MouseEvent): void => {
    onClick && onClick(e);
  };

  return (
    <div
      ref={rootElementRef}
      className={joinClassNames(baseClassName, className)}
      onMouseDown={onMouseDownHandler}
      onMouseUp={onMouseUpHandler}
      onClick={onClickHandler}
      data-cy={cyId}
      aria-hidden="true"
    >
      <div className={bem("left-side")}>
        <div className={bem("content")}>{children}</div>
      </div>
      {control && <div className={bem("right-side")}>{control}</div>}
      {!disableRightArrow && (
        <SvgIcon name="arrow_right" className={bem("icon")} />
      )}
      {enableBottomArrow && !!addOnDescription && (
        <SvgIcon name="arrow_bottom" className={bem("icon")} />
      )}
      {enableBottomArrow && !addOnDescription && (
        <div className="w-[24px] ml-[10px]" />
      )}
      {showDescription && addOnDescription && (
        <span
          className="add-on-info w-full mt-4"
          dangerouslySetInnerHTML={{ __html: addOnDescription }}
        />
      )}
    </div>
  );
};

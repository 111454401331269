import { ListItem } from "components/list-item/ListItem";
import { InputChip } from "elements/input-chip/InputChip";
import { COLORS } from "models/colors";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface IAddOnnData {
  addOnId: string;
  name: string;
  price: number;
  duration: number;
  description?: string | null;
  choiceIsRequired: boolean;
  addOnsCart: string[];
  setAddOnsCart: Dispatch<SetStateAction<string[]>>;
  isFoundAddonsInCart: boolean;
  requiredAddOnsCountForValidation: string[];
  setRequiredAddOnsCountForValidation: Dispatch<SetStateAction<string[]>>;
}

const AddOnItem = ({
  addOnId,
  name,
  price,
  duration,
  description,
  choiceIsRequired,
  addOnsCart,
  setAddOnsCart,
  isFoundAddonsInCart,
  requiredAddOnsCountForValidation,
  setRequiredAddOnsCountForValidation
}: IAddOnnData): JSX.Element => {
  const [isNoSelected, setIsNoSelected] = useState<boolean>(false);
  const [showDescription, setShowDescription] = useState<boolean>(false);
  const isSelected = addOnsCart.includes(addOnId);

  useEffect(() => {
    if (isFoundAddonsInCart && !isSelected) {
      setIsNoSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFoundAddonsInCart, isSelected]);

  const toggleAddingAndRemovingAddOn = () => {
    if (isSelected) {
      setAddOnsCart((prev) => prev.filter((item) => item !== addOnId));
    } else {
      setAddOnsCart((prev) => [...prev, addOnId]);
    }
  };

  const handleClickOnYes = () => {
    if (!isSelected) {
      setAddOnsCart((prev) => [...prev, addOnId]);
    }
    setIsNoSelected(false);
    if (!requiredAddOnsCountForValidation.includes(addOnId)) {
      setRequiredAddOnsCountForValidation((prev) => [...prev, addOnId]);
    }
  };
  const handleClickOnNo = () => {
    if (isSelected) {
      setAddOnsCart((prev) => prev.filter((item) => item !== addOnId));
    }
    setIsNoSelected(true);
    if (!requiredAddOnsCountForValidation.includes(addOnId)) {
      setRequiredAddOnsCountForValidation((prev) => [...prev, addOnId]);
    }
  };

  const control = choiceIsRequired ? (
    <div className="flex gap-2">
      <InputChip
        color={COLORS.PRIMARY}
        value={isSelected}
        text="Yes"
        onClick={handleClickOnYes}
      />
      <InputChip
        color={COLORS.PRIMARY}
        value={isNoSelected}
        text="No"
        onClick={handleClickOnNo}
      />
    </div>
  ) : (
    <InputChip
      color={COLORS.PRIMARY}
      value={isSelected}
      text={isSelected ? "Added" : "Add"}
      iconLeftName={isSelected ? "minus" : "plus"}
      onClick={toggleAddingAndRemovingAddOn}
    />
  );

  return (
    <ListItem
      control={control}
      className={`add-on items-center ${
        showDescription ? "rotateChildIcon" : ""
      }`}
      disableRightArrow
      enableBottomArrow
      onClick={() => setShowDescription((prev) => !prev)}
      addOnDescription={description}
      showDescription={showDescription}
    >
      <span className="add-on-title">{name}</span>
      <span className="add-on-info">
        {price ? `$${price} • ${duration} mins` : `Free • ${duration} mins`}
      </span>
    </ListItem>
  );
};

export default AddOnItem;

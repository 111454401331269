interface CacheEntry {
  timestamp: number;
  appointments: any;
  artists: any;
  isProcessed: boolean;
}

const cache: Record<string, CacheEntry> = {};

const fifteenMinutes = 15 * 60 * 1000;

export function cleanupCache(): void {
  const now = Date.now();
  Object.keys(cache).forEach((key) => {
    if (now - cache[key].timestamp > fifteenMinutes) {
      delete cache[key];
    }
  });
}

export function isDataCachedAndValid(key: string): boolean {
  cleanupCache();
  const cacheEntry = cache[key];
  if (!cacheEntry) return false;

  return Date.now() - cacheEntry.timestamp < fifteenMinutes;
}

export function addToCache(
  key: string,
  appointments: any,
  artists: any,
  isProcessed: boolean
): void {
  cache[key] = {
    timestamp: Date.now(),
    appointments: !appointments ? null : appointments,
    artists: !artists ? null : artists,
    isProcessed
  };
}

export function getCachedData(key: string): any | null {
  // before returning cache data I am checking if data is valid
  // by deleting items that was saved more than 15 min ago.
  if (isDataCachedAndValid(key)) {
    return cache[key];
  }
  return null;
}

export function clearCache() {
  for (const key in cache) {
    if (Object.hasOwnProperty.call(cache, key)) {
      delete cache[key];
    }
  }
}

import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Auth } from "@aws-amplify/auth";
import { Button, BUTTON_SIZES } from "components/Button";
import AuthInput from "components/AuthInput";
import { Link } from "react-router-dom";
import AuthLayout from "components/AuthLayout";

/* eslint-disable */
const authLinkButtonStyles =
  "w-full rounded-[8px] !py-[13px] !bg-mono-tertiary !border !border-mono-primary !text-[15px] !font-['Inter'] !text-mono-primary hover:!text-mono-primary hover:!bg-mono-secondary focus:!bg-mono-secondary whitespace-nowrap !px-0";

const authSubmitButtonStyles =
  "w-auto rounded-[8px] !py-[13px] !bg-mono-primary border-none !text-[15px] !font-['Inter'] text-mono-white hover:!text-mono-white hover:!bg-mono-primaryLight focus:!bg-mono-primaryLight whitespace-nowrap !px-7 disabled:!bg-mono-300";
/* eslint-enable */

export default function ResetPasswordVerify() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  const email = searchParams.get("email");

  const { handleSubmit, handleChange, touched, errors, values } = useFormik({
    initialValues: {
      email: email || "",
      verification_code: "",
      newPassword: ""
    },
    validationSchema: ResetPasswordVerifySchema,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: false,
    onSubmit: async ({ email, verification_code, newPassword }) => {
      try {
        setSubmitting(true);

        await Auth.forgotPasswordSubmit(
          email.toLowerCase(),
          verification_code,
          newPassword
        );

        navigate(`/login`);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  return (
    <>
      <Helmet>
        <title>Pointment | Reset Password Verify</title>
        <meta property="og:title" content="Pointment | Reset Password Verify" />
      </Helmet>
      <AuthLayout
        title="Reset Password Verification"
        error={error}
        setError={setError}
      >
        <>
          <p className="mb-6 text-base font-normal font-['Inter'] text-[#000]">
            Verification code has been sent to:{" "}
            <span className="font-bold">{email}</span>
          </p>
          <form onSubmit={handleSubmit}>
            <AuthInput
              type="text"
              name="verification_code"
              label="Verification code"
              placeholder="123456"
              onChange={handleChange}
              value={values.verification_code}
              touched={touched}
              errors={errors}
            />
            <AuthInput
              type="password"
              name="newPassword"
              label="New Password"
              placeholder="New Password"
              value={values.newPassword}
              onChange={handleChange}
              touched={touched}
              errors={errors}
            />
            <div className="flex gap-[16px]">
              <Link
                className="cursor-pointer w-full"
                to={"/login"}
                tabIndex={-1}
              >
                <Button
                  className={authLinkButtonStyles}
                  text={"Return to sign in"}
                  size={BUTTON_SIZES.MEDIUM}
                  type="button"
                  backIcon
                />
              </Link>
              <Button
                className={authSubmitButtonStyles}
                text={"Verify"}
                type="submit"
                size={BUTTON_SIZES.MEDIUM}
                submitting={submitting}
                disabledOnSubmit
              />
            </div>
          </form>
        </>
      </AuthLayout>
    </>
  );
}

const ResetPasswordVerifySchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  verification_code: Yup.string().required("Verification code is required"),
  newPassword: Yup.string().required("New password is required")
});

import "./SignUpForm.scss";
import { useEffect, useMemo, useState } from "react";
import { Button } from "elements/button/Button";
import { IForm, useForm } from "hooks/use-form";
import { COLORS } from "models/colors";
import { VARIANTS } from "models/variants";
import { validators } from "validators";
import {
  FormBuilder,
  IFormElement,
  generateFormElementConfigs
} from "components/form-builder";
import { bemElement, bemModifier } from "utils/bem-class-names";
import * as Axios from "providers/axios";
import * as User from "providers/user";
import { Auth } from "@aws-amplify/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { getVerifyUserRoute } from "routes";
import { joinClassNames } from "utils/join-class-names";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { getPhone } from "../../utils/getPhone";

/* eslint-disable no-console */

const baseClassName = "sign-up-form";
const bem = bemElement(baseClassName);

export const pronounSelectOptions = [
  {
    label: "She/Her",
    value: "She/Her"
  },
  {
    label: "He/Him",
    value: "He/Him"
  },
  {
    label: "They/Them",
    value: "They/Them"
  },
  {
    label: "Other",
    value: "Other"
  }
];
const selectOtherOptionValue =
  pronounSelectOptions[pronounSelectOptions.length - 1].value;

const getFormElements = (
  form: IForm,
  disablePassword: boolean,
  disableEmail: boolean,
  disablePhoneInput: boolean
): IFormElement[] => {
  return generateFormElementConfigs(form, [
    {
      type: "Input",
      formValueName: "firstName",
      extraProps: {
        cyId: "first-name-input",
        label: "First Name",
        type: "text"
      }
    },
    {
      type: "Input",
      formValueName: "lastName",
      extraProps: {
        cyId: "last-name-input",
        label: "Last Name",
        type: "text"
      }
    },
    {
      type: "Input",
      formValueName: "email",
      extraProps: {
        cyId: "email-input",
        label: "Email",
        type: "email",
        disabled: disableEmail
      }
    },
    {
      type: "Input",
      formValueName: "password",
      extraProps: {
        cyId: "password-input",
        label: "Password",
        type: "password",
        disabled: disablePassword,
        hint:
          "Your password should be at least 8 characters long and include a combination " +
          "of uppercase and lowercase letters, numbers, and symbols like @, #, or $."
      }
    },
    {
      type: "Input",
      formValueName: "confirmPassword",
      extraProps: {
        cyId: "confirm-password-input",
        label: "Confirm Password",
        type: "password",
        disabled: disablePassword
      }
    },
    {
      type: "PhoneInput",
      formValueName: "phone",
      extraProps: {
        cyId: "phone-input",
        label: "Phone",
        type: "tel",
        disabled: disablePhoneInput
      }
    },
    {
      type: "Select",
      formValueName: "pronoun",
      extraProps: {
        cyId: "pronoun-select",
        label: "Pronoun",
        options: pronounSelectOptions,
        className: bemModifier(bem("pronoun-select"), {
          advanced: form.values.pronoun === selectOtherOptionValue
        })
      }
    },
    {
      type: "Input",
      formValueName: "otherPronoun",
      extraProps: {
        cyId: "other-pronoun-input",
        label: "",
        placeholder: "e.g. They/Their",
        type: "text",
        className: bemModifier(bem("other-input"), {
          hidden: form.values.pronoun !== selectOtherOptionValue
        })
      }
    },
    {
      type: "Checkbox",
      formValueName: "newsletter",
      extraProps: {
        cyId: "newsletter-checkbox",
        label: "Subscribe to our newsletter"
      }
    },
    {
      type: "Checkbox",
      formValueName: "reminders",
      extraProps: {
        cyId: "reminders-checkbox",
        label: "Subscribe to text message reminders"
      }
    }
  ]);
};

const phoneUtil = PhoneNumberUtil.getInstance();

export interface ISignUpFormProps {
  className?: string;
  onRedirectToLogin: () => void;
  onRedirectToVerify?: (email: string) => void;
  description?: string;
  onErrorMessage?: (message: string) => void;
}

export const SignUpForm = ({
  className = "",
  onRedirectToLogin,
  onRedirectToVerify,
  description,
  onErrorMessage
}: ISignUpFormProps): JSX.Element => {
  const { api } = Axios.useAxios();
  const {
    updateUser,
    user: userContext,
    updateUserPassword,
    userPassword
  } = User.useUser();
  const [signUpSubmitting, setSignUpSubmitting] = useState<boolean>(false);
  const { state } = useLocation();

  const urlParams = new URLSearchParams(window.location.search);
  const emailParam = urlParams.get("email");
  const phoneNumber = urlParams.get("phoneNumber");
  const userId = urlParams.get("userId");
  const joinedCompanyId = urlParams.get("joined_company_id");

  const user = state?.user || userContext;

  const navigate = useNavigate();

  const signUpFormConfig = {
    firstName: user?.first_name || "",
    lastName: user?.last_name || "",
    email: user?.email || emailParam || "",
    password: userPassword || "",
    confirmPassword: userPassword || "",
    phone: user?.libphonenumber || (phoneNumber ? getPhone(phoneNumber) : "+1"),
    pronoun: user?.pronoun || "",
    otherPronoun: "",
    newsletter:
      user?.reminder_settings?.find((setting: any) => setting.type === "EMAIL")
        ?.enabled || true,
    reminders:
      user?.reminder_settings?.find((setting: any) => setting.type === "SMS")
        ?.enabled || true,
    isInvited: !!emailParam || !!phoneNumber
  };

  const form = useForm(signUpFormConfig, {
    firstName: [validators.required()],
    lastName: [validators.required()],
    email: [validators.required(), validators.email()],
    password: !user ? [validators.required(), validators.password()] : [],
    confirmPassword: !user
      ? [validators.required(), validators.matchWith("password")]
      : [],
    phone: [validators.required(), validators.phone()],
    otherPronoun: [validators.requiredIf("pronoun", selectOtherOptionValue)]
  });

  useEffect(() => {
    if (user?.email) {
      form.updateValues({
        ...form.values,
        email: user.email
      });
      form.setSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email]);

  const formElements = useMemo(
    () => getFormElements(form, !!user, !!emailParam, !!phoneNumber),
    [form, user, emailParam, phoneNumber]
  );

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.setSubmitted(true);

    if (!form.valid) {
      return;
    }

    const {
      password,
      confirmPassword,
      otherPronoun,
      pronoun,
      email,
      phone,
      firstName,
      lastName,
      ...rest
    } = form.values;

    setSignUpSubmitting(true);

    try {
      let cognitoUser = { userSub: "" };
      let isInvited = !!email || !!phoneNumber;
      if (!user || user.email !== (email as string).toLowerCase()) {
        updateUserPassword(password as string);
        cognitoUser = await Auth.signUp({
          username: (email as string).toLowerCase(),
          password: password as string,
          attributes: {
            given_name: firstName as string,
            family_name: lastName as string,
            email: (email as string).toLowerCase(),
            phone_number: phone as string,
            gender: pronoun || "Other"
          }
        });
        if (user && user.email !== (email as string).toLowerCase()) {
          isInvited = true;
        }
      }

      const parsedNumber = phoneUtil.parseAndKeepRawInput(phone as string);

      const { data } = await api.post("/v1/users/customer", {
        ...rest,
        userId: userId || user?.id || undefined,
        firstName,
        lastName,
        phone: phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL),
        libphonenumber: phone as string,
        email: (email as string).toLowerCase(),
        pronoun: otherPronoun ? otherPronoun : pronoun,
        sub: cognitoUser.userSub || userId || user?.id,
        joined_company_id: joinedCompanyId || undefined,
        isInvited
      });

      if (onRedirectToVerify) {
        return onRedirectToVerify(email as string);
      }

      updateUser(data);
      navigate(getVerifyUserRoute(email as string), {
        state: { password }
      });
    } catch (err) {
      if (err.message.startsWith("Password did not conform with policy:")) {
        form.errors.password = err.message;
      } else if (
        err.message === "An account with the given email already exists."
      ) {
        form.errors.email = err.message;
      } else if (err.response?.data.message === "User already exists") {
        navigate(getVerifyUserRoute(email as string), {
          state: { password }
        });
      } else {
        onErrorMessage && onErrorMessage(err.message);
      }
    } finally {
      setSignUpSubmitting(false);
    }
  };

  return (
    <div className={joinClassNames(baseClassName, className)}>
      <h2>{user && "Finish"} Sign Up</h2>
      {description && <p className={bem("description")}>{description}</p>}
      <FormBuilder
        className={bem("form")}
        elements={formElements}
        onSubmit={onSubmit}
      >
        <div className={bem("form-controls")}>
          <Button
            className="flexible flex-1"
            color={COLORS.PRIMARY}
            variant={VARIANTS.OUTLINED}
            text="Not new?"
            onClick={onRedirectToLogin}
          />
          <Button
            cyId="submit-sign-up-button"
            color={COLORS.PRIMARY}
            variant={VARIANTS.FILLED}
            text="Sign up"
            disabled={(form.submitted && !form.valid) || signUpSubmitting}
            type="submit"
          />
        </div>
      </FormBuilder>
    </div>
  );
};

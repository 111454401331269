import React, { useEffect } from "react";

import * as Cognito from "providers/cognito";
import * as Axios from "providers/axios";
import { useUserQuery } from "./User.generated";

interface UserContextInterface {
  permissions: {
    isSuperAdmin: boolean;
    role: { id: string; role: string; company_id: string };
    companyId: string | undefined;
  };
  updateUser: (value: any) => any;
  user: any | null;
  updateUserPassword: (value: string) => string;
  userPassword: string | null;
  isLoggedIn: boolean;
  loading: boolean;
}

interface Props {
  children: React.ReactNode;
}

const UserStateContext = React.createContext<UserContextInterface | undefined>(
  undefined
);

function UserProvider(props: Props) {
  const { children } = props;
  const { user: cognitoUser } = Cognito.useCognito();
  const [user, setUser] = React.useState<any | null>(undefined);
  const [userPassword, setUserPassword] = React.useState<string | null>(null);
  const [isFetching, setIsFetching] = React.useState(false);
  const { api } = Axios.useAxios();
  const { data, loading } = useUserQuery({
    variables: {
      where: {
        email: cognitoUser?.email
      }
    },
    skip: !cognitoUser?.email
  });

  useEffect(() => {
    if (loading) {
      setIsFetching(true);
      return;
    }

    const updateUserAndSetVerifiedStatus = async () => {
      if (data?.user) {
        setUser({ ...data.user, isVerified: cognitoUser?.isVerified });
        setIsFetching(false);
      } else {
        if (cognitoUser?.email) {
          try {
            const newUser = await api.post(`/v1/users/customer`, {
              sub: cognitoUser.id,
              email: cognitoUser.email,
              phone: "",
              reminders: true
            });

            updateUser({
              ...newUser.data,
              isVerified: cognitoUser?.isVerified
            });
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error("Failed to create new user:", error);
          } finally {
            setIsFetching(false);
          }
        }
        setUser(undefined);
        setIsFetching(false);
      }
    };

    updateUserAndSetVerifiedStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.user, cognitoUser, loading]);

  const updateUser = (user: any) => {
    setUser(user);

    return user;
  };

  const updateUserPassword = (password: string) => {
    setUserPassword(password);

    return password;
  };

  const impersonatedUserLogout = async () => {
    try {
      await api.post("v1/users/impersonate-logout");
      setUser((prev: any) => ({
        ...prev,
        impersonate_id: null,
        impersonate_name: null
      }));
    } catch (error) {
      throw new Error("Error logging out: " + error.message);
    }
  };

  useEffect(() => {
    if (user?.impersonate_id && window.location.pathname === "/") {
      impersonatedUserLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const permissions = {
    isSuperAdmin: user?.role?.role === "SUPER",
    role: user?.role || "",
    companyId: user?.company?.id
  };

  return (
    <UserStateContext.Provider
      value={{
        permissions,
        updateUser,
        user,
        updateUserPassword,
        userPassword,
        isLoggedIn: cognitoUser?.id ? !!cognitoUser.id : false,
        loading: isFetching
      }}
    >
      {children}
    </UserStateContext.Provider>
  );
}

function useUser() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUser };

import "./Select.scss";
import React, { useRef, useState } from "react";
import { SvgIcon } from "../svg-icon/svg-icon";
import { bemElement, bemModifier } from "../../utils/bem-class-names";
import { joinClassNames } from "../../utils/join-class-names";

export interface IOptionData {
  label: string;
  value: string | number;
}

export interface ISelectData {
  value?: string | number;
  label?: string;
  options?: IOptionData[];
  onChange?: (value: string | number | undefined) => void;
  className?: string;
  cyId?: string;
  errorMsg?: string;
}

const baseClassName = "select";
const bem = bemElement(baseClassName);

const Select = ({
  value = "",
  label = "",
  options = [],
  onChange,
  className = "",
  cyId = "",
  errorMsg
}: ISelectData) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className={joinClassNames(
        bemModifier(baseClassName, {
          open: isOpen,
          error: errorMsg
        }),
        className
      )}
    >
      <div
        className={bem("wrapper")}
        onClick={() => setIsOpen((prevState) => !prevState)}
        data-cy={`${cyId}-wrapper`}
        aria-hidden
        ref={ref}
      >
        <div className={bem("left-side")}>
          <div className={bem("label")}>{value === "" ? "" : label}</div>
          <div className={bem("selected")}>
            <div data-cy={`${cyId}-selected`}>
              {value === "" ? label : value}
            </div>
            {isOpen && (
              <div className={bem("options")}>
                {options.map((option: IOptionData) => (
                  <div
                    className={bem("option")}
                    key={option.value}
                    data-cy={cyId}
                    onClick={() => onChange && onChange(option.value)}
                    aria-hidden
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={bem("right-side")}>
          {isOpen ? (
            <SvgIcon name="arrow_top" />
          ) : (
            <SvgIcon name="arrow_bottom" />
          )}
        </div>
      </div>
      <div className={bem("error-msg-container")}>
        {errorMsg && <span className={bem("error-msg")}>{errorMsg}</span>}
      </div>
    </div>
  );
};

export default Select;

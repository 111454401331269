export enum COLORS {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  SECONDARY_CONTAINER = "secondary-container",
  TERTIARY = "tertiary",
  TERTIARY_CONTAINER = "tertiary-container",
  ERROR = "error",
  ERROR_CONTAINER = "error-container",
  SUCCESS = "success",
  BACKGROUND = "background"
}

import * as Types from "../../../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpdateUserMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars["String"]>;
  data: Types.UserUpdateInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateOneUser?: {
    __typename?: "User";
    first_name?: string | null;
    last_name?: string | null;
    last_viewed_notifications?: any | null;
    email: string;
    phone_number?: string | null;
    pronoun?: string | null;
    reminder_settings: Array<{
      __typename?: "ReminderSetting";
      id: string;
      type: Types.ReminderType;
      value: string;
    }>;
  } | null;
};

export const UpdateUserDocument = gql`
  mutation UpdateUser($id: String, $data: UserUpdateInput!) {
    updateOneUser(where: { id: $id }, data: $data) {
      first_name
      last_name
      last_viewed_notifications
      email
      phone_number
      reminder_settings {
        id
        type
        value
      }
      pronoun
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

import "./EditAccountSection.scss";
import React, { useMemo } from "react";
import { Avatar } from "../../../elements/avatar/Avatar";
import { Button } from "../../../elements/button/Button";
import { COLORS } from "../../../models/colors";
import { VARIANTS } from "../../../models/variants";
import { bemElement, bemModifier } from "../../../utils/bem-class-names";
import { IForm, useForm } from "../../../hooks/use-form";
import {
  FormBuilder,
  generateFormElementConfigs,
  IFormElement
} from "../../form-builder";
import { validators } from "../../../validators";
import { Desktop } from "../../responsive/Responsive";
import { pronounSelectOptions } from "../../../forms/sign-up-form/SignUpForm";
import { useUser } from "../../../providers/user";
import { useUpdateUserMutation } from "./EditAccountSection.generated";
import FileInput from "../../../elements/file-input/FileInput";
import { useAxios } from "../../../providers/axios";
import { toBase64 } from "utils/files";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

const baseClassName = "edit-account-section";
const bem = bemElement(baseClassName);

const selectOtherOptionValue =
  pronounSelectOptions[pronounSelectOptions.length - 1].value;
const getFormElements = (form: IForm): IFormElement[] => {
  return generateFormElementConfigs(form, [
    {
      type: "Input",
      formValueName: "firstName",
      extraProps: {
        label: "First Name",
        type: "text",
        cyId: "first-name-input"
      }
    },
    {
      type: "Input",
      formValueName: "lastName",
      extraProps: {
        label: "Last Name",
        type: "text",
        cyId: "last-name-input"
      }
    },
    {
      type: "Input",
      formValueName: "email",
      extraProps: {
        label: "Email",
        type: "email",
        cyId: "email-input",
        disabled: true
      }
    },
    {
      type: "PhoneInput",
      formValueName: "phone",
      extraProps: {
        label: "Phone",
        type: "tel",
        cyId: "phone-input"
      }
    },
    {
      type: "Select",
      formValueName: "pronoun",
      extraProps: {
        label: "Pronoun",
        cyId: "pronoun-select",
        options: pronounSelectOptions,
        className: bemModifier(bem("pronoun-select"), {
          advanced: form.values.pronoun === selectOtherOptionValue
        })
      }
    },
    {
      type: "Input",
      formValueName: "otherPronoun",
      extraProps: {
        label: "",
        cyId: "other-pronoun-input",
        placeholder: "e.g. They/Their",
        type: "text",
        className: bemModifier(bem("other-input"), {
          hidden: form.values.pronoun !== selectOtherOptionValue
        })
      }
    }
  ]);
};

const EditAccountSection = () => {
  const { user, updateUser } = useUser();
  const { api } = useAxios();
  const editAccountFormConfig = useMemo(
    () => ({
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      phone: user.phone_number,
      pronoun:
        user.pronoun ===
        pronounSelectOptions[pronounSelectOptions.length - 1].value
          ? "other"
          : user.pronoun,
      otherPronoun:
        user.pronoun ===
        pronounSelectOptions[pronounSelectOptions.length - 1].value
          ? user.pronoun
          : ""
    }),
    [user]
  );

  const form = useForm(editAccountFormConfig, {
    firstName: [validators.required()],
    lastName: [validators.required()],
    email: [validators.required(), validators.email()],
    phone: [validators.required(), validators.phone()],
    otherPronoun: [validators.requiredIf("pronoun", selectOtherOptionValue)]
  });
  const formElements = useMemo(() => getFormElements(form), [form]);

  const [updateAccount, { loading }] = useUpdateUserMutation();

  const onSubmit = () => {
    const parsedNumber = phoneUtil.parseAndKeepRawInput(
      form.values.phone as string
    );

    const data: any = {
      first_name: {
        set: form.values.firstName as string
      },
      last_name: {
        set: form.values.lastName as string
      },
      email: {
        set: form.values.email as string
      },
      phone_number: {
        set: phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL)
      },
      pronoun: {
        set: (form.values.pronoun ===
        pronounSelectOptions[pronounSelectOptions.length - 1].value
          ? form.values.otherPronoun
          : form.values.pronoun) as string
      }
    };

    const phoneReminder = user.reminder_settings?.find(
      (rs: any) => rs.type === "SMS"
    );

    if (phoneReminder) {
      data.reminder_settings = {
        update: [
          {
            where: { id: phoneReminder?.id },
            data: {
              value: {
                set: phoneUtil.format(parsedNumber, PhoneNumberFormat.E164)
              }
            }
          }
        ]
      };
    }

    updateAccount({
      variables: {
        id: user.id,
        data
      },
      onCompleted: (data) => updateUser({ ...user, ...data.updateOneUser })
    });
  };

  const uploadPhoto = async (event: any) => {
    const files = event.target.files;
    const file = files[0];

    const base64Data = await toBase64(file);

    const { data } = await api.put("/v1/users/customer/profile-picture", {
      image: base64Data
    });
    updateUser({ ...user, photo_url: data.url });
  };

  return (
    <div className={baseClassName}>
      <div className={bem("wrapper")}>
        <div className={bem("photo")}>
          <Avatar
            src={user.photo_url || ""}
            monogram={user.first_name || ""}
            size="lg"
          />
          <FileInput
            color={COLORS.SECONDARY}
            variant={VARIANTS.OUTLINED}
            size="sm"
            cyId="upload-photo-button"
            text="Set new photo"
            accept="image/*"
            onChange={uploadPhoto}
          />
        </div>
        <FormBuilder
          className={bem("form")}
          elements={formElements}
          onSubmit={onSubmit}
        >
          <Desktop>
            <Button
              className={"flexible w-100%"}
              color={COLORS.SECONDARY}
              variant={VARIANTS.FILLED}
              disabled={!form.isTouched || form.hasErrors}
              text="Save changes"
              cyId="save-changes-button"
              submitting={loading}
              type="submit"
            />
          </Desktop>
        </FormBuilder>
      </div>
      <div className={bem("button-wrapper")}>
        <Button
          className={"flexible w-100%"}
          color={COLORS.SECONDARY}
          variant={VARIANTS.FILLED}
          disabled={!form.isTouched || form.hasErrors}
          text="Save changes"
          cyId="save-changes-button"
          submitting={loading}
          type="submit"
          onClick={() => onSubmit()}
        />
      </div>
    </div>
  );
};

export default EditAccountSection;

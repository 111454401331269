import "./FormattedTextModal.scss";
import React from "react";
import { Desktop, Mobile } from "../../responsive/Responsive";
import DesktopModal from "../desktop-modal/DesktopModal";
import SlidingModal from "../sliding-modal/SlidingModal";

interface IFormattedTextModalData {
  title?: string;
  show: boolean;
  onHide: () => void;
  text: string;
  BottomControls?: React.FC;
  className?: string;
}

const FormattedTextModal = ({
  show,
  onHide,
  title,
  text,
  BottomControls,
  className
}: IFormattedTextModalData) => {
  return (
    <>
      <Desktop>
        <DesktopModal
          show={show}
          onHide={onHide}
          title={title}
          BottomControls={BottomControls}
          className={className}
        >
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            className="formatted-text-modal"
          />
        </DesktopModal>
      </Desktop>
      <Mobile>
        <SlidingModal
          show={show}
          onHide={onHide}
          title={title}
          BottomControls={BottomControls}
          className={className}
        >
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            className="formatted-text-modal"
          />
        </SlidingModal>
      </Mobile>
    </>
  );
};

export default FormattedTextModal;

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ReactComponent as SpriteSVG } from "assets/sprite.svg";

import App from "App";
import reportWebVitals from "reportWebVitals";

import "./index.scss";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  tunnel: `${process.env.REACT_APP_API_CORE_URL}/sentry-tunnel`
});

ReactDOM.render(
  <React.StrictMode>
    <App />
    <SpriteSVG className="hidden" />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import "./ChangeArtistModal.scss";
import { useEffect, useState } from "react";
import {
  ArtistSelect,
  IValidPermutationsByArtist
} from "components/artist-select/ArtistSelect";
import { Button, TButtonSize } from "elements/button/Button";
import { COLORS } from "models/colors";
import { VARIANTS } from "models/variants";
import { useCart } from "providers/cart-provider";
import { TBookAppointmentService } from "types";
import SlidingModal from "../sliding-modal/SlidingModal";
import { IBookAppointmentServiceFull } from "providers/cart-provider.utils";
import { Toast } from "elements/toast/Toast";

export interface IChangeArtistModalData {
  show: boolean;
  slideTo?: number;
  onHide: () => void;
  className?: string;
}

const ChangeArtistModal = ({
  show,
  slideTo,
  onHide,
  className
}: IChangeArtistModalData): JSX.Element => {
  const {
    cart,
    applySelectedArtist,
    autoSelectArtists,
    activePersonsNumber,
    artists,
    services
  } = useCart();
  const [cartServices, setCartServices] = useState<
    IBookAppointmentServiceFull[]
  >([]);
  const [showResetButton, setShowResetButton] = useState(false);
  const [selectAllArtistsMsg, setSelectAllArtistsMsg] = useState("");
  const [constraints, setConstraints] = useState<TBookAppointmentService[]>([]);

  const removeLockedArtist = (service: IBookAppointmentServiceFull) => {
    setConstraints((prev) => {
      return prev?.filter(
        (curr) =>
          !(
            curr?.personIndex === service?.personIndex &&
            curr?.service_id === service?.service?.id
          )
      );
    });
  };

  const resetButtonHandler = () => {
    setCartServices([...cart.services]);
    setConstraints([]);
  };

  const BottomControls = () => {
    return (
      <div className="change-artist-buttons-wrapper">
        {selectAllArtistsMsg && (
          <Toast msg={selectAllArtistsMsg} type="error" />
        )}
        <div className="apply-button-wrapper">
          <Button
            className="apply-button flexible w-100%"
            variant={VARIANTS.FILLED}
            color={COLORS.SECONDARY}
            text="Apply"
            disabled={!!selectAllArtistsMsg}
            onClick={() => {
              applySelectedArtist(cartServices);
              onHide();
            }}
          />
        </div>
      </div>
    );
  };

  const resetButtonProps = {
    text: "Reset",
    color: COLORS.TERTIARY,
    size: "sm" as TButtonSize,
    onClick: resetButtonHandler
  };

  const _selectArtist = (
    validPermutationByArtist: IValidPermutationsByArtist
  ) => {
    let newServices = JSON.parse(
      JSON.stringify(validPermutationByArtist?.permutations)
    );

    newServices = newServices?.map(
      (service: IBookAppointmentServiceFull, index: number) => {
        service.artist = validPermutationByArtist?.permutations?.[index];
        if (validPermutationByArtist?.permutations?.[index]) {
          service.service = services?.find(
            (service) =>
              service.id ===
              validPermutationByArtist.permutations?.[index].service
          ) || {
            id: "",
            company_id: "",
            name: "",
            price: 0,
            choice_is_required: false,
            is_addon: false,
            category: "OTHER"
          };
        }
        service.addOns =
          validPermutationByArtist?.permutations?.[index].add_ons;
        return service;
      }
    );

    setCartServices(newServices);
  };

  useEffect(() => {
    if (cart.services.filter((service) => !service.artist).length > 0) {
      autoSelectArtists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.services]);

  useEffect(() => {
    if (!cartServices.length) {
      setCartServices([...cart.services]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.services]);

  useEffect(() => {
    setShowResetButton(
      JSON.stringify(cartServices) !== JSON.stringify(cart.services)
    );
  }, [cartServices, cart.services]);

  useEffect(() => {
    const isEmptyArtistExist = cartServices.some(
      (s: IBookAppointmentServiceFull) => !s.artist
    );
    if (isEmptyArtistExist) {
      setSelectAllArtistsMsg("Please select all artists");
    } else {
      setSelectAllArtistsMsg("");
    }
  }, [cartServices]);

  return (
    <SlidingModal
      title="Details"
      show={show}
      slideTo={slideTo}
      onHide={onHide}
      BottomControls={BottomControls}
      className={className}
      rightButton={showResetButton ? resetButtonProps : undefined}
    >
      <div className="change-artist-container hide-scroll-bar">
        {new Array(activePersonsNumber).fill(1).map((_, i: number) => (
          <div className="select-artist-item" key={i}>
            <span className="select-artist-item-title">
              {`PERSON ${i + 1}${i === 0 ? " (YOUR)" : ""}`}
            </span>
            <div className="select-artist-item-selectors">
              {cartServices
                .filter((service: IBookAppointmentServiceFull) => {
                  return service.personIndex === i;
                })
                .map(
                  (
                    service: IBookAppointmentServiceFull,
                    index: number,
                    array: IBookAppointmentServiceFull[]
                  ) => {
                    // let startTime;
                    // if (index === 0) {
                    //   startTime = cart.start_time;
                    // } else {
                    //   const date = new Date(cart.start_time);
                    //   for (let j = 0; j < index; j++) {
                    //     const foundService = services.find(
                    //       (item) => item.id === array[j].service.id
                    //     );
                    //     date.setMinutes(
                    //       date.getMinutes() +
                    //         ((foundService?.duration || 45) +
                    //           (foundService?.clean_up_time || 0))
                    //     );
                    //   }
                    //   startTime = date.toISOString();
                    // }
                    const isConcurrent =
                      array?.[index]?.artist?.start_time ===
                      array?.[index + 1]?.artist?.start_time;

                    return (
                      <div
                        className="select-artist-item-selector"
                        key={service.service.id}
                      >
                        <ArtistSelect
                          value={
                            artists?.find(
                              (a) => a.id === service.artist?.artist_id
                            ) || null
                          }
                          isConcurrent={isConcurrent}
                          onChange={(artist: IValidPermutationsByArtist) => {
                            _selectArtist(artist);
                            setConstraints((prev) => [
                              ...prev,
                              {
                                artist_id: artist.artist_id,
                                personIndex: service.personIndex,
                                service_id: service.service.id
                              }
                            ]);
                          }}
                          service={service}
                          constraints={constraints}
                          removeLockedArtist={removeLockedArtist}
                        />
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        ))}
      </div>
    </SlidingModal>
  );
};

export default ChangeArtistModal;
